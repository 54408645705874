import React from "react";
import { SubTitle, Title } from "../../../../../../../Styled";
import { capitalizeWords } from "../../../../../../../utils/utilidades";
import { WrapperContent } from "./Styled";

const Properties = ({
  padding,
  margin,
  cargo,
  gerencia,
  estamento,
  ubicacion,
  jornada,
  jefatura 
}) => {
  return (
    <WrapperContent padding={padding} margin={margin}>
      <Title size="20px" color="blue">
        {cargo || "No ingresado"}
      </Title>
      <SubTitle lh="20px" $margin="0" size="14px">
        Gerencia: <span>{gerencia || "No ingresado"}</span>
      </SubTitle>
      <SubTitle lh="20px" $margin="0" size="14px">
        Estamento: <span>{estamento || "No ingresado"}</span>
      </SubTitle>
      <SubTitle lh="20px" $margin="0" size="14px">
        Ubicacion: <span>{ubicacion || "No ingresado"}</span>
      </SubTitle>
      <SubTitle lh="20px" $margin="0" size="14px">
        Tipo jornada laboral: <span>{jornada || "No ingresado"}</span>
      </SubTitle>
      {
        jefatura && (
          <SubTitle lh="20px" $margin="0" size="14px">
          Jefatura: <span>{capitalizeWords(jefatura) || "No ingresado"}</span>
        </SubTitle>
        )
      }

    </WrapperContent>
  );
};

export default Properties;
