export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  export const removeParentesis = (str) => {
    return str.replace(/\(.*?\)/g, '')
  };

  export const getDays = (fechas) =>{
    if(fechas === ''){
        return ''
    }
    else{
        fechas = fechas.slice(0, fechas.indexOf('T'));
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
    
        let fecha = fechas.split(' ')[0]
        let numero = fecha.split('-')
    
    
    // This arrangement can be altered based on how we want the date's format to appear.
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(year, month, day);
        const secondDate = new Date(numero[0], numero[1], numero[2]);
     const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
     return diffDays
    }

  }
  export const formatDate = (date) => {
    let fecha = date.split(' ')[0]
    let numero = fecha.split('-')
     return numero[2] +'-'+numero[1] +'-' +numero[0]
  }
export const añosCargo = (fecha) =>{
  var fechaDeContratacion = new Date(fecha);
var hoy = new Date();
return parseInt((hoy - fechaDeContratacion) / (1000*60*60*24*365));
}