import { useEffect, useState } from "react";
import $SP from "sharepointplus";
import { useGetToken } from "../App";


// export const useFetch = ({
//   url,
//   options = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json"
//     }
//   },
//   initial
// }) => {
//   const [loading, setLoading] = useState("iddle");
//   const [results, setResults] = useState(initial || []);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     (async () => {
//       setLoading("pending");

//       try {
//         const x = await fetch(
//           `https://api-selecciontalento.azurewebsites.net/api/${url}`,
//           options
//         );
//         const y = await x.json();
//         setResults(y);
//         setLoading("succeded");
//       } catch (error) {
//         setError(error);
//         setLoading("rejected");
//       }
//     })();
//     // eslint-disable-next-line
//   }, []);

//   return { loading, results, error };
// };
export const useFetchPublic = ({
  url,
  options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  },
  initial
}) => {
  const [loading, setLoading] = useState("iddle");
  const [results, setResults] = useState(initial || []);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading("pending");

      try {
        const x = await fetch(
          `${url}`,
          options
        );
        const y = await x.json();
        setResults(y);
        setLoading("succeded");
      } catch (error) {
        setError(error);
        setLoading("rejected");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return { loading, results, error };
};
export const fetchToken = async ({
  endpoint,
  method = "GET",
  body = "",
  alt,
  token
}) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      token: token
    }
  };

  document.body.style.cursor = "wait";

  if (method.toLowerCase() === "post") {
    options.body = JSON.stringify(body);
  }

  try {
    const data = await fetch(
      `https://api-selecciontalento.azurewebsites.net/api/${endpoint}`,
      options
    );
    const json = await data.json();
    document.body.style.cursor = "default";
    return json;
  } catch (error) {
    document.body.style.cursor = "default";
    console.log(error);
    return alt;
  }
};

export const fetchPostToken = async ({
  endpoint,
  method = "GET",
  body = "",
  alt,
  token,
  archivos
}) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      token: token
    }
  };

  document.body.style.cursor = "wait";

  if (method.toLowerCase() === "post") {
    options.body = JSON.stringify(body);
  }

  try {
    const data = await fetch(
      `https://api-selecciontalento.azurewebsites.net/api/${endpoint}`,
      options
    );
    const json = await data.json();
    document.body.style.cursor = "default";
    if(json.FlgOk == 1){
      addAttachmentBlob(archivos,{id:json.id})
    }
    return json;
  } catch (error) {
    document.body.style.cursor = "default";
    console.log(error);
    return alt;
  }
};
export const fetchPostTokenCV = async ({
  endpoint,
  method = "GET",
  body = "",
  alt,
  token,
  archivos
}) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      token: token
    }
  };

  document.body.style.cursor = "wait";

  if (method.toLowerCase() === "post") {
    options.body = JSON.stringify(body);
  }

  try {
    const data = await fetch(
      `https://api-selecciontalento.azurewebsites.net/api/${endpoint}`,
      options
    );
    const json = await data.json();
    document.body.style.cursor = "default";
    if(json.FlgOk == 1){
      addAttachmentBlobCV(archivos,{id:json.id})
    }
    return json;
  } catch (error) {
    document.body.style.cursor = "default";
    console.log(error);
    return alt;
  }
};
export const useFetchToken = async ({
    endpoint,
    method = "GET",
    body = "",
    alt
  }) => {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        token: useGetToken()
      }
    };
  
    document.body.style.cursor = "wait";
  
    if (method.toLowerCase() === "post") {
      options.body = JSON.stringify(body);
    }
  
    try {
      const data = await fetch(
        `https://api-selecciontalento.azurewebsites.net/api/${endpoint}`,
        options
      );
      const json = await data.json();
      document.body.style.cursor = "default";
      return json;
    } catch (error) {
      document.body.style.cursor = "default";
      console.log(error);
      return alt;
    }
  };

export const addAttachment = file => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onloadend = e => {
      let content = e.target.result;

      $SP()
        .list("Publicaciones")
        .createFile({
          content: content,
          filename: file.name
        })
        .then(file => {
          resolve(file.Url);
        });
    };

    fileReader.onerror = function (e) {
      reject("Unexpected error: " + e.target.error);
    };

    fileReader.readAsArrayBuffer(file);
  });
};
export const addAttachmentBlob =  (file,data) => {
  let formData = new FormData()
  let filesRespaldos = []
  let archivos = Array.prototype.slice.call(file);
  Promise.all(archivos.map((file) => {
    return new Promise((prom_res, prom_rej) => {
      var fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        file.content = e.target.result;
        filesRespaldos.push(file)
        prom_res(file);
      }
      fileReader.onerror = (e) => {
        prom_rej(e.target.error)
      }
      fileReader.readAsArrayBuffer(file);
    })
  })).then((archivos) => {


  });
  setTimeout(() => {
    formData.append("inputData", JSON.stringify(data))
    for (let i = 0; i < filesRespaldos.length; i++) {
       formData.append("inputFile" + i.toString(), filesRespaldos[i]);
    }
    var xmlhttp = new XMLHttpRequest()

    var url = 'https://api-selecciontalento.azurewebsites.net/api/Publicacion/Guardar'

    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {

        var response = JSON.parse(this.responseText);
document.getElementById("loader").style.display = "none";



      }
    }



    xmlhttp.open("POST", url, true);
    xmlhttp.send(formData);
  }, 1000);


};
export const addAttachmentBlobCV =  (file,data) => {
  let formData = new FormData()
  let filesRespaldos = []
  let archivos = Array.prototype.slice.call(file);
  Promise.all(archivos.map((file) => {
    return new Promise((prom_res, prom_rej) => {
      var fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        file.content = e.target.result;
        filesRespaldos.push(file)
        prom_res(file);
      }
      fileReader.onerror = (e) => {
        prom_rej(e.target.error)
      }
      fileReader.readAsArrayBuffer(file);
    })
  })).then((archivos) => {


  });
  setTimeout(() => {
    formData.append("inputData", JSON.stringify(data))
    for (let i = 0; i < filesRespaldos.length; i++) {
       formData.append("inputFile" + i.toString(), filesRespaldos[i]);
    }
    var xmlhttp = new XMLHttpRequest()

    var url = 'https://api-selecciontalento.azurewebsites.net/api/Persona/GuardarCV'

    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {

        var response = JSON.parse(this.responseText);
document.getElementById("loader").style.display = "none";



      }
    }



    xmlhttp.open("POST", url, true);
    xmlhttp.send(formData);
  }, 1000);


};

