import React, { useState, useEffect } from "react";
import { PATH, colors } from "../../../assets/variables";
import { ContainerModal, Hr, SubTitle, Title } from "../../../Styled";
import { Icon } from "../../Common/IconProfile/Styled";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";

import { useDispatch, useSelector } from "react-redux";
import { fetchToken } from "../../../customHooks/hooks";
import { v4 } from "uuid";

import {
  BtnClose,
  BtnSubmit,
  ModalWrapper,
  Section
} from "../../PostDetails/PostModal/Styled";
import { TitleSection } from "../Filters/Styled";
import {
  BoxResults,
  ContainerResults,
  GridResults,
  ImgStar,
  TagList,
  WhiteButton
} from "./Styled";
import {
  setUserAllData,
  fetchInfoUserAll,
  setUserData,
  fetchInfoUserFav
} from "../../../store/slices/userAll";
import Alert from "../../Common/Alert/Alert";
import { BtnBlue, BtnCancelar } from "../../VacanciesDetails/MainVacancy/styled";
import { ContainerButton } from "../../Common/Alert/Styled";
import { capitalizeWords } from "../../../utils/utilidades";
const Results = () => {
  const [showModal, setshowModal] = useState(false);
  const [showModalFav, setshowModalFav] = useState(false);

  const [showDetailsUser, setshowDetailsUser] = useState([]);
  const [showDetailsUserTags, setshowDetailsUserTags] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector(store => store.userAll);
  const {data,token} = useSelector(store => store.usuario);

  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: "Persona/GetAll" });
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetAllTags" });
    const dataInteres = await fetchToken({ endpoint: "Interes/GetAllTags" });
    const dataLenguaje = await fetchToken({ endpoint: "Lenguaje/GetAllTags" });
    const skills = dataSkills;
    const interes = dataInteres;
    const lenguaje = dataLenguaje;


    let newData = dataUsers.map(item =>
      Object.assign({}, item, { habilidad: [], intereses: [], lenguajes: [] })
    );

    skills.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.habilidad.push(obj.nombre);
        }
      });
    });

    interes.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.intereses.push(obj.nombre);
        }
      });
    });
    lenguaje.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.lenguajes.push(obj.nombre);
        }
      });
    });
    dispatch(setUserAllData({post:newData,pais:'Chile'}));
    dispatch(setUserData(newData));
  };




  function showDetailsProfile(id) {
    setshowModal(true);
    const searchObject = userData.datos.find(user => user.id == id);
    const skillsMap = searchObject.habilidad.map(tag => ({
      id: v4(),
      title: tag
    }));
    const interesesMap = searchObject.intereses.map(tag => ({
      id: v4(),
      title: tag
    }));
    const lenguajesMap = searchObject.lenguajes.map(tag => ({
      id: v4(),
      title: tag
    }));
    const tagsObject = {
      skills: skillsMap,
      interests: interesesMap,
      languages: lenguajesMap
    };

    setshowDetailsUser(searchObject);
    setshowDetailsUserTags(tagsObject);
  }
  async function addFav(id){
    const dataUserFav = {
      id: id
    };
   const post = await fetchToken({
      endpoint: "Persona/AddFav",
      method: "POST",
      body: dataUserFav,
      alt: { error: true },
      token:token
    });
    if(post.FlgOk === 1){
      setshowModalFav(false)
      dispatch(fetchInfoUserFav(token));
    }
    
  }
  useEffect(() => {
    FetchData();
    dispatch(fetchInfoUserFav(token));
    dispatch(fetchInfoUserAll());
  }, []);

  //   const filtered = userData.data.filter((item) => {
  //     return item.img === "2";
  // });
  // console.log(showSkills)
  // let op = userData.datos.map((e,i)=>{
  //   let temp = showSkills.find(element=> element.id === e.id)

  //     Object.assign({}, e, {habili:"aaaa"})

  //   return e;
  // })
  // console.log(op);
 
 
  return (
    <>
      <ContainerResults>
        <TitleSection>Buscador de talentos:</TitleSection>
        <GridResults>
          {userData.datos.length > 0 ? (
            userData.datos.map(x => {
              // fetchAsync(x.email)
  
              return (
                <BoxResults key={v4()}>
                {(() => {
                  if (userData.useFav.filter(v => v.id === x.id).length > 0){
                      return (<ImgStar src={PATH + "img/icono-star.svg"} alt="fav"/>
                      ); 
                  }
                  else{
                    return false;
                  }
                })()}    
                  <Icon
                    img={
                      x?.img
                        ? `${PATH}perfil/profile${x.img}.svg`
                        : "./img/profile.svg"
                    }
                    size="80px"
                    back={x?.background ? x.background : colors.graySkeleton}
                  />
                  <h3>{capitalizeWords(x.nombreCargo)}</h3>
  
                  <TagList>
                    {x.habilidad.map(skill => {
                      return <h4 key={v4()}>{skill} /</h4>;
                    })}
                  </TagList>
                  <WhiteButton
                    onClick={() => showDetailsProfile(x.id)}
                    width="100%"
                    height="32px"
                  >
                    Ver más
                  </WhiteButton>
                </BoxResults>
              );
            })
          ) : (
            <img src={PATH + "img/no-data-search.svg"} alt="no data" />
          )
          }
        </GridResults>
      </ContainerResults>
      {showModal && (
        <ContainerModal>
          <ModalWrapper>
            <BtnClose onClick={() => setshowModal(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
            <Section>
              <div>
                <Icon img={PATH + `perfil/profile${showDetailsUser.img}.svg`} size="80px" 
                back={
                  showDetailsUser?.background ? showDetailsUser.background : colors.graySkeleton
                }
                />
              </div>
              <div>
                <Title mb="0" size="18px">
                  {showDetailsUser.nombreCargo}
                </Title>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Gerencia actual:{" "}
                  <span>{showDetailsUser.nombreVpGerencia}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Estamento: <span>{showDetailsUser.estamento}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Nivel de cargo: <span>{showDetailsUser.nivelCargo}</span>
                </SubTitle>
              </div>
            </Section>
            <Hr $margin="24px" />
            <CapSkills itemsData={showDetailsUserTags} />
            <Hr $margin="24px" />
            <div>
              <Title mb="0" size="16px">
                Descripcion personal:
              </Title>
              <SubTitle lh="24px" $margin="0" size="16px">
                {showDetailsUser.descripcion}
              </SubTitle>
            </div>
            <Hr $margin="24px" />

            {(() => {
              if (userData.useFav.filter(v => v.id === showDetailsUser.id).length > 0){
                  return false; 
              }
              else{
                return(
                  <BtnSubmit key={showDetailsUser.id} onClick={()=> {setshowModalFav(true);setshowModal(false)}}>
              Agregar a favoritos <img src={PATH + "/Iconmas.svg"} alt="" />
            </BtnSubmit>
                )
              }
              
            })()}    
          </ModalWrapper>
        </ContainerModal>
      )}
      <Alert
      estado={showModalFav}
      >
        <img src={PATH + "icono-info.svg"} alt="info"/> 
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas agregar a este colaborador a tus favoritos?
         </p>
        <ContainerButton>
          <BtnCancelar width={"150px"}  onClick={()=> {setshowModalFav(false);setshowModal(true)}}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={()=> addFav(showDetailsUser.id)}>Agregar</BtnBlue>
          
        </ContainerButton>

      </Alert>

    </>
  );
};

export default Results;
