import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchToken } from "../../customHooks/hooks";

export const fetchInfoUserAll = createAsyncThunk(
  "userAll/fetchInfoUserAll",
  async () => {
    const data = await fetchToken({ endpoint: "Persona/GetAll" });
    return data;
  }
);

export const fetchInfoUserFav = createAsyncThunk(
  "userAll/fetchInfoUserFav",
  async (token) => {
    const dataFav = await fetchToken({ endpoint: "Persona/GetFav", token:token });
    return dataFav;
  }
);

export const userAllSlice = createSlice({
  name: "userAll",
  initialState: {
    data: [],
    datos: [],
    useFav: [],
    postulo: [],
    match: [],
    loading: false,
    error: null,
    modalMobile: false,
    postfinalizado : [],
    prevCap:false,
    inputFile: false,
    inputFile2: false
  },
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    setUserAllData: (state, action) => {
      // state.datos = action.payload;
      state.datos = action.payload.post.filter(objeto => objeto.Pais === action.payload.pais);

    },
    setUserPostulo: (state, action) => {
      state.postulo = action.payload;
    },
    setPostFinalizado: (state, action) => {
      state.postfinalizado = action.payload;
    },
    setUserMatch: (state, action) => {
      // state.match = [...state.match, action.payload];
      state.match = state.match.concat(action.payload); 
    },
    clearUserMatch: (state, action) => {
      // state.match = [...state.match, action.payload];
      state.match = action.payload; 
    },
    setModalMobile: (state,action) => {
      state.modalMobile = action.payload
    },
    setPrevCap: (state,action) => {
      state.prevCap = action.payload
    },
    setInputFile: (state,action) => {
      state.inputFile = action.payload
    },
    setInputFile2: (state,action) => {
      state.inputFile2 = action.payload
    }
  },
  extraReducers: {
    [fetchInfoUserAll.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInfoUserAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchInfoUserAll.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchInfoUserFav.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInfoUserFav.fulfilled]: (state, action) => {
      state.loading = false;
      state.useFav = action.payload;
    },
    [fetchInfoUserFav.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { setUserData, setUserAllData, setUserPostulo, setUserMatch, setModalMobile, setPostFinalizado, clearUserMatch, setPrevCap, setInputFile, setInputFile2 } = userAllSlice.actions;

export default userAllSlice.reducer;
