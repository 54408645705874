import styled from "styled-components";
import { colors, scrollCustom } from "../../../../assets/variables";
import { FilterResult } from "../../../Search/Filters/Styled";

export const BoxArrow = styled.div`
  position: relative;
  width: 1px;
  height: 100%;

  & img {
    position: absolute;
  }

  img:first-child {
    top: -10px;
    right: 0;
    transform: rotate(-180deg);
  }
  img:last-child {
    bottom: -10px;
    right: 0;
  }
`;

export const Items = styled(FilterResult)`
  width: min-content;
  gap: 8px;

  h3 {
    white-space: nowrap;
  }

  img {
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  position: absolute;
  top: 100%;
  border: 1px solid ${colors.graySkeleton};
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
  padding: 10px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  ${scrollCustom}

  p {
    padding: 3px 8px;
    font-size: 0.8em;
    border-radius: 4px;
    width: min-content;
    color: ${colors.tagsBlue};
    cursor: pointer;
    border: 1px solid ${colors.ligthBlue};
    transition: background 0.1s ease-in-out;
    white-space: nowrap;

    :hover {
      background: ${colors.ligthBlue};
      transition: background 0.1s ease-in-out;
    }
  }
`;
