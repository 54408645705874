import React, { useState } from "react";
import { Hr, Title } from "../../../../Styled";
import {
  BtnAgg,
  ContainerMaintainer,
  ContainerTag,
  ContainerToggle,
  Fieldset,
  TableCustom,
  Th
} from "../Styled";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce
} from "react-table";
import { motion } from "framer-motion";
import { PATH } from "../../../../assets/variables";
import Paginacion, {
  PreviousBoard,
  ViewBoard,
  NextBoard,
  NumberBoard
} from "../ItemsBoard/Paginacion";
import { ContPageBtn, WrapperBtns } from "../ItemsBoard/Styled";
import Filter from "./Filter";
import { ContainerTitle } from "../../Styled";
import { useDispatch, useSelector } from "react-redux";
import { ContainerLeaders, ContainerLeadersMantenedor, Leader } from "../../../OfferForm/Form/InputFields/Styled";
import { fetchToken } from "../../../../customHooks/hooks";
import { fetchGetSelectors } from "../../../../store/slices/posts";
import { fetchInfoUser } from "../../../../store/slices/user";

const Toggle = ({ value, nombre }) => {

  const [act, setAct] = useState(value);
  const name = useSelector(store => store.user.data.nombreCompleto);
  const userPais = useSelector(store => store.user.data.Pais);

  const token = useSelector(store => store.usuario.token);

  const date = new Date();
  const dispatch = useDispatch();

  const handleToggle = () => {
    setAct(!act);
    // // setupdLider({
    //   fk_colaborador: nombre,
    //   activo: act,
    //   modificado_por:name,
    //   modificado_fecha: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    // //   })

      const  upd = async () => {
        const post = await fetchToken({
          endpoint: "Persona/UpdLider",
          method: "POST",
          body: {
            fk_colaborador: nombre,
            activo: !value,
            modificado_por:name,
            modificado_fecha: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
          },
          alt: { error: true }
        });
        if(post.FlgOk === 1){
          dispatch(fetchGetSelectors(userPais));
          dispatch(fetchInfoUser(token));

        }
        else{
        }
    
      };
      upd()
  };

  return (
    <ContainerToggle
      initial={{ justifyContent: "flex-start" }}
      animate={{
        // justifyContent: act ? "flex-end" : "flex-start",
        backgroundColor: act ? "#00A364" : "#999999"
      }}
      onClick={handleToggle}
    >
      <motion.div
        animate={{
          x: act ? "24px" : "0"
        }}
        transition={{ type: "Tween" }}
      />
    </ContainerToggle>
  );
};

const LeaderBoard = ({ columns }) => {
  const nameUser = useSelector(store => store.user.data.nombreCompleto);
  const userPais = useSelector(store => store.user.data.Pais);
  const dispatch = useDispatch();
  const  add = async () => {
    const post = await fetchToken({
      endpoint: "Persona/AddLider",
      method: "POST",
      body: newCapData,
      alt: { error: true }
    });
    if(post.FlgOk === 1){
      dispatch(fetchGetSelectors(userPais));
    }

  };

  const [newCapData, setNewCapData] = useState({
    fk_colaborador: 0,
    activo: 1,
    creado_por:"",
    creado_fecha: "",
    modificado_por: "",
    modificado_fecha: ""
  });

  const [leaderToSearch, setLeaderToSearch] = useState({
    name: "",
    status: false // con esta propiedad controlo cuando ya le dio click a un usuario y se seteo en el objeto principal
  });
  const data = useSelector(store => store.posts.selectors.lideres);
  const dataFilter = data.filter(x => x.id == 27)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useGlobalFilter,
    usePagination
  );

  const leader = useSelector(store => {


    const leaderSplit = leaderToSearch.name.split(" ");
    let finalArray = [];
    for (let i = 0; i < leaderSplit.length; i++) {
      if (i == 0) {
        finalArray = store.posts.selectors.perfilesLider.filter(
          opt =>
            opt.nombreCompleto
              .toLowerCase()
              .includes(leaderSplit[i].toLowerCase()) ||
            opt.email.toLowerCase().includes(leaderSplit[i].toLowerCase())
        );
      } else {
        finalArray = finalArray.filter(
          opt =>
            opt.nombreCompleto
              .toLowerCase()
              .includes(leaderSplit[i].toLowerCase()) ||
            opt.email.toLowerCase().includes(leaderSplit[i].toLowerCase())
        );
      }
    }

    return finalArray;
  });
  const handleLeader = e => {
    setLeaderToSearch({
      name: e.target.value,
      status: false
    });
  };
  const handleSelectLeader = e => {
    const date = new Date();
    setNewCapData({
    fk_colaborador: Number(e.target.dataset.value),
    activo: 1,
    creado_por:nameUser,
    creado_fecha: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
    modificado_por: "",
    modificado_fecha: ""
    });
    setLeaderToSearch({
      name: e.target.dataset.name,
      status: true
    });
  };
  return (
    <ContainerMaintainer>
      <Title size="20px" mb="32px">
        Ingreso de nuevo lider
      </Title>
      <ContainerTag>
        <Fieldset>
          <input
          onChange={handleLeader}
          placeholder="Mail o Nombre del Lider"
          value={leaderToSearch.name}
        />
        </Fieldset>
        <BtnAgg blue onClick={add}>
          Agregar <img src={PATH + "img/Iconmas.svg"} alt="add"  />
        </BtnAgg>
        {leaderToSearch.name.length > 3 && !leaderToSearch.status && (
          <ContainerLeadersMantenedor>
            {leader.length > 0 ? (
              leader.map(l => (
                l.activo === null ? 
                <Leader
                  key={l.id}
                  data-key="lider"
                  data-value={l.id}
                  data-name={l.nombreCompleto}
                  onClick={handleSelectLeader}
                >
                  <h3>{l.nombreCompleto}</h3>
                  <p>{l.email}</p>
                </Leader> : (<Leader>
                  <h3>{l.nombreCompleto}</h3>
                <p>Colaborador ya agregado como lider</p>
              </Leader>)
              ))
            ) : (
              <Leader>
                <h3>No hay resultados</h3>
                <p>Recuerda separar los nombres por un espacio</p>
              </Leader>
            )}
          </ContainerLeadersMantenedor>
        )}
      </ContainerTag>
      <Hr $margin="24px 0" />
      <Title size="22px" mb="29px">
        Lista de Lideres
      </Title>
      <ContainerTitle>
        <Paginacion pageSize={pageSize} setPageSize={setPageSize} />
        <Filter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          useAsyncDebounce={useAsyncDebounce}
        />
      </ContainerTitle>

      <TableCustom {...getTableProps()}>
        <thead>
          {
            // Recorremos las columnas que previamente definimos
            headerGroups.map(headerGroup => (
              // Añadimos las propiedades al conjunto de columnas
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Recorremos cada columna del conjunto para acceder a su información
                  headerGroup.headers.map(column => (
                    // Añadimos las propiedades a cada celda de la cabecera
                    <Th id={column.id} {...column.getHeaderProps()}>
                      <Title size="16px" bold="500" lh="19px">
                        {
                          // Pintamos el título de nuestra columna (propiedad "Header")
                          column.render("Header")
                        }
                      </Title>
                    </Th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Recorremos las filas
            page.map(row => {
              // Llamamos a la función que prepara la fila previo renderizado
              prepareRow(row);
              return (
                // Añadimos las propiedades a la fila

                <tr {...row.getRowProps()}>
                  {
                    // Recorremos cada celda de la fila
                    row.cells.map(cell => {
                      // Añadimos las propiedades a cada celda de la fila
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Pintamos el contenido de la celda
                            cell.column.id === "activo" ? (
                              <Toggle value={cell.value} nombre={cell.row.original.fk_colaborador}/>
                            ) : (
                              cell.render("Cell")
                            )
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </TableCustom>
      <ContPageBtn>
        <ViewBoard data={data} pageIndex={pageIndex} pageSize={pageSize} />
        <WrapperBtns>
          <PreviousBoard
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
          />
          <NumberBoard
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
          />

          <NextBoard canNextPage={canNextPage} nextPage={nextPage} />
        </WrapperBtns>
      </ContPageBtn>
    </ContainerMaintainer>
  );
};

export default LeaderBoard;
