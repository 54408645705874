import styled from "styled-components";
import { colors, scrollCustom } from "../../assets/variables";
import { ContainerGrid } from "../Home/Styled";
import { ContainerHalf } from "../VacanciesDetails/Style";

export const ContainerGridCustom = styled(ContainerGrid)`
    position: fixed;
    top: 0;
    margin: 0 auto;

    & > div {
        height: 100vh;
    }
`;

export const ContainerHalfCustom = styled(ContainerHalf)`
    height:${props => props.postulante === "postulo" ? "100vh" : "240px"};
    border-top-left-radius: ${props => props.postulante === "postulo" ? "0px" : "8px"};
    border-top-right-radius: ${props => props.postulante === "postulo" ? "0px" : "8px"};
    border-bottom-left-radius: ${props => props.postulante === "postulo" ? "8px" : "0px"};
    border-bottom-right-radius: ${props => props.postulante === "postulo" ? "8px" : "0px"};
    & > section {
        padding: 0 16px;
        height: 164px;
        height:${props => props.postulante === "postulo" ? "111px" : "150px"};
        position: relative;

        & > div {
            border-bottom: 1px solid #e5e5e5;

            :last-child {
                border-bottom: none;
            }
        }
    }
    section{
        button{
            
        }
    }
`;
