import styled from "styled-components";

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: 30px;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;
