import styled from "styled-components";
import { colors } from "../../../../../../../assets/variables";

export const WrapperStory = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    height: 100%;
    width: 350px;
    object-fit: contain;
    aspect-ratio: 16/9;
    border-radius: 8px;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 4px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    text-align: center;
    margin-bottom: 16px;
  }

  button {
    background: #ffffff;
    border: 1px solid #002eff;
    border-radius: 28px;
    padding: 8px 24px;
    color: #002eff;
    cursor: pointer;
  }
`;

export const WrapperText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 17px 17px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 62, 62, 0.5) 19%,
    rgba(0, 0, 0, 1) 100%
  );
  width: 351px;
  border-radius: 8px;
  overflow: hidden;
  a{
    background: #FFFFFF;
    height: 40px;
    display: flex;
    align-items: center;
    color: #002EFF;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #002EFF;
    border-radius: 28px;
    padding-left: 24px;
    padding-right: 24px;
    text-decoration: none;
  }
`;

export const HelperWrapper = styled.div`
  position: relative;
  height: 83%;
  width: 350px;
  background-color: #000;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const MinStory = styled.div`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #e2e2e2;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 20;
`;

export const MinStoryCap = styled.div`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #e2e2e2;
  position: absolute;
  top: 90px;
    left: 55px;
  transform: translate(-50%, -50%);
  z-index: 20;
`;

export const Close = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  display: flex;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;

  img {
    margin-left: 12px;
    cursor: pointer;
  }
`;
