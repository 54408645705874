import styled from "styled-components";

export const WrapperGeneral = styled.div`
  background-color: #fff;
  min-height: 100vh;
  background-color: #f5f5f5;
`;

export const ChildrenContainer = styled.div`
  margin: 0 auto;
  width: 1110px;
  padding-top: 40px;
  @media (max-width: 600px) {
    width:100%;
    padding: 0 28px;
  }
`;
