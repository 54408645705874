import styled from "styled-components";
import { colors } from "../../../../assets/variables";

export const ContainerMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  height: 100%;

  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: #002EFF;
    font-size:18px;
    font-weight: 600;

  }
  .menu-activo{
      display: none;
    }
  .active{
    .icono-menu{
      display: none;
    }
    .menu-activo{
      display: flex;
      background: #F3F5FF;
      padding: 10px 16px;
      border-radius: 24px;
      gap: 7px;
      align-items: center;
    }
  }
  /* .active:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 4px solid ${colors.pink};
    border-radius: 2px 2px 0px 0px;
  } */
`;

export const Divider = styled.section`
  width: 2px;
  height: 40px;
  background-color: #fff;
`;

export const BtnAdmin = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-size:18px;
  font-weight: 600;
  color: #002EFF;

  /* :before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 4px solid ${colors.pink};
    border-radius: 2px 2px 0px 0px;
    display: ${({ admin }) => (admin ? "block" : "none")};
  } */
  /* &:hover{
    .icono-menu{
      display: none;
    }
    .menu-activo{
      display: flex;
      background: #F3F5FF;
      padding: 10px 16px;
      border-radius: 24px;
      gap: 7px;
      align-items: center;
    }
  } */
`;
