import { motion } from "framer-motion";
import styled from "styled-components";

export const Video = styled.video`
  width: 100%;
  /* height: 240px; */
  aspect-ratio:inherit!important;
  object-fit: cover!important;
  border-radius: 8px;
  /* position: relative; */
`;

export const Play = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  cursor: pointer;
  width: 61px;
  height: 61px!important;
`;

export const WrapperVideo = styled.div`
  width: 100%;
  /* aspect-ratio: 16/9; */
  position: relative;
  height: ${props => props.custom ? "100%" : "auto"};
`;

export const ImgCap = styled.img`
  width: 100%;
  /* aspect-ratio: 16/9; */
  /* object-fit: cover; */
  border-radius: 8px;
  height: ${props => props.custom ? "100%" : "auto"};
  object-fit: ${props => props.custom  ? "contain" : "initial"};

`;
export const ImgCapPost = styled(ImgCap)`
   height: auto;
`;