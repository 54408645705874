import styled from "styled-components";
import { colors, PATH } from "../../../../assets/variables";

export const NoFile = styled.div`
  width: 100%;
  height: ${({ story }) => (story ? "100%" : "285px")};
  background-color: ${({ story }) => (story ? "#000" : colors.graySkeleton)};
  border-radius: 8px;
  background-image: url(${PATH}img/Play.svg);
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  ::before {
    content: "No ingresado";
    background-color: #fff;
    padding: 7px 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 8px;
  }
`;

export const CapImg = styled.img`
  width: 100%;
  height: 285px;
  border-radius: 8px;
  object-fit: cover;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 30px;
`;
