import styled from "styled-components";

export const ContainerProfile = styled.div`
  border-radius: 8px;
  background-color: #fff;
  height: min-content;
  padding: 16px;
  /* position: sticky;
  top: 100px; */
  //solo para diferenciar, borrar luego
  box-shadow: 0 0 4px #c2c2c2;
  @media (max-width: 600px) {
    display: none;
  }
`;
