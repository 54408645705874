import styled from "styled-components";
import { colors, scrollCustom } from "../../../assets/variables";
import { ContainerButton } from "../../Common/Alert/Styled";


export const SuggestStyled = styled.div`
  display: none!important;
  background-color: #002EFF;
  height: 80px;
  border-radius: 8px;
  width: 100%;
  /* position: sticky;
  top: 100px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  margin-bottom: 15px;
  h3{
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    span{
        display: block;
        font-weight: 600;
    }
  }
  @media (max-width: 600px) {
    order: 1;
    margin-top: 40px;
   }
`;
export const SuggestButton= styled.div`
    width: 104px;
    background: ${colors.mainBlue};
    height: 40px;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 16px 0 12px;
    margin-top: 12px;
    cursor: pointer;

`
export const ContainerAdd = styled.div`
    width: 100%;
    text-align: left;
    /* height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    ${scrollCustom} */
    h4{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    input{
        height: 56px;
        border: 1px solid #666666;
        border-radius: 8px;
        width: 100%;
        outline: none;
    }
`;

export const ContainerInput = styled.div`
    display: grid;
    grid-template-columns: 450px 1fr;
    gap: 16px;
    align-items: center;
    margin-bottom: 24px;
    img{
        cursor: pointer;
    }
    @media (max-width: 600px) {
        grid-template-columns: 80% 20%;
   }
`;

export const ContainerSkills = styled.div`
    display: flex;
    max-height: 200px;
    background: #DDDDDD;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
    gap: 8px;
    flex-wrap: wrap;
    h3{
        margin: 0;
    }
`;
export const ContainerImportante = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #FEF9EB;
    width:100%;
    margin: 24px 0;
    padding: 10px;
    border: 1px solid #F8B106;
    border-radius: 8px;
    h4{
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 6px;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 0;
    }
`;

export const ContainerColores = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    h4{
        font-size: 16px;
        font-weight: 500;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 0;
    }
    div{
        display: flex;
        align-items: center;
        gap: 8px;
        h5{
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            margin: 0;
        }
    }

`;
export const ColorCuadrado = styled.span`
    width: 20px;
    height: 20px;
    background: #BFCBFF;
    background: ${props => props.color ? props.color : '#FFFFFF'};
    border: 1px solid #F7F7F7;
`;
export const ContainerButtonSuggest = styled(ContainerButton)`
    width: 100%;
    position: sticky;
    bottom: -21px;
    background: white;
    padding-top: 12px;
`