import { motion } from "framer-motion";
import styled, { createGlobalStyle } from "styled-components";
import { colors, scrollCustom } from "./assets/variables";

export const GlobalStyle = createGlobalStyle`
html{
        box-sizing: border-box;
    }
    *,*::before,*::after{
        box-sizing: border-box;
    }
    body{
        margin: 0;
        font-family: 'Barlow', sans-serif;
        h1,h2,h3,h4,h5,h6,p{
            margin: 0;
        }
        hr{
            border-bottom: 1px solid #E5E5E5;
            width: 100%;
        }

        .slick-arrow {
          opacity: 1;
          transition: opacity .5s ease;
        }

        .slick-disabled{
          opacity: 0;
          cursor: default;
          transition: opacity .5s ease;
        }

        ${scrollCustom}

        .blue_border {
          outline: 2px solid ${colors.mainBlue};
        }
    }
    .StoryPrev{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
    .storyMantenedor{
      /* display: grid;
      grid-template-columns: 1fr; */
      img:not([alt*="Play"]), video {
        height: 200px;
        width: 200px;
}
img[alt*="Play"]{
display: none;
}
img[alt*="eliminar"]{
width: 24px;
margin-left: 10px;
cursor: pointer;
}
    }
    .contenedorColores{
      @media (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: flex-start!important;
        gap: 7px;
      }
    }
    .seccionEditUser{
      @media (max-width: 600px) {
        flex-direction: column!important;;
      }
    }
`;

export const Hr = styled.div`
  border-bottom: 1px solid #e5e5e5;
  width: auto;
  margin: ${({ $margin }) => $margin};
`;

export const Tag = styled.div`
  display: inline-block;
  padding: 3px 16px 4px 12px;
  background-color: ${({ background }) => background};
  border-radius: 20px;
  color: ${colors.tagsBlue};
  font-weight: ${({ bold }) => (bold ? "500" : "400")};
  max-width: 205px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const SubTitle = styled.h2`
  font-weight: 400;
  font-size: ${({ size }) => (size ? size : "16px")};
  line-height: ${({ lh }) => (lh ? lh : "24px")};
  margin: ${({ top }) => (top ? "0 0 8px" : "8px 0")};
  ${({ top }) => top && "border-bottom: 1px solid #e5e5e5;"}
  ${({ top }) => top && "padding-bottom: 8px;"}
  margin: ${({ $margin }) => $margin && $margin};
  span {
    font-weight: 600;
  }
`;

export const ContainerModal = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(2, 2, 71, 0.8);
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .slick-dots{
    bottom: -30px!important;
    width: 28%;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    left: 0;
    right: 0;
}
.slick-dots li button:before{
    color: #CCCCCC;
    opacity: 1;
    font-size: 17px;
}
.slick-dots li.slick-active button:before{
    color: #002EFF;
}
.contenedorModal{
  h5{
    color: #002EFF;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 16px;
  }
  h6{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
  }
  p{
    font-size: 18px;
    font-weight: 400;
  }
  img{
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    h5{
      font-size: 22px;
    }
    h6{
      font-size: 16px;
    }
    p{
      font-size: 13px;
      text-wrap: balance;
      margin-bottom: 15px;
      padding: 0 57px;
    }
   }
}
.bannerBienvenida{
  height: 200px;
  max-width: 100%;
  margin-bottom: 30px!important;
}
@media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
   }
`;

export const Title = styled.h3`
  font-weight: ${({ bold }) => (bold ? bold : "600")};
  font-size: ${({ size }) => size};
  line-height: ${({ lh }) => (lh ? lh : "24px")};
  color: ${({ color }) => (color === "blue" ? "#002eff" : "#191919")};
  margin-bottom: ${({ mb }) => (mb ? mb : "8px")};
  white-space: ${({ linear }) => linear && "nowrap"};
  padding: ${({ p }) => p};
  text-align: ${({ align }) => align};
  small{
    color: #333333;
    font-size: 14px;
    font-weight: 400;
  }
`;
