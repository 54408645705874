import styled from "styled-components";
import { colors } from "../../../../assets/variables";

export const BtnSubmit = styled.div`
    background-color: ${colors.mainBlue};
    color: #fff;
    width: max-content;
    gap: 8px;
    border-radius: 50px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 16px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 12px;
`;

export const BtnClose = styled.div`
    position: absolute;
    top: -30px;
    right: 0;
    cursor: pointer;

    img {
        width: 23px;
        height: 23px;
    }
`;

export const ModalWrapper = styled.div`
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    width: 540px;
`;

export const Section = styled.div`
    display: flex;
`;
