import React from "react";
import { useSelector } from "react-redux";
import { Item, SectionItems, SectionItemsTag, SectionsContainer } from "./Styled";

const CapSkills = ({ itemsData, off }) => {
  const { skills, languages, interests } = useSelector(store => store.user);

  const matchData = (array, value) => {
    return off ? false : array.some(obj => Number(obj.id) === Number(value));
  };

  return (
    <SectionsContainer>
        <SectionItems>
        <h3>Habilidades</h3>
        <SectionItemsTag>
        {itemsData.skills.map(item => (
          <Item check={matchData(skills, item.id)} key={item.id} title={item.title}
          >
            {item.title}
          </Item>
        ))}
        </SectionItemsTag>
      </SectionItems>
      <SectionItems>
        <h3>Intereses</h3>
        <SectionItemsTag>
        {itemsData.interests.map(item => (
          <Item check={matchData(interests, item.id)} key={item.id} title={item.title}>
            {item.title}
          </Item>
        ))}
      </SectionItemsTag>
      </SectionItems>
      <SectionItems last>
        <h3>Idiomas</h3>
        {itemsData.languages.map(item => (
          <Item check={matchData(languages, item.id)} key={item.id} title={item.title}>
            {item.title}
          </Item>
        ))}
      </SectionItems>
    </SectionsContainer>
  );
};

export default CapSkills;
