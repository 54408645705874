import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "../../assets/variables";
import { ChildrenContainer } from "../MasterPage/Style";

export const HeaderContainer = styled.header`
  height: 90px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const HeaderContainerMobile = styled.header`
  height: 60px;
  background-color: #ffffff;
  display: none;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  justify-content: space-between;
  @media (max-width: 600px) {
    display: flex;
    padding: 0 28px;
    justify-content: flex-start;
    gap: 8px;
  }
`;

export const MenuMobile = styled(motion.div)`
  height: 120px;
  background-color: #ffffff;
  margin-top: -20px;
  position: fixed;
  width: 100%;
  z-index: 50;
  div{
    margin-top: 20px;
    padding: 0 28px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    a{
      display: flex;
      align-items: center;
      gap: 0 17px;
      color: #191919;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      padding: 8px 0;
      &:hover{
        background: #ffffff;
        color: #002eff
      }
      img{
      width: 22px;
      }
    }

  }
`;

export const HeaderStyle = styled(ChildrenContainer)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
export const logoStyle = styled.img`
   width: 100px;
`;
export const AdminContainer = styled(motion.div)`
  height: 50px;
  /* top: 60px; */
  background-color: #fff;
  box-shadow: 0 0 4px ${colors.graySkeleton};
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 98;
  top: 80px;
`;

export const AdminStyle = styled(ChildrenContainer)`
  display: flex;
  justify-content: flex-end;
  padding: 0;

  div {
    padding: 0 26px;
    position: relative;

    a {
      text-decoration: none;
      color: #000;
      position: relative;
      height: 50px;
      display: flex;
      align-items: center;
    }

    :before {
      content: "";
      width: 0.5px;
      height: 25px;
      background: #000;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    :last-child {
      padding-right: 0;
      :before {
        display: none;
      }
    }
  }

  .active {
    color: ${colors.mainBlue};
    font-weight: 600;

    :before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 4px solid ${colors.pink};
      border-radius: 2px 2px 0px 0px;
    }
  }
`;
