import React, { useEffect, useRef, useState } from "react";
import { PATH } from "../../../assets/variables";
import { Title } from "../../../Styled";
import {
  Close,
  HelperWrapper,
  MinStory,
  WrapperText
} from "../../Home/Feed/SectionStories/Stories/MainStory/Styled";
import { StoryStyle } from "../../Home/Feed/SectionStories/Stories/Story/Styled";
import { NoFile } from "../../OfferForm/PrevCap/Styled";
import { ContainerHalfCustom } from "../../OfferForm/Styled";
import { OffSet } from "../../VacanciesDetails/Style";
import {
  ContainerStoryPrev,
  ImgStory,
  WrapperStory,
  WrapperStoryCustom
} from "./Styled";

const VideoOrImg = ({ file, video }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", event => {
        setSrc(event.target.result);
      });
      reader.readAsDataURL(file);
    }
  }, [file]);

  if (!file) {
    return <NoFile story />;
  }

  if (file.type.startsWith("image/")) {
    return <ImgStory src={src} alt="previsualizacion de imagen" />;
  }

  if (file.type.startsWith("video/")) {
    return <video loop src={src} ref={video} />;
  }
};

const ImgProfileStory = ({ file2 }) => {
  const [srcStory, setSrcStory] = useState(null);

  useEffect(() => {
    if (file2) {
      const reader = new FileReader();
      reader.addEventListener("load", event => {
        setSrcStory(event.target.result);
      });
      reader.readAsDataURL(file2);
    }
  }, [file2]);

  if (!file2) {
    return <img src={PATH + "img/profile.svg"} alt="Profile" />;
  }


    return <img src={srcStory} alt="previsualizacion de imagen" />;

};


const StoryPrev = ({ newStoryData, setNewStoryData }) => {
  const video = useRef(null);
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (newStoryData.file) {
      const reader = new FileReader();
      reader.addEventListener("load", event => {
        setSrc(event.target.result);
      });
      reader.readAsDataURL(newStoryData.file);
    }
  }, [newStoryData.file]);

  useEffect(() => {
    video.current?.play();
  }, [video.current]);

  const handlePlay = e => {
    if (e._reactName === "onMouseDown") {
      video.current?.pause();
    } else {
      video.current?.play();
    }
  };

  return (
    <div>
      <OffSet />
      <ContainerHalfCustom section_scroll header_height="24">
        <Title size="20px">Vista previa:</Title>
        <section>
          <ContainerStoryPrev>
            <WrapperStoryCustom onMouseDown={handlePlay} onMouseUp={handlePlay}>
              <Close>
                Destacadas!
                <img src={PATH + "img/Cerrar-azul.svg"} alt="close" />
              </Close>
              <MinStory>
                <StoryStyle>
                  <ImgProfileStory file2={newStoryData.file2} />
                </StoryStyle>
              </MinStory>
              {/* <video loop src={src} ref={video} /> */}
              <VideoOrImg file={newStoryData.file} video={video} />
              <WrapperText>
                <h3>{newStoryData.titulo}</h3>
                <p>{newStoryData.bajada}</p>
                {newStoryData.agregarBtn && <button>Ver màs</button>}
              </WrapperText>
            </WrapperStoryCustom>
          </ContainerStoryPrev>
        </section>
      </ContainerHalfCustom>
    </div>
  );
};

export default StoryPrev;
