import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "../../../assets/variables";
import { setModalMobile, setUserPostulo } from "../../../store/slices/userAll";
import MainVacancy from "./MainVacancy/MainVacancy";
import { BackBtn, BtnCloseMobile, ContainerGridCustom, ContainerHalfModal } from "./Style";
import Vacancies from "./Vacancies/Vacancies";

const VacancyDetails = () => {
  const [showModal, setshowModal] = useState(true);
  const modalMobile = useSelector(store => store.userAll.modalMobile)

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(setUserPostulo(""))
    
  }, [])
  

  return (
    <>
      <BackBtn onClick={() => navigate("/inicio/")}>
        <img src={PATH + "/LeftArrowBlue.svg"} alt="Arrow" /> Volver
      </BackBtn>
      <ContainerGridCustom columns="540px 540px">
        <Vacancies />
        {
          modalMobile && (
            <ContainerHalfModal>
            <BtnCloseMobile >
            <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" onClick={()=> dispatch(setModalMobile(false))}  />
            </BtnCloseMobile>
            <MainVacancy id={id} />
          </ContainerHalfModal>
          )
        }


      </ContainerGridCustom>
    </>
  );
};

export default VacancyDetails;
