import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PATH } from "../../../../assets/variables";
import { TypeManteinerStyle } from "./Style";
import { ReactComponent as UsersLogo } from "../../../../assets/img/Usuarios.svg";
import { ReactComponent as LaptopLogo } from "../../../../assets/img/Laptop.svg";
import { ReactComponent as StarLogo } from "../../../../assets/img/club_entel.svg";
import { ReactComponent as IdeaLogo } from "../../../../assets/img/Idea.svg";
import { ReactComponent as PublicacionLogo } from "../../../../assets/img/icono-publicaciones-mantenedor.svg";


const TypesMaintainers = () => {
    return (
        <div>
            <TypeManteinerStyle>
                <NavLink to="lideres">
                    <UsersLogo fill="#002eff" />
                    <div>
                        <p>Mantenedor</p>
                        <p>Lideres</p>
                    </div>
                </NavLink>
            </TypeManteinerStyle>
            <TypeManteinerStyle>
                <NavLink to="habilidades">
                    <LaptopLogo fill="#002eff" />
                    <div>
                        <p>Mantenedor</p>
                        <p>Habilidades</p>
                    </div>
                </NavLink>
            </TypeManteinerStyle>
            <TypeManteinerStyle>
                <NavLink to="intereses">
                    <StarLogo fill="#002eff" />
                    <div>
                        <p>Mantenedor</p>
                        <p>Intereses</p>
                    </div>
                </NavLink>
            </TypeManteinerStyle>
            <TypeManteinerStyle>
                <NavLink to="lenguajes">
                    <IdeaLogo fill="#002eff" />
                    <div>
                        <p>Mantenedor</p>
                        <p>Lenguajes</p>
                    </div>
                </NavLink>
            </TypeManteinerStyle>
            <TypeManteinerStyle>
                <NavLink to="posts">
                <PublicacionLogo fill="#002eff"/>
                    <div>
                        <p>Matenedor</p>
                        <p>Publicaciones</p>
                    </div>
                </NavLink>
            </TypeManteinerStyle>
        </div>
    );
};

export default TypesMaintainers;
