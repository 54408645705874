import { createSlice } from "@reduxjs/toolkit";
import { fetchToken } from "../../customHooks/hooks";

/*  
estructura obligatoria de los objetos de generalData 
    { 
        fecha_recepcion: String,
        creador: String,
        nombre: String,
        id: Number
    } 
*/

export const generalDataSlice = createSlice({
  name: "generalData",
  initialState: {
    skills: [],
    languages: [],
    interests: []
  },
  reducers: {
    setGeneralData: (state, action) => {
      state.skills = action.payload.skills;
      state.languages = action.payload.languages;
      state.interests = action.payload.interests;
    }
  }
});

const helperUpdateData = async (fetchCustom, dispatch, reducer, token) => {
  const languagesServ = await fetchCustom({
    endpoint: "Lenguaje/GetAll",
    alt: [],
    token:token
  });
  const interestsServ = await fetchCustom({
    endpoint: "Interes/GetAll",
    alt: [],
    token:token
  });
  const skillsServ = await fetchCustom({
    endpoint: "Habilidad/GetAll",
    alt: [],
    token:token
  });

  const languages = languagesServ.map(l => ({
    ...l,
    fecha_creacion: new Date(l.fecha_creacion).toLocaleDateString()
  }));
  const skills = skillsServ.map(s => ({
    ...s,
    fecha_creacion: new Date(s.fecha_creacion).toLocaleDateString()
  }));
  const interests = interestsServ.map(i => ({
    ...i,
    fecha_creacion: new Date(i.fecha_creacion).toLocaleDateString()
  }));

  dispatch(
    reducer({
      skills,
      languages,
      interests
    })
  );
};

export const { setGeneralData } = generalDataSlice.actions;

export const fetchGeneralData = (token) => async dispatch => {
  await helperUpdateData(fetchToken, dispatch, setGeneralData, token);
};

export const addData = (obj, text) => async dispatch => {
  await fetchToken({
    endpoint: `${text}/Add`,
    method: "POST",
    body: obj,
    alt: []
  });

  await helperUpdateData(fetchToken, dispatch, setGeneralData);
};

export const deleteData = (id, callback, endpoint) => async dispatch => {
  await fetchToken({
    endpoint: `${endpoint}/Delete`,
    method: "POST",
    body: { id },
    alt: []
  });

  await helperUpdateData(fetchToken, dispatch, setGeneralData);
  callback();
};

export default generalDataSlice.reducer;
