import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const WrapperVacancy = styled(NavLink)`
  text-decoration: none;
  color: initial;
  & > div {
    padding: 24px 20px;
    border-top: 1px solid #e5e5e5;

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #002eff;
      margin-bottom: 8px;
    }

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    p{
      white-space: break-spaces;
    }
  }
`;
