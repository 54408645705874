import React, { useState } from "react";
import { Arrow, Shadow, WrapperStories } from "./Styled";
import Slider from "react-slick";
import { ContainerModal } from "../../../../../Styled";
import Story from "./Story/Story";
import MainStory from "./MainStory/MainStory";
import { AnimatePresence } from "framer-motion";
import { useFetch, useFetchPublic } from "../../../../../customHooks/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import userAll from "../../../../../store/slices/userAll";

//componente de flechas del carrusel, el atributo "prev" indica la flecha a la izquierda.
const NextPreviousSlide = ({ className, onClick, prev }) => {
  return <Arrow prev={prev} className={className} onClick={onClick}></Arrow>;
};

//componente que muestra ambos carruseles de historias (miniaturas y desplegables).
const Stories = () => {
  const dataId = useSelector(store => store.user.data)
  const idmatches = useSelector(store => store.userAll.match)

  const [slideInitial, setSlideInitial] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState({ current: 0, next: 0 });
  const infoStories = useFetchPublic({
    url: "https://api-selecciontalento.azurewebsites.net/api/Publicacion/GetDestacadas",
    options: {}
  });
  //configuracion de el primer carrusel que corresponde al que contiene las miniaturas.
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5.5,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextPreviousSlide />,
    prevArrow: <NextPreviousSlide prev />
  };

  //configuracion de el segundo carrusel que corresponde al que contiene las historias desplegables.
  const settings2 = {
    dots: false,
    nextArrow: <NextPreviousSlide />,
    speed: 150,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    initialSlide: slideInitial,
    prevArrow: <NextPreviousSlide prev />,
    infinite: false,
    beforeChange: (current, next) => setActiveSlide({ current, next })
  };
// console.log("dataId", dataId);
// console.log("idmatches", idmatches.filter(x => x.fk_persona === 3876 && x.average >= 50));
// console.log("infoStories", infoStories)
  const [carrusel, setCarrusel] = useState([])
  useEffect(() => {
    let filtradoCarrusel= []
    infoStories.results?.map((user, i) =>{
      if(idmatches.filter(x => x.idpost === user.fk_publicacion && x.fk_persona === dataId.fk_colaborador && x.average >= 50).length > 0){
        filtradoCarrusel.push(user)
      }
      if(user.tipo && user.Pais == 'Chile'){
        filtradoCarrusel.push(user)
      }
    })
    setCarrusel(filtradoCarrusel)
  }, [infoStories.results, idmatches, dataId])
  

  return (
    <>

      <AnimatePresence>
        {showModal && (
          <ContainerModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-modal="true"
            onMouseDown={e => e.target.dataset.modal && setShowModal(false)}
          >
            {carrusel.length == 1 ? (
              <div>
              {/* {infoStories.results?.map((user, i) => {
              return idmatches.filter(x => x.idpost === user.fk_publicacion && x.fk_persona === dataId.fk_colaborador && x.average >= 50).length > 0 ?(
                <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory>)  : user.tipo ? (
                  <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory> 
                ) : false
              })} */}
              
              {carrusel.map((user, i) => (
                <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory>
              ))} 
            </div>
            ) : (
              <Slider
              style={{
                width: "440px",
                height: "100%"
              }}
              {...settings2}
            >
              {/* {infoStories.results?.map((user, i) => {
              return idmatches.filter(x => x.idpost === user.fk_publicacion && x.fk_persona === dataId.fk_colaborador && x.average >= 50).length > 0 ?(
                <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory>)  : user.tipo ? (
                  <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory> 
                ) : false
              })} */}
              
             {carrusel.map((user, i) => (
                <MainStory
                  key={user.id}
                  setShowModal={setShowModal}
                  user={user}
                  activeSlide={activeSlide}
                  i={i}
                  infoStories={infoStories}
                ></MainStory>
              ))} 
            </Slider>
            )}

          </ContainerModal>
        )}
      </AnimatePresence>
      <WrapperStories>
        <Shadow left />
        <Slider {...settings}>
          {carrusel.map((user, i) => {
            // return user.fk_publicacion === 46 ?(
            // return idmatches.filter(x => x.idpost === user.fk_publicacion && x.fk_persona === dataId.fk_colaborador && x.average >= 50).length > 0 ?(
            //   <Story
            //   destacada={true}
            //   setShowModal={setShowModal}
            //   key={user.id}
            //   user={user}
            //   setSlideInitial={setSlideInitial}
            //   setActiveSlide={setActiveSlide}
            //   infoStories={infoStories}
            //   i={i}
            // ></Story>
            // ) : user.tipo ? (
            //   <Story
            //   destacada={false}
            //   setShowModal={setShowModal}
            //   key={user.id}
            //   user={user}
            //   setSlideInitial={setSlideInitial}
            //   setActiveSlide={setActiveSlide}
            //   infoStories={infoStories}
            //   i={i}
            // ></Story>
            // ) : false
            return(
            <Story
            destacada={!user.tipo}
            setShowModal={setShowModal}
            key={user.id}
            user={user}
            setSlideInitial={setSlideInitial}
            setActiveSlide={setActiveSlide}
            infoStories={infoStories}
            i={i}
          ></Story>
            )
            })}
        </Slider>
        <Shadow />
      </WrapperStories>
    </>
  );
};

export default Stories;
