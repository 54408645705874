import React from "react";
import { colors, PATH } from "../../../../../assets/variables";
import { Hr } from "../../../../../Styled";
import { DescriptionStyle } from "./Styled";
import { motion } from "framer-motion";
import { TextSkeleton } from "../../../Common/Skeleton/Styled";
import { Icon } from "../../../Common/IconProfile/Styled";
import { capitalizeWords, removeParentesis } from "../../../../../utils/utilidades";

const DescriptionProfile = ({ userData }) => {
  return (
    <>
      <DescriptionStyle>
        <Icon
          img={
            userData?.img
              ? `https://colaboradordigital.blob.core.windows.net/ematch/perfil/profile${userData.img}.svg`
              : './img/profile.svg'
          }
          size="150px"
          back={
            userData?.background ? userData.background : colors.graySkeleton
          }
        />
        {userData?.nombreCargo ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <h1>{capitalizeWords(userData.nombreCompleto)}</h1>
            <h2>{capitalizeWords(userData.nombreCargo)}</h2>
            <h2>{ removeParentesis(capitalizeWords(userData.nombreVpGerencia) || "Sin informacion")}</h2>
          </motion.div>
        ) : (
          <>
            <TextSkeleton m_top="16px" width="80%" />
            <TextSkeleton m_top="16px" width="70%" />
            <TextSkeleton m_top="16px" width="50%" />
          </>
        )}
      </DescriptionStyle>
      <Hr />
      {userData.nombreCV != null && (
        <>
        <div style={{paddingBottom:16,paddingTop:16}}>
      <h5 style={{fontSize:16, fontWeight:600, marginBottom:5}}>Tu CV</h5>
      <a style={{color:'#0C0C0C'}} href={`https://colaboradordigital.blob.core.windows.net/ematch/cv/${userData.nombreCV}`} target="_blank" rel="noopener noreferrer">{userData.urlCV}</a>
      </div>
      <Hr />
      </>
      )}
    </>
  );
};

export default DescriptionProfile;
