import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchGetSelectors } from "../../store/slices/posts";
import { setPrevCap } from "../../store/slices/userAll";
import { ContainerGridCustom } from "../PostDetails/Styled";
import Form from "./Form/Form";
import PrevCap from "./PrevCap/PrevCap";

const OfferFormUpd = () => {
    const { id } = useParams();
    const datos = useSelector(store => store.posts.totalPosts);
    const userPais = useSelector(store => store.user.data.Pais);


  const dispatch = useDispatch();
  dispatch(setPrevCap(true))
  const prevCap = useSelector(store => store.userAll.prevCap);
  const dataFilter = datos.filter(fil => fil.data.id == id)
  const [newCapData, setNewCapData] = useState({
    idPost:id,
    cargo: dataFilter[0].data.cargo,
    gerencia: dataFilter[0].data.gerencia,
    estamento: dataFilter[0].data.estamento,
    jornada: dataFilter[0].data.jornada,
    ubicacion: dataFilter[0].data.ubicacion,
    fechaVencimiento: dataFilter[0].data.fecha_vencimiento.replace('T00:00:00',''),
    lider: dataFilter[0].data.fk_persona,
    file: undefined,
    file2: undefined,
    filePrev: dataFilter[0].data.archivo,
    filePrev2: dataFilter[0].data.archivo2,
    filePrev3: dataFilter[0].data.imgStory,
    mision: dataFilter[0].data.mision,
    descripcion: dataFilter[0].data.descripcion,
    funciones: dataFilter[0].data.funciones,
    skills: dataFilter[0].listadoHabilidades.map(s => ({
        title: s.nombre,
        level: s.nivel,
        id: s.idTag
      })),
    languages: dataFilter[0].listadoLenguajes.map(s => ({
        title: s.nombre,
        level: s.nivel,
        id: s.idTag
        })),
    interests: dataFilter[0].listadoIntereses.map(s => ({
        title: s.nombre,
        id: s.idTag
      }))
  });

  useEffect(() => {
   const dataFilter = datos.filter(fil => fil.data.id == id)
    dispatch(fetchGetSelectors(userPais));
  }, []);

  return (
    <ContainerGridCustom columns="540px 540px">
      <Form newCapData={newCapData} setNewCapData={setNewCapData} upd={true}>
        OfferForm
      </Form>
      <PrevCap newCapData={newCapData} setNewCapData={setNewCapData}/>
    </ContainerGridCustom>
  );
};

export default OfferFormUpd;