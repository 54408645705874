import styled from "styled-components";
import { colors } from "../../assets/variables";
import { ContainerGrid } from "../Home/Styled";
import { ContainerHalf } from "../VacanciesDetails/Style";

export const ContainerGridCustom = styled(ContainerGrid)`
  position: fixed;
  top: 0;
  margin: 0 auto;
  @media (max-width: 600px) {
    height: 100%;
    position: relative;
   }
  & > div {
    height: 100vh;
    @media (max-width: 600px) {
    height: 100%;
   }
  }
`;

export const ContainerHalfCustom = styled(ContainerHalf)`
  & > section {
    padding: 0 16px;

    & > div {
      border-bottom: 1px solid #e5e5e5;

      :last-child {
        border-bottom: none;
      }
    }
  }
`;
