import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerModal, Hr, SubTitle, Title } from "../../../Styled";

import PostItems from "./PostItems/PostItems";
import PostMain from "./PostMain/PostMain";
import PostModal from "./PostModal/PostModal";
import { ContainerGridCustom } from "./Styled";

const PostDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  return (
    <>
      {/* {showModal && (
        <ContainerModal
          data-modal="true"
          onClick={e =>
            e.target.dataset.modal === "true" && setShowModal(!showModal)
          }
        >
          <PostModal setShowModal={setShowModal} />
        </ContainerModal>
      )} */}
                <PostModal  />

      <ContainerGridCustom columns="540px 540px">
        <PostMain idPost={id} />
        <PostItems idPost={id} setShowModal={setShowModal} />
      </ContainerGridCustom>
    </>
  );
};

export default PostDetails;
