import { useEffect, useRef } from "react";
import { PATH } from "../../../../../../assets/variables";
import CapVideo from "../../../../../Common/CapVideo/CapVideo";
import { Spinner } from "../../../../../Common/Skeleton/Styled";
import { NavLink } from "react-router-dom";
import Story from "../Story/Story";
import {
  Close,
  HelperWrapper,
  MinStory,
  WrapperStory,
  WrapperText
} from "./Styled";

//Creamos un componente para mostrar cada historia en formato grande
const MainStory = ({ user, i, activeSlide, setShowModal, infoStories }) => {
  const [type] = user.imgStory.split(";#");

  const video = useRef(null);

  //cuando se monta el segundo carrusel validamos que la diapositiva sea la correcta para evitar que se reproduzcan todos los video a la vez.
  useEffect(() => {
    if(type?.startsWith("video")){
      if (infoStories.loading === "succeded") {
       
        if (activeSlide.next === i) {
          video.current.play();
      }
        else if (activeSlide.next !== i) {
          video.current.pause();
        }
      }
    }

  }, [activeSlide, i, infoStories.loading]);
useEffect(() => {

}, [])

  return (
    <WrapperStory>
      {infoStories.loading === "succeded" ? (
        <HelperWrapper>
          {type?.startsWith("video") ? (
            <video ref={video}>
            <source src={'https://colaboradordigital.blob.core.windows.net/ematch/archivos/'+user.imgStory.slice(0, user.imgStory.indexOf(';#'))} type="video/mp4" />
          </video> 
          ) : (
            <CapVideo post={true} i={i} activeSlide={activeSlide} infoStories={infoStories} custom={user.tipo} archivo={user.imgStory} tipo={user.tipo} />
          )}
           {/* <video loop ref={video}>
            <source src="./img/Lake.mp4" type="video/mp4" />
          </video> 
          <img src={user.archivo.slice(0, user.archivo.indexOf(';#'))} alt="story"  /> */}
          <WrapperText>
            <h3>{user.titulo}</h3>
            <p>
              {user.descripcion}
            </p>
           {!user.tipo ? (<NavLink to={`resumen/${user.fk_publicacion}`}>Ir a la publicación</NavLink>): user.url !== '' ? (<a
          href={user.url}
          target='_blank'
          rel="noopener"
          aria-label='Github'
        >Ver más
        </a>) : "" } 
          </WrapperText>
          <MinStory>
            <Story infoStories={infoStories} user={user} />
          </MinStory>
          <Close>
            Destacadas!
            <img
              onClick={() => setShowModal(x => !x)}
              src={PATH + "img/Cerrar-azul.svg"}
              alt="close"
            />
          </Close>
        </HelperWrapper>
      ) : (
        <Spinner />
      )}
    </WrapperStory>
  );
};

export default MainStory;
