import styled from "styled-components";
import { colors } from "../../../assets/variables";

export const WrapperLinkBtn = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  label {
    white-space: nowrap;
  }
`;

export const Legend = styled.legend`
  position: absolute;
  background-color: #fff;
  top: -13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0 8px;
  color: ${colors.mainBlue};
`;
