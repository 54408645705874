import { useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { createRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../../assets/variables";
import { fetchToken } from "../../../../customHooks/hooks";
import { fetchGetPosts } from "../../../../store/slices/posts";
import { clearUserMatch, setUserMatch } from "../../../../store/slices/userAll";
import { calculator } from "../../../../utils/calculator";
import { SkeletonLoader, TextSkeleton } from "../../../Common/Skeleton/Styled";
import Cap from "./Cap/Cap";
import { ContainerCaps } from "./Cap/Styled";
import {
  CapsContainer,
  ContainerSkills,
  ContainerText,
  ImgEmpty,
  VideoSkeleton
} from "./Styled";

export const SkeletonCap = () => {
  return (
    <ContainerCaps>
      <VideoSkeleton>
        <SkeletonLoader />
      </VideoSkeleton>
      <ContainerText>
        <TextSkeleton width="100%" />
        <TextSkeleton width="100%" />
        <TextSkeleton width="100%" />
      </ContainerText>
      <ContainerSkills>
        <div>
          <TextSkeleton width="80%" />
          <TextSkeleton width="80%" />
          <TextSkeleton width="80%" />
        </div>
        <div>
          <TextSkeleton width="80%" />
          <TextSkeleton width="80%" />
          <TextSkeleton width="80%" />
        </div>
      </ContainerSkills>
    </ContainerCaps>
  );
};

const Caps = () => {
  const dispatch = useDispatch();
  const infoCaps = useSelector(store => store.posts.totalPosts);
  const userId = useSelector(store => store.user.data);
  const infoMatch = useSelector(store => store.userAll.match);
  const [matches, setMatches] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [clase, setClase] = useState();
  const elemetRef = useRef(null);

  const match = useSelector(store => store.userAll.match)


  useEffect (() => {

     


    // Loop through children
    // childrens.forEach((children, idx) => {
    //   // If children's current index match 2 or 4
    //     // Add class to that children
    //     children.classList.add('new-class');
    //   })

  }, []);

  // useEffect(() => {
   
  //   (async () => {

  //     infoCaps.forEach(async (element, i) => {
  //       const results = await fetchToken({
  //         endpoint: `Publicacion/GetMatchTags?id=${element.data.id}`,
  //         alt: { dataSkills: [], dataLenguajes: [] }
  //       });
  //       let listadoHabilidades = element.listadoHabilidades
  //       let listadoLenguajes = element.listadoLenguajes
  //       let listadoIntereses = element.listadoIntereses


  //       const calc = calculator({
  //         listadoHabilidades,
  //         listadoLenguajes,
  //         listadoIntereses,
  //         results
  //       });
  //       setMatches(calc);
  //       console.log(match)
  //     if(match.length === 0){
  //       dispatch(setUserMatch(calc))
  //     }
  //       if((infoCaps.length - 1) == i ){
          
  //         setCargando(true)
  //       }
  //     });

  //   })();
  // }, [infoCaps])
  

  //   verificamos si la informacion cargo con exito.
  // if (!cargando) {
  //   return <SkeletonCap />;
  // }

    return (
      <CapsContainer>
        {/* {match.filter(fil => fil.average >= 50 && fil.fk_persona == userId.fk_colaborador && fil.finalizado !== true).length > 0 
        ? ( 
          infoCaps.map(cap => {
          return  <Cap key={cap.data.id} info={cap} />
          
  
          })
        ) : (
          <ImgEmpty>
          <img src={PATH + "sin_posts.svg"} />
        </ImgEmpty>)
        } */}


        {infoCaps.length > 0 ? (
         
          infoCaps.map(cap => 
           
              <Cap key={cap.data.id} info={cap} />
            
  
             )
        ) : (
          <ImgEmpty>
            <img src={PATH + "img/sin_posts.svg"} />
          </ImgEmpty>
        )}
      
  
      </CapsContainer>
    );
  

};

export default Caps;
