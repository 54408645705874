import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { PATH } from "../../../assets/variables";
import { BtnAdmin, ContainerMenu, Divider } from "./Styled";
import home from '../../../assets/img/home.svg'
import adminstrador from '../../../assets/img/admin.svg'
import adminActivo from '../../../assets/img/admin-activo.svg'

import homeActivo from '../../../assets/img/home-activo.svg'
import buscadorActivo from '../../../assets/img/buscador-activo.svg'
import misPublicacionesActivo from '../../../assets/img/mis-publicaciones-activo.svg'
import perfilActivo from '../../../assets/img/perfil-activo.svg'

import buscador from '../../../assets/img/buscador.svg'
import misPublicaciones from '../../../assets/img/mis-publicaciones.svg'
import perfil from '../../../assets/img/perfil.svg'

const Menu = ({ setAdmin, admin }) => {
  const loading = useSelector(store => store.user.loading);

  const userData = useSelector(store => store.user.admin);
  const userDatalider = useSelector(store => store.user.lider);
console.log(userDatalider)
  return (
    <ContainerMenu>
      <NavLink to="/inicio">
        <img className="icono-menu" src={home} alt="home" />
        <div className="menu-activo">
        <img src={homeActivo} alt="home" />
          Inicio 
        </div>
      </NavLink>
      { userDatalider && (
        <>
      <NavLink to="/buscador">
            <img className="icono-menu" src={buscador} alt="search" />
            <div className="menu-activo">
            <img src={buscadorActivo} alt="buscador" />
              Buscador
            </div>
      </NavLink>  
      <NavLink to="/mis-publicaciones">
        <img className="icono-menu" src={misPublicaciones} alt="request" />
        <div className="menu-activo">
            <img src={misPublicacionesActivo} alt="mis publicaciones" />
              Mis publicaciones
            </div>
      </NavLink>
      </>
      )}

      <NavLink to="/perfil">
        <img className="icono-menu" src={perfil} alt="profile" />
        <div className="menu-activo">
            <img src={perfilActivo} alt="perfil" />
              Mi perfil 
            </div>
      </NavLink>
      {userData && (
        <>
          <BtnAdmin className={admin ? 'active' : ''} admin={admin} onClick={() => setAdmin(x => !x)}>
            <img className="icono-menu" src={adminstrador} alt="admin" />
            <div className="menu-activo">
            <img src={adminActivo} alt="perfil" />
              Administrador
            </div>
          </BtnAdmin>
        </>
      )}
    </ContainerMenu>
  );
};

export default Menu;
