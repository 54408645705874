import  styled   from "styled-components";
import { colors, scrollCustom } from "../../../assets/variables";

export const ModalAlert = styled.div`
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #10069F;
    border-radius: 8px;
    width:  ${({ width }) => (width ? "600px" : "508px")};
    min-height: ${({ width }) => (width ? "393px" : "270px")};;
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => (justify === "flex-start" ? "flex-start" : "center")};
    flex-direction: column;
    padding:  ${({ padding }) => (padding === "40px 50px" ? "40px 50px" : "20px 50px")};
    height:  ${({ height }) => (height ? "550px" : "auto")};
    overflow: auto;
    ${scrollCustom}

    h3{
        margin: 6px 0 16px 0;
        font-size: 22px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-direction: column;
    }
    p{
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 24px;
        span{
            display: block;
            font-weight: 600;
        }
    }
    textarea{
        padding: 20px;
        resize: none;
        margin-bottom: 20px;
    }
    h6{
        text-align: center;
        font-weight:400;
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 8px;
    }
    h5{
        color: ${colors.mainBlue};
        font-size: 32px;
        font-weight: 400;
        margin: 20px 0;
    }
    @media (max-width: 600px) {
        width: 100%;
   }
`
export const ContainerButton = styled.div`
    display: flex;
    gap: 43px;
    justify-content: center;
`

export const InputStyled = styled.div`
    position:relative;
    margin-bottom: 40px;
    input{
        min-width: 100%;
        font-size: 16px;
        outline: none;
        border: 1px solid #999;
        min-height: 56px;
        border-radius: 8px;
        padding: 0px 16px;
        color: #3e3c3d;
        font-weight: 400;
        transition: .1s ease-out;
        position: relative;

    }
    input:focus{
            border: 2px solid ${colors.mainBlue}
        }
    input[type="text"]:focus + label, input[type="number"]:focus + label {
        color: $azul;
        top: 0;
        transform: translateY(-50%);
        font-weight: 600;
        font-size: 14px;
      }
    input[type="text"]:not(:placeholder-shown) + label, input[type="number"]:not(:placeholder-shown) + label {
        top: 0;
        font-size: 14px;
        font-weight: 600;
      }
    label{
        position: absolute;
        font-size: 16px;
        left: 10px;
        top: 47%;
        transform: translateY(-50%);
        background-color: #fff;
        color: #636467;
        padding: 0 8px;
        margin: 0 0.5rem;
        transition: .2s ease-out;
        transform-origin: left top;
        pointer-events: none;
    }
`