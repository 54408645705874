import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

import { Provider } from "react-redux";
import store from "../store";
import App from "../App";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


const pca = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient()
export const MsalProviderComponent = () => {
  
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>

        <MsalProvider instance={pca}>
       <App/>
        </MsalProvider>
      </Provider>
      </QueryClientProvider>
  );
};
