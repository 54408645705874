import React from "react";
import { Hr } from "../../../../../Styled";

import TextArea from "./TextArea/TextArea";

const TextAreas = ({ newCapData, setNewCapData }) => {
  return (
    <>
      <TextArea
        title="Misión"
        newCapData={newCapData}
        name="mision"
        maxCharacters={900}
        setNewCapData={setNewCapData}
      />
      <TextArea
        title="Descripcion"
        newCapData={newCapData}
        name="descripcion"
        maxCharacters={900}
        setNewCapData={setNewCapData}
      />
      <TextArea
        title="Requisitos"
        newCapData={newCapData}
        name="funciones"
        maxCharacters={900}
        setNewCapData={setNewCapData}
      />
      <Hr $margin="24px 0" />
    </>
  );
};

export default TextAreas;
