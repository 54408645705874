import React from 'react'
import { ContainerGrid } from '../Home/Styled'
import Favs from './Favs/Favs'
import Filters from './Filters/Filters'
import Results from './Results/Results'
import { ContainerGridSearch } from './Results/Styled'

const Search = () => {
  return (
    <>
        <ContainerGridSearch columns="255px 540px 255px">
            <Filters/>
            <Results/>
            <Favs/>
        </ContainerGridSearch>
    </>
  )
}

export default Search