import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../assets/variables';
import { Title } from '../../../../Styled'
import { ContainerPostsList } from './Styled'
import { fetchToken, useFetchPublic } from '../../../../customHooks/hooks';
import { OffSet } from '../../../VacanciesDetails/Style';
import { ContainerStoryPrev, WrapperStoryCustom } from '../../../StoryForm/StoryPrev/Styled';
import { Close, MinStory } from '../../../Home/Feed/SectionStories/Stories/MainStory/Styled';
import { StoryStyle } from '../../../Home/Feed/SectionStories/Stories/Story/Styled';
import { WrapperText } from '../../../OfferForm/PrevCap/Styled';
import { ContainerHalfCustom } from '../../../OfferForm/Styled';
import CapVideo from '../../../Common/CapVideo/CapVideo';
import Alert from '../../../Common/Alert/Alert';
import { ContainerButton } from '../../../Common/Alert/Styled';
import { BtnBlue, BtnCancelar } from '../../../VacanciesDetails/MainVacancy/styled';

const PostDestacadaList = () => {
    const [visible, setVisible] = useState(false)
    const [escuchar, setEscuchar] = useState(false)
    const [stories, setStories] = useState([])

    const getPost = async (idPost) =>{
      const userSelected = await fetchToken({
         endpoint: "Publicacion/GetDestacadas",
         alt: { error: true }
       });
       console.log(userSelected)
      setStories(userSelected)
     }

    // const infoStories = fetchToken({
    //     url: "https://api-selecciontalento.azurewebsites.net/api/Publicacion/GetDestacadas",
    //     options: {}
    //   });

    

    useEffect(() => {
      getPost()
    }, [])
    
    const EliminarPost = async (idPost) =>{
     const userSelected = await fetchToken({
        endpoint: "Publicacion/DeleteDestacadas",
        method: "POST",
        body: {id:idPost},
        alt: { error: true }
      });
      if(userSelected.FlgOk === 1){
        setVisible(false)
        getPost()
      }
    }
  return (
    <>
        <ContainerPostsList>
            <Title size="22px" mb="29px">
                Lista de publicaciones
            </Title>
            <table>
                <thead>
                    <tr>
                        <th><h3>Nombre Publicacíon</h3></th>
                        <th><h3>Eliminar</h3></th>
                    </tr>
                </thead>
                <tbody className='storyMantenedor'>
                    {stories.map(x =>( 
                        x.tipo ? (
                        <>
                            <tr key={x.id}>
                            {/* <td><img style={{
                                    width: "80px",
                                    height: "80px"
                                }} src={`https://colaboradordigital.blob.core.windows.net/ematch/archivos/`+x.imgStory.slice(0, x.imgStory.indexOf(';#'))} alt="logo persona"/></td>
                            <td> <NavLink to={`postsList/${x.id}`}><img src={PATH + "img/icono-lapiz.svg"} /></NavLink> </td> */}
                            <td> <CapVideo post={true} archivo={x.archivo}/></td>
                            <td>                              
                                <img
                                  alt="eliminar"
                                  width="24px"
                                  src={PATH + "img/delete.svg"}
                                  onClick={()=>setVisible(true)}
                                /></td>
                        </tr>
                        <Alert
      estado={visible}
      >
        <img src={PATH + "img/icono-info.svg"} alt="Play"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas
         <span>eliminar esta publicación destacada?</span>
        </p>
        <div>
        <ContainerButton>
        <BtnCancelar onClick={()=>setVisible(false)}>Cancelar</BtnCancelar>
        <BtnBlue onClick={()=> EliminarPost(x.id)}>Eliminar</BtnBlue>
        </ContainerButton>

        </div>
    </Alert>
                        </>
                        ) : ""
                    ))
                    }
                </tbody>
            </table>


        </ContainerPostsList>

    </>
  )
}

export default PostDestacadaList