import React from "react";
import { PATH } from "../../../../../assets/variables";
import { Hr, Title } from "../../../../../Styled";
import { Section } from "../../../UserRegister/FormUser/Styled";
import { FilesContainer, FilesContainerNew, InputFile, UploadInput, UploadLabel, UploadLabelNew } from "./Styled";

const FilesTwo = ({ newCapData, setNewCapData,setArchivos, archivos }) => {
  const handleChangeTwo = e => {
    if (e.target.files.length === 0) {
      return;
    }


      setNewCapData({ ...newCapData, file2: e.target.files[0] });
      const newFiles = [...archivos];
      const selectedFiles = Array.from(e.target.files);
      newFiles.push(...selectedFiles);
      setArchivos(newFiles);
      console.log(selectedFiles)
  };

  const handleDragOverTwo = e => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    if (e.target.name === "draggable") {
      console.log(e.target);
    }
  };

  const handleDropTwo = e => {
    e.stopPropagation();
    e.preventDefault();
    setNewCapData({ ...newCapData, file2: e.dataTransfer.files[0] });
  };

  return (
    <>
      {/* <FilesContainer
        name="draggable"
        onDragOver={handleDragOverTwo}
        onDrop={handleDropTwo}
      >
        <Section flex gap="16px" mb="25px">
          <UploadLabel htmlFor="upload2">
            <UploadImg src={PATH + "img/upload.svg"} />
          </UploadLabel>
          <div>
            <UploadLabel htmlFor="upload2">Subir imagen o video</UploadLabel>
            <UploadInput
              id="upload2"
              type="file"
              accept="image/*,video/*"
              onChange={handleChangeTwo}
              multiple
            />
            <Title size="18px" bold="400" mb="0">
              o sube tus archvios arrastrándolos a esta ventana
            </Title>
            <small>*Tamaño recomendado (ancho 50px - alto 50px)</small><br></br>
            <small>*Formato png - jpg</small>

          </div>
        </Section>
        <Hr $margin="0 0 16px" />
        <Title size="20px">Archivos cargados</Title>
        {newCapData.file2 && <p>{newCapData.file2.name}</p>}
      </FilesContainer>
      <Hr $margin="24px 0" /> */}
                <FilesContainerNew>
        <InputFile>
        <h5>{newCapData.file2 && newCapData.file2.name}</h5>
        <UploadInput
              id="upload2"
              type="file"
              accept="image/*,video/*"
              onChange={handleChangeTwo}
            />
        <UploadLabelNew htmlFor="upload2">Adjuntar</UploadLabelNew>
        </InputFile>
      </FilesContainerNew>
      <Hr $margin="0 0" />
    </>
  );
};

export default FilesTwo;
