import React from "react";
import { useSelector } from "react-redux";
import { Hr } from "../../../../Styled";
import CapVideo from "../../Common/CapVideo/CapVideo";
import Properties from "../../Home/Feed/Caps/Cap/CapProperties/Properties";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import {
  SectionStyled,
  WrapperContent
} from "../../VacanciesDetails/MainVacancy/styled";
import { ContainerHalf, OffSet } from "../../VacanciesDetails/Style";

const MatchesMain = ({ idPost }) => {
  //varios componentes vienen del componente principal "Cap" que esta en la pagina "inicio", estos componentes tambien estan en la pagina "resumen" en el componente "MainVacancy"

  const {
    data: {
      //id,
      cargo,
      gerencia,
      estamento,
      ubicacion,
      jornada,
      mision,
      funciones,
      descripcion,
      archivo
    },
    listadoHabilidades,
    listadoIntereses,
    listadoLenguajes
  } = useSelector(
    store =>
      store.posts.totalPosts.filter(post => post.data.id === Number(idPost))[0]
  );

  const capData = {
    //change for real data
    interests: listadoIntereses.map(i => ({
      ...i,
      title: i.nombre,
      id: i.idTag
    })),
    languages: listadoLenguajes.map(l => ({
      ...l,
      title: l.nombre,
      id: l.idTag
    })),
    skills: listadoHabilidades.map(s => ({
      ...s,
      title: s.nombre,
      id: s.idTag
    }))
  };

  return (
    <div>
      <OffSet />
      <ContainerHalf scroll>
        <CapVideo post={true} archivo={archivo} />
        <Properties
          cargo={cargo}
          gerencia={gerencia}
          estamento={estamento}
          ubicacion={ubicacion}
          jornada={jornada}
          margin="0"
        />
        <Hr $margin="24px 16px" />
        <CapSkills itemsData={capData} off/>
        <WrapperContent direction="column">
          <Hr />
          <SectionStyled>
            <h3>Mision:</h3>
            <p>{mision}</p>
          </SectionStyled>
          <SectionStyled>
            <h3>Descripcion:</h3>
            <p>{descripcion}</p>
          </SectionStyled>
          <SectionStyled>
            <h3>Requisitos:</h3>
            <p>{funciones}</p>
          </SectionStyled>
        </WrapperContent>
      </ContainerHalf>
    </div>
  );
};

export default MatchesMain;
