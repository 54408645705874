import styled from "styled-components";
import { PATH, colors, scrollCustom } from "../../../../assets/variables";

export const FormUserContainer = styled.div`
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0 0 4px #c2c2c2;
  padding: 16px 16px 0px 16px;
  @media (max-width: 600px) {
    order: 2;
    margin-top: 32px;
  }
`;

export const AlertaContainer = styled.div`
  background-color: #E5FFF9;
  border-bottom: 2px solid #00A364;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  color: #191919;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  gap: 8px;
`;


export const Section = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "block")};
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: ${({ jc }) => jc && jc};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  margin-bottom: ${({ mb }) => mb};
  gap: ${({ gap }) => gap};
  ${({ border }) => border && "border: 1px solid #666666; border-radius: 8px;"}
  padding: ${({ $padding }) => $padding};
  width: ${({ $width, name }) => (name !== "interests" ? $width : "90%")};
  height: ${({ $height }) => $height};
  flex-wrap: ${({ wrap }) => wrap};
  @media (max-width: 600px) {
   flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
   width: ${({ widthMobile }) => (widthMobile ? "100%" : "auto")};

  }
  & > textarea {
    width: 100%;
    resize: none;
    height: 120px;
    margin-bottom: 12px;
    border: 1px solid #666666;
    border-radius: 4px;
    padding: 11px 15px;
  }

  & textarea,
  input,
  select {
    outline: none;
    font-family: inherit;
    background-color: #fff;
    appearance: none;

    ::-webkit-calendar-picker-indicator {
      background: none;
    }
  }

  & input,
  select {
    border: none;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 40px;
    height: 35px;
    width: inherit;
  }

  select {
    padding: 5px 10px;
    cursor: pointer;
    background: url(${PATH}img/CheckDown.svg) 96% 50% no-repeat;
    background-repeat: no-repeat;
  }
  .instruccion{
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }
`;
export const SectionSearch = styled.div`
  position: absolute;
  display: none;
    bottom: -199px;
    width: 100%;
    height: 200px;
    overflow: auto;
    background: white;
    left: 0;
    padding: 20px 10px;
    z-index: 1;
    border-top-left-radius: 0;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    ${scrollCustom}
    &:has(h6){
      display: block;
    }
    h6{
      font-weight: 500;
    padding: 5px 0px;
    font-size: 12px;
    }
`;
export const ContainerBtnSave = styled.div`
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  padding: 24px 0 16px 0;
  border-top: 1px solid #e5e5e5;
`;
export const BtnSave = styled.div`
  width: min-content;
  background-color: ${colors.mainBlue};
  padding: 12px 16px;
  border-radius: 50px;
  color: #fff;
  margin-left: auto;
  /* opacity: ${({ modified }) => (modified ? "1" : "0.4")}; */
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  /* cursor: ${({ modified }) => (modified ? "pointer" : "not-allowed")}; */
  cursor: pointer;
`;

export const ImgEdit = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 10;
`;

export const ContainerImgProfile = styled.div`
  position: relative;
`;
