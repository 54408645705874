import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../../assets/variables";
import { Title } from "../../../../Styled";
import { BackBtn, ContainerHalf, OffSet } from "../Style";
import { CargosSection, HeaderSection } from "./Styled";
import Vacancy from "./Vacancy/Vacancy";

const Vacancies = () => {
  const dataId = useSelector(store => store.user.data)
  const idmatches = useSelector(store => store.userAll.match)
  const posts = useSelector(store => store.posts.totalPosts);
  return (
    <div>
      <OffSet />
      <CargosSection>
      <ContainerHalf $max section_scroll header_height="62">
        <HeaderSection>
          {/* <BackBtn onClick={() => navigate("/inicio/")}>
            <img src={PATH + "LeftArrowBlue.svg"} alt="Arrow" /> Volver
          </BackBtn> */}
          <img src={PATH + "img/ListIcon1.svg"} alt="List" />
          <Title size="24px">Cargos según tu perfil</Title>
        </HeaderSection>
        <section>
          {/* {posts.map(post => (
            <Vacancy key={post.data.id} post={post.data} />
          ))} */}
          {posts.map(post => {
            return idmatches.filter(x => x.idpost === post.data.id && x.fk_persona === dataId.fk_colaborador && x.average >= 50).length > 0 ?(
              <Vacancy key={post.data.id} post={post.data} />
            ):""
            })}
        </section>
      </ContainerHalf>
      </CargosSection>
    </div>
  );
};

export default Vacancies;
