import React from "react";
import { Hr, Title } from "../../../../Styled";
import Pagination from "../ItemsBoard/Pagination";
import {
  ContainerMaintainer,
  Fieldset,
  TableCustom,
  Td,
  Th,
  BtnAgg,
  ContainerTag,
  ContainView
} from "../Styled";
import { 
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../../assets/variables";
import Paginacion, {
  PreviousBoard,
  ViewBoard,
  NextBoard,
  NumberBoard
} from "./Paginacion";
import { ContImgDelete, ContPageBtn, WrapperBtns } from "./Styled";
import { useState } from "react";
import { addData, deleteData } from "../../../../store/slices/generalData";
import { ContainerTitle } from "../../Styled";
import Filter from "../LeaderBoard/Filter";

const useRows = name => {
  const data = useSelector(store => store.generalData[name]);

  return [data];
};

const ItemsBoard = ({ columns, title, legend, name, titleList, endpoint }) => {
  const [data] = useRows(name);
  const nameUser = useSelector(store => store.user.data.nombreCompleto);
  const dispatch = useDispatch();
  const [newItem, setNewItem] = useState("");
  const [newDesc, setNewDesc] = useState("");

  const [sending, setSending] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useGlobalFilter,
    usePagination
  );

  const handleChange = e => {
    setNewItem(e.target.value.replace("  ", " "));
  };
  const handleChangeDesc = e => {
    setNewDesc(e.target.value.replace("  ", " "));
  };

  const addItem = () => {
    if (!newItem) {
      return;
    }
    const date = new Date();
    const fullNewItem = {
      nombre: newItem,
      creador: nameUser,
      fecha_creacion: '2023-01-19',
      descripcion : newDesc
    };
    dispatch(addData(fullNewItem, endpoint));
    setNewItem("");
    setNewDesc("");

  };

  const handleDelete = id => {
    if (sending) {
      return;
    }
    setSending(true);
    dispatch(deleteData(id, () => setSending(false), endpoint));
  };

  return (
    <ContainerMaintainer>
      <Title size="20px" mb="32px">
        {title}
      </Title>
      <ContainerTag>
        <Fieldset width={legend === 'Habilidad' ? "200px" : "608px"}>
          <legend>{legend}</legend>
          <input type="text" value={newItem} onChange={handleChange} />
        </Fieldset>
        {legend === 'Habilidad' ? (
          <Fieldset width="385px">
            <legend>Descripción</legend>
            <input type="text" value={newDesc} onChange={handleChangeDesc} />
         </Fieldset>
        ) : false}
        <BtnAgg onClick={addItem}>
          Agregar <img alt="agregar" src={PATH + "img/Add.svg"} />{" "}
        </BtnAgg>
      </ContainerTag>
      <Hr $margin="24px 0" />
      <Title size="22px" mb="29px">
        {titleList}
      </Title>
      <ContainerTitle>
        <Paginacion pageSize={pageSize} setPageSize={setPageSize} />
        <Filter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          useAsyncDebounce={useAsyncDebounce}
          title={legend}
        />
      </ContainerTitle>


      <TableCustom {...getTableProps()}>
        <thead>
          {
            // Recorremos las columnas que previamente definimos
            headerGroups.map(headerGroup => (
              // Añadimos las propiedades al conjunto de columnas
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Recorremos cada columna del conjunto para acceder a su información
                  headerGroup.headers.map(column => (
                    // Añadimos las propiedades a cada celda de la cabecera
                    <Th id={column.id} {...column.getHeaderProps()}>
                      <Title size="16px" bold="500" lh="19px">
                        {
                          // Pintamos el título de nuestra columna (propiedad "Header")
                          column.render("Header")
                        }
                      </Title>
                    </Th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Recorremos las filas por pagina
            page.map(row => {
              // Llamamos a la función que prepara la fila previo renderizado
              prepareRow(row);
              return (
                // Añadimos las propiedades a la fila
                <tr {...row.getRowProps()}>
                  {
                    // Recorremos cada celda de la fila
                    row.cells.map(cell => {
                      // Añadimos las propiedades a cada celda de la fila
                      return (
                        <Td {...cell.getCellProps()}>
                          {
                            // Pintamos el contenido de la celda
                            cell.column.id === "btnDelete" ? (
                              <ContImgDelete
                                onClick={() =>
                                  handleDelete(cell.row.original.id)
                                }
                              >
                                <img
                                  alt="eliminar"
                                  width="24px"
                                  src={PATH + "img/delete.svg"}
                                />
                              </ContImgDelete>
                            ) : (
                              cell.render("Cell")
                            )
                          }
                        </Td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </TableCustom>
      <ContPageBtn>
        <ViewBoard data={data} pageIndex={pageIndex} pageSize={pageSize} />
        <WrapperBtns>
          <PreviousBoard
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
          />
          {/* <NumberBoard
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
          /> */}
            {pageIndex + 1 } de {pageOptions.length}
          <NextBoard canNextPage={canNextPage} nextPage={nextPage} />
        </WrapperBtns>
      </ContPageBtn>
    </ContainerMaintainer>
  );
};

export default ItemsBoard;
