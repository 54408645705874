import React from "react";
import { useDispatch } from "react-redux";
import { PATH } from "../../../../assets/variables";
import { setInputFile, setPrevCap } from "../../../../store/slices/userAll";
import { Hr, Title } from "../../../../Styled";
import { Section } from "../../../UserRegister/FormUser/Styled";
import { FilesContainer, FilesContainerNew, InputFile, UploadImg, UploadInput, UploadLabel, UploadLabelNew, inputFile } from "./Styled";

const Files = ({ newCapData, setNewCapData ,setArchivos, archivos }) => {
  const dispatch = useDispatch();
  const handleChange = e => {
    if (e.target.files.length === 0) {
      return;
    }
    if (
      e.target.files[0].type.startsWith("image/") ||
      e.target.files[0].type.startsWith("video/")
    ) {
      setNewCapData({ ...newCapData, file: e.target.files[0] });
      const newFiles = [...archivos];
      const selectedFiles = Array.from(e.target.files);
      newFiles.push(...selectedFiles);
      setArchivos(newFiles);
    }
  };

  const handleDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    if (e.target.name === "draggable") {
    }
  };

  const handleDrop = e => {
    e.stopPropagation();
    e.preventDefault();
    setNewCapData({ ...newCapData, file: e.dataTransfer.files[0] });

  };

  return (
    <>

      {/* <FilesContainer
        name="draggable"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Section flex gap="16px" mb="25px">
          <UploadLabel htmlFor="upload">
            <UploadImg src={PATH + "img/upload.svg"} />
          </UploadLabel>
          <div>
            <UploadLabel htmlFor="upload">Subir imagen o video</UploadLabel>
            <UploadInput
              id="upload"
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
            />
            <Title size="18px" bold="400" mb="0">
              o sube tus archvios arrastrándolos a esta ventana
            </Title>
            <small>*Tamaño recomendado (ancho 540px - alto 340px)</small><br></br>
            <small>*Formato png - jpg - mp4 </small>

          </div>
        </Section>
        <Hr $margin="0 0 16px" />
        <Title size="20px">Archivos cargados</Title>
        {newCapData.file && <p>{newCapData.file.name}</p>}
      </FilesContainer> */}
      <FilesContainerNew>
        <InputFile>
        <h5>{newCapData.file && newCapData.file.name}</h5>
        <UploadInput
              id="upload"
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
            />
        <UploadLabelNew htmlFor="upload">Adjuntar</UploadLabelNew>
        </InputFile>
      </FilesContainerNew>
      <Hr $margin="24px 0" />
    </>
  );
};

export default Files;
