import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchToken, useFetchToken } from "../../customHooks/hooks";
import { useSelector } from "react-redux";

export const fetchInfoData = createAsyncThunk(
  "userAll/fetchInfoData",
  async (token) => {
    const data  = await helperMap(fetchToken,token);   
    return data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    skills: [],
    languages: [],
    interests: [],
    admin: false,
    lider: false
    },
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload.data;
      state.lider = action.payload.data.activo;
      state.admin = action.payload.data.administrador;
      state.skills = action.payload.skills;
      state.languages = action.payload.languages;
      state.interests = action.payload.interests;
    }
  },
  extraReducers: {
    [fetchInfoData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInfoData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.lider = action.payload.data.activo;
      state.admin = action.payload.data.administrador;
      state.skills = action.payload.skills;
      state.languages = action.payload.languages;
      state.interests = action.payload.interests;
    },
    [fetchInfoData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const { setUserData } = userSlice.actions;

const helperMap = async (fetchCustom,token) => {

  const data = await fetchCustom({ endpoint: "Colaborador/GetToken", alt: {},token:token });
  const skillsServ = await fetchCustom({ endpoint: "Habilidad/GetToken", alt: [],token:token  });
  const skills = skillsServ.map(x => ({
    id: x.idTag,
    title: x.nombre,
    level: x.nivel
  }));
  const languagesServ = await fetchCustom({
    endpoint: "Lenguaje/GetToken",
    alt: [],
    token:token 
  });
  const languages = languagesServ.map(x => ({
    id: x.idTag,
    title: x.nombre,
    level: x.nivel
  }));
  const interestsServ = await fetchCustom({ endpoint: "Interes/GetToken", alt: [],token:token  });
  const interests = interestsServ.map(x => ({
    id: x.idTag,
    title: x.nombre
  }));

  return { data, skills, interests, languages};

};

export const fetchInfoUser = (token) => async dispatch => {
  // const userToken = useSelector(store => store.usuario.token);
  console.log("usuario")
  const data  = await helperMap(fetchToken,token);
  dispatch(setUserData(data));
};

export const updateInfoUser = (body, dataPrev, callback, token) => async dispatch => {
  if (dataPrev.data.img) {
    const update = await fetchToken({
      endpoint: "Persona/Upd",
      method: "POST",
      body,
      alt: {},
      token: token
    });
    if(update.FlgOk = 1){
      callback()
    }
  } else {
    const add = await fetchToken({
      endpoint: "Persona/Add",
      method: "POST",
      body,
      alt: {},
      token: token
    });
    if(add.FlgOk = 1){
      callback()
    }
  }
  const { data, skills, languages, interests } = await helperMap(fetchToken, token);

  // callback();
  dispatch(setUserData({ data, skills, languages, interests }));
};

export default userSlice.reducer;
