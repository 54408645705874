import styled from "styled-components";

export const SectionItems = styled.div`
  padding: 0 24px;
  width: 160px;
  ${({ last }) => !last && "border-right: 1px solid #e5e5e5;"}
  @media (max-width: 600px) {
    width: 100%;
    padding: 0px;
    /* div{
      display: flex;
      flex-wrap: wrap;
      gap: 0 4px;
    } */
  }
  h3 {
    font-weight: 600;
    font-size: 16px!important;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
  }
`;
export const SectionItemsTag = styled.div`
   @media (max-width: 600px) {
    display: flex;
      flex-wrap: wrap;
      gap: 0 4px;
   }
`
export const SectionsContainer = styled.div`
  display: flex;
  padding: 0 30px;
  @media (max-width: 600px) {
   flex-direction: column;
  }
`;

export const Item = styled.div`
  font-weight: ${({ check }) => (check ? "600" : "400")};
  font-size: 14px;
  line-height: 20px;
  color: #2e1fc8;
  background: ${({ check }) => (check ? "#a4f2d9" : "#DEE4FB")};
  border-radius: 20px;
  padding: 2px 15px;
  max-width: 116px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: min-content;
  white-space: nowrap;
`;
