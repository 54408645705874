import React, { useEffect } from "react";
import DescriptionProfile from "./DescriptionProfile/DescriptionProfile";
import MyInterests from "./MyInterests/MyInterests";
import { ContainerProfile } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoUser } from "../../../store/slices/user";
import Alert from "../../Common/Alert/Alert";
import { ContainerModal } from "../../../Styled";
import { PATH } from "../../../assets/variables";
import { ContainerButton } from "../../Common/Alert/Styled";
import { Navigate, NavLink, useNavigate, redirect  } from "react-router-dom";
import { BtnSave } from "../../UserRegister/FormUser/Styled";
import { BtnBlue } from "../../VacanciesDetails/MainVacancy/styled";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchToken } from "../../../customHooks/hooks";
/* An object with three arrays. */

const Profile = () => {
  const userData = useSelector(store => store.user);
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    setVisible(userData.data.fk_colaborador != "" ? true : false)

  }, [])
  
  const routeChange = () =>{ 
    setVisible(false)
    setTimeout(() => {
      setVisible(false)
      navigate("/perfil");

    }, 1000);
  }
    return (

      <>
      <ContainerProfile>
          <DescriptionProfile userData={userData.data} />
          <MyInterests title="Tus intereses" items={userData.interests} />
          <MyInterests title="Tus habilidades" items={userData.skills} />
          <MyInterests last title="Tus idiomas" items={userData.languages} />
        </ContainerProfile>

      
            </>
      
        );

};
export default Profile;
