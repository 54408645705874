import  styled   from "styled-components";
import { colors, scrollCustom } from "../../../assets/variables";


export const ContainerFilter = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 11px 16px 22px 16px;
    gap: 32px;
    height: max-content;
    @media (max-width: 600px) {
        margin-bottom: auto;
        margin-top: 70px;
   }
`
export const TitleSection = styled.h2`
    font-size: 18px;
    font-weight: 600;
`
export const ContainerFilterResult = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: 600px) {
        max-height: 160px;
        ${scrollCustom}
        overflow: auto;
        button{
            padding: 8px 0;
        }
   }
`
export const ContainerFilterBox = styled.div`
    height: 127px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${scrollCustom}
    @media (max-width: 600px) {
        height: 95px;
   }
`
export const FilterResult = styled.div`
    width: 100%;
    height: 24px;
    font-weight: 600;
    background: #DEE4FB;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 12px;
    @media (max-width: 600px) {
        padding: 8px 8px 8px 12px;
   }
    h3{
        color: ${colors.mainBlue};
        font-size: 14px;
        font-weight: 400;
    }
    button{
        background: transparent;
        outline: none;
        border: 0;
    }
`

export const ContainerCheckbox = styled.label`
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
    color: #191919;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        /* :checked  ~ span::after{
            display: block;
        }
        :checked  ~ span {
            background-color:blue;
            border: 0px;
            border-radius: 4px;
        } */

    }
    span {
        position: absolute;
        top: 1px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #fff;
        border: 2px solid #999999;
        border-radius: 4px;
        ::after{
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: 2px solid #fff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
      }
`
export const CheckboxCheck = styled.div`
    position: absolute;
    top: 1px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color:blue;
    border: 0px;
    border-radius: 4px;
    ::after{
        content: "";
        position: absolute;
        display: block;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: 2px solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`
export const InputSearchFilter = styled.div`
    position: relative;
    input{
    width: 100%;
    height: 24px;
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 0 26px 0 12px;
    }
    img{
        position: absolute;
        right: 8px;
        top: 6px;
    }
`