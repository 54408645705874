const levelUser = {
  basico: 1,
  intermedio: 1.5,
  avanzado: 2
};

const requestedLevel = {
  deseable: 1,
  necesario: 1.5,
  excluyente: 2.5
};

const calculatePostAverage = ({
  listadoLenguajes,
  listadoHabilidades
}) => {
  let total = 0;

  for (let i = 0; i < listadoHabilidades.length; i++) {
    const item = listadoHabilidades[i];
    const multiplier = levelUser.basico + requestedLevel[item.nivel];
    // const multiplier = levelUser[item.peso] + requestedLevel[item.nivel];

    total += 14 * multiplier;
  }

  for (let i = 0; i < listadoLenguajes.length; i++) {
    const item = listadoLenguajes[i];

    const multiplier = levelUser.basico + requestedLevel[item.nivel];
    // const multiplier = levelUser[item.peso] + requestedLevel[item.nivel];

    total += 5 * multiplier;
  }

  return total;
};

export const calculator = ({
  results: { dataSkills, dataLenguajes },
  listadoLenguajes,
  listadoHabilidades
}) => {
  const postAverage = calculatePostAverage({
    listadoHabilidades,
    listadoLenguajes
  });

  const objUsersWithRate = {};

  for (let i = 0; i < dataSkills.length; i++) {
    const item = dataSkills[i];

    const requestedSkillLevelPost = listadoHabilidades.filter(
      s => s.idTag === item.fk_habilidad
    )[0];

    const multiplier =
      levelUser[item.nivel] + requestedLevel[requestedSkillLevelPost.nivel];
      
    if (objUsersWithRate[item.fk_persona]) {
      objUsersWithRate[item.fk_persona].average =
        objUsersWithRate[item.fk_persona].average + 14 * multiplier;
    } else {
      objUsersWithRate[item.fk_persona] = { average: 14 * multiplier, ...item };
    }
  }

  for (let i = 0; i < dataLenguajes.length; i++) {
    const item = dataLenguajes[i];

    const requestedLanguagesLevelPost = listadoLenguajes.filter(
      s => s.idTag === item.fk_lenguaje
    )[0];

    const multiplier =
      levelUser[item.nivel] + requestedLevel[requestedLanguagesLevelPost.nivel];

    if (objUsersWithRate[item.fk_persona]) {
      objUsersWithRate[item.fk_persona].average =
        objUsersWithRate[item.fk_persona].average + 5 * multiplier;
    } else {
      objUsersWithRate[item.fk_persona] = { average: 5 * multiplier, ...item };
    }
  }

  // console.log(objUsersWithRate);
  const objToArray = Object.entries(objUsersWithRate);
  const newArray = [];

  for (let i = 0; i < objToArray.length; i++) {
    const [_, obj] = objToArray[i];

    obj.average = Math.round((100 * obj.average) / postAverage);
    newArray.push(obj);
  }

  return newArray.sort((a, b) => b.average - a.average);
};

/*resumen de calculo
el calculo debe ser la suma del numero de hablilidades * 14 


y multipicado por el requerido 
excluyente: 2.5
necesario: 1.5
deseable: 1


multiplicado por el nivel de habilidad
avansado: 2
intermedio: 1.5
basico: 1

postAverage = al valor de la publicacion 

objUsersWithRate[item.fk_persona].average = valor de rated

const calcularPorsentaje = () => {
   return (100 * objUsersWithRate[item.fk_persona].average) / postAverage;
  
}
*/
