import React from "react";
import { CollapseImg, ContainerSection } from "./Styled";
import { PATH } from "../../../../../../../assets/variables";
import { Hr } from "../../../../../../../Styled";

const Relation = ({ isOpen, setIsOpen, noMatch }) => {
  return (
    <ContainerSection noMatch={noMatch}>
      <CollapseImg
        initial={{ rotate: 90, translateX: "-50%", translateY: "-50%" }}
        animate={{
          rotate: isOpen ? 270 : 90,
          translateX: "-50%",
          translateY: "-50%"
        }}
        onClick={() => setIsOpen(!isOpen)}
        src={PATH + "img/CheckRight.svg"}
        alt="Check Down"
      />
      <Hr />
      {
        noMatch != true ? (
          <section>
          <div>
            <img src={PATH + "img/LogoRelation.svg"} alt="RelationLogo" />
            Tu perfil se ajusta a este empleo<br></br>
            <span>Recuerda revisar los requisitos para postular</span>
          </div>
        </section>
        )
         : (
          <section>
          <div>
            <img src={PATH + "img/logo-no-ajusta.svg"} alt="RelationLogo" />
            Tu perfil no se ajusta a este empleo
          </div>
        </section>
         )
      }

    </ContainerSection>
  );
};

export default Relation;
