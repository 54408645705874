import React, { useState } from "react";
import { PATH } from "../../../assets/variables";
import { WhiteButton } from "../Results/Styled";

import { useDispatch, useSelector } from "react-redux";
import {setModalMobile, setUserAllData} from "../../../store/slices/userAll";
import {v4} from "uuid"
import {
  ContainerFilter,
  ContainerFilterResult,
  FilterResult,
  TitleSection,
  ContainerCheckbox,
  CheckboxCheck,
  InputSearchFilter,
  ContainerFilterBox
} from "./Styled";
import { BtnCloseMobile, ContainerHalfModal, ContainerHalfModalSearch } from "../../VacanciesDetails/Style";
import { useEffect } from "react";



const Filters = () => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userAll);
  const modalMobile = useSelector(store => store.userAll.modalMobile)
  const [showFilter, setshowFilter] = useState(userData.filteredUsers);

  const userSkills = useSelector(store => store.generalData.skills);
 const skills = userSkills.map(x =>({
    id:v4(),
    name:x.nombre,
    tipo: "habilidad"
  }))
  const userInterests = useSelector(store => store.generalData.interests);
 const interests = userInterests.map(x =>({
    id:v4(),
    name:x.nombre,
    tipo: "interes"
  }))
  //  const [filteredHabilidad, setfilteredHabilidad] = useState([]);
  const allTags = skills.concat(interests)
  const [checkboxs, setcheckboxs] = useState(allTags);
  const filteredInteres = checkboxs.filter(a => a.tipo === "interes");
  const filteredHabilidad = checkboxs.filter(b => b.tipo === "habilidad")
  const [searchTerm, setSearchTerms] = useState([]);
  const [searchTermInteres, setSearchTermsInteres] = useState([]);
  const filteredChecked = checkboxs.filter(c => c.check === true);
  const handleChange = event => {
    setSearchTerms(event.target.value);
  };
  const handleChangeInteres = event => {
    setSearchTermsInteres(event.target.value);
  };
  function filtro(){
    const arraySkills = []
    checkboxs.forEach(item => {
      if(item.check){
      arraySkills.push(item.name)
      }
    })
    if(arraySkills.length == 0){
      dispatch(setUserAllData({post:userData.data,pais:'Chile'}))
    }
    else{
      const filterByTags = arraySkills
      
      const filterByTagSet = new Set(filterByTags);
      
      const result = userData.data.filter((o) => 
        o.habilidad.some((tag) => filterByTagSet.has(tag)) || o.intereses.some((tag) => filterByTagSet.has(tag))
      );
      dispatch(setUserAllData({post:result,pais:'Chile'}))

    }

  }

  const renderCheckboxs = () => {
     //// Aca hacer el filtrooooo
     if(searchTerm.length > 0){
      return filteredHabilidad.filter(obj => obj.name.toLowerCase().includes(searchTerm.toLowerCase())).map(item => (
      <ContainerCheckbox key={item.id}>
        <input type="checkbox" onClick={() => handleChangeCheckboxs(item.id)} />
        {item.check === true ? <CheckboxCheck /> : <span></span>}
        {item.name}
      </ContainerCheckbox>));
     }
     else{
      return filteredHabilidad.map(item => (
        <ContainerCheckbox key={item.id}>
          <input type="checkbox" onClick={() => handleChangeCheckboxs(item.id)} />
          {item.check === true ? <CheckboxCheck /> : <span></span>}
          {item.name}
        </ContainerCheckbox>
      ));
     }

  };
  const renderCheckboxsInteres = () => {
    if(searchTermInteres.length > 0){
      return filteredInteres.filter(obj => obj.name.toLowerCase().includes(searchTermInteres.toLowerCase())).map(item => (
        <ContainerCheckbox key={item.id}>
          <input type="checkbox" onClick={() => handleChangeCheckboxs(item.id)} />
          {item.check === true ? <CheckboxCheck /> : <span></span>}
          {item.name}
        </ContainerCheckbox>
        ));
    }
    else{
      return filteredInteres.map(item => (
        <ContainerCheckbox key={item.id}>
          <input type="checkbox" onClick={() => handleChangeCheckboxs(item.id)} />
          {item.check === true ? <CheckboxCheck /> : <span></span>}
          {item.name}
        </ContainerCheckbox>
    
    ));
    }
  };


  const handleChangeCheckboxs = id => {
    setcheckboxs(prev => {
      return prev.map(item => {
       
        if (item.id === id) {
          return { 
            ...item, check: !item.check 
           
          };
          
        } else {
          return { ...item };
        }
      });
    });
    
  };
  const handleUnCheckboxsAll = () => {
    dispatch(setUserAllData({post:userData.data,pais:'Chile'}))
    setcheckboxs(prev => {
      return prev.map(item => {
        return { ...item, check: false };
      });
    });
  };

  const renderFilters = () => {
    
    return checkboxs.map(item => {
      if (item.check) {
        return (
          <FilterResult key={item.id}>
            <h3>{item.name}</h3>
            <img
              src={PATH + "img/close-blue.svg"}
              alt="close"
              onClick={() => handleChangeCheckboxs(item.id)}
            />
          </FilterResult>
        );
      } else {
        return null;
      }
    });
  };
  useEffect(() => {
    dispatch(setModalMobile(true))
  }, [])
  
  return (
    <>
    {modalMobile && (
    <ContainerHalfModalSearch>
        <BtnCloseMobile >
          <img src={PATH + "img/Cerrar-azul.svg"} alt="Close"  onClick={()=> dispatch(setModalMobile(false))} />
        </BtnCloseMobile>
      <ContainerFilter>
        {filteredChecked.length > 0 ? (
          <>
            <ContainerFilterResult>
              <TitleSection>Tus filtros:</TitleSection>
              {renderFilters()}
              <WhiteButton
                onClick={() => handleUnCheckboxsAll()}
                width="100%"
                height="32px"
              >
                Eliminar filtros <img src={PATH + "img/delete.svg"} alt="" />
              </WhiteButton>
            </ContainerFilterResult>
          </>
        ): ""}
        <ContainerFilterResult>
          <TitleSection>Intereses:</TitleSection>
          <InputSearchFilter>
            <input type="text" placeholder="Buscar por interes" value={searchTermInteres} onChange={handleChangeInteres}/>
            <img src={PATH + "img/lupa-azul.svg"} alt="" />
          </InputSearchFilter>
          <ContainerFilterBox>
            {renderCheckboxsInteres()}
          </ContainerFilterBox>
        </ContainerFilterResult>
        <ContainerFilterResult>
          <TitleSection>Habilidades</TitleSection>
          <InputSearchFilter>
            <input type="text" placeholder="Buscar por habilidades" value={searchTerm} onChange={handleChange}  />
            <img src={PATH + "img/lupa-azul.svg"} alt="" />
          </InputSearchFilter>
          <ContainerFilterBox>
            {renderCheckboxs()}
          </ContainerFilterBox>
          <WhiteButton
                onClick={() => {filtro()}}
                width="100%"
                height="32px"
              >
                Aplicar
              </WhiteButton>
        </ContainerFilterResult>
      </ContainerFilter>
      </ContainerHalfModalSearch>)}

    </>
  );
};

export default Filters;
