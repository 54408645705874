import  styled   from "styled-components";
import { colors } from "../../../assets/variables";
import { ContainerGrid } from "../../Home/Styled";

export const ContainerResults = styled.div`
    background: #FFFFFF;
    padding: 10px 16px 27px 16px;
    gap: 32px;
    border-radius: 8px;
    overflow: auto;
`
export const GridResults = styled.div`
    margin-top: 14px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    @media (max-width: 600px) {
       grid-template-columns: repeat(2, 1fr);
   }
`
export const BoxResults = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 202px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 8px;
    h3{
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 5px;
        border-bottom: 1px solid #E5E5E5;
        text-align: center;
        margin-top: 4px;
        height: 41px;
        display: flex;
        align-items: center;
    }
`
export const ImgProfile = styled.img`
   width: 80px;
   height: 80px;
`

export const ImgStar= styled.img`
   position: absolute;
   top:8px;
   right: 8px;
   `
export const TagList = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 10px 0px 12px 0px;
   h4{
    font-size: 9px;
    font-weight: 500;
   }
`
export const WhiteButton = styled.button`
   width: ${props => props.width ? props.width : 'auto'};
   height:${props => props.height ? props.height : 'auto'};
   border: 1px solid ${colors.mainBlue};
   color: ${colors.mainBlue};
   font-size: 16px;
   font-weight: 600;
   background: #fff;
   border-radius: 50px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
   margin-top: auto;
`
export const ContainerGridSearch = styled(ContainerGrid)`
   height: 100vh;
`