import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PATH } from "../../../../assets/variables";
import { Title } from "../../../../Styled";
import { Section } from "../Styled";
import { BoxArrow, Items, FilterContainer } from "./Styled";

const InputWithSearch = ({
  title,
  data,
  setDataSets,
  name,
  setFormModified,
  optionsOffer
}) => {
  const [info, setInfo] = useState({
    title: "",
    level: optionsOffer ? "deseable" : "basico",
    search: false, //controlamos cuando se abre el select de opciones
    status: false,
    id: null
  });

  const generalData = useSelector(store =>
    store.generalData[name].filter(item =>
      item.nombre.toLowerCase().includes(info.title.toLowerCase())
    )
  );
  // document.addEventListener('keydown', keyDownHandler);

  // const keyDownHandler = event => {
  //   console.log('User pressed: ', event.key);

  //   if (event.key === 'Enter') {

  //     if (
  //       !info.title ||
  //       data.length === 5 ||
  //       data.filter(x => x.id === info.id).length > 0 ||
  //       !info.status
  //     ) {
  //       return;
  //     }

  //     let newData = [...data, info];
  //     setDataSets(prevState => ({
  //       ...prevState,
  //       [name]: newData
  //     }));
  //     setInfo({
  //       title: "",
  //       level: optionsOffer ? "deseable" : "basico",
  //       status: false,
  //       search: false,
  //       id: null
  //     });
  //     setFormModified && setFormModified(true);

  //   }
  // };

  const handleSearch = e => {
    if (e.target.value === " ") {
      return;
    }

    setInfo({
      ...info,
      // [e.target.name]: e.target.value.replace(" ", ""),
      [e.target.name]: e.target.value,
      search: e.target.name === "title",
      // status: false,
      id: e.target.name === "title" ? null : info.id
    });
  };

  const handleAddAction = () => {
    if (
      !info.title ||
      data.length === 5 ||
      data.filter(x => x.id === info.id).length > 0 ||
      !info.status
    ) {
      return;
    }

    let newData = [...data, info];
    setDataSets(prevState => ({
      ...prevState,
      [name]: newData
    }));
    setInfo({
      title: "",
      level: optionsOffer ? "deseable" : "basico",
      status: false,
      search: false,
      id: null
    });
    setFormModified && setFormModified(true);
  };

  const handleItemSelected = item => {
    setInfo({
      ...info,
      title: item.nombre,
      id: item.id,
      descripcion : item.descripcion,
      search: false,
      status: true
    });
  };

  const handleDeleteAction = id => {
    let newData = data.filter(data => data.title !== id);
    setDataSets(prevState => ({ ...prevState, [name]: newData }));
    setFormModified && setFormModified(true);
  };

  const handleBorder = e => {
    e.stopPropagation();
    if (e._reactName === "onFocus") {
      e.target.parentElement.classList.add("blue_border");
    } else {
      e.target.parentElement.classList.remove("blue_border");
    }
  };

  const handleShowResult = e => {
    if (info.id !== null) {
      return;
    }
    setInfo({
      ...info,
      search: e._reactName === "onMouseEnter"
    });
  };

  return (
    <div>
      <Title size="16px" bold="500">
        {title == "habilidades" ? "Habilidades profesionales" : title == "intereses" ? "Interes en área" : "Idiomas"} 
      </Title>
      <Section
        onMouseLeave={handleShowResult}
        onMouseEnter={handleShowResult}
        flex
        jc="space-between"
        mobile
      >
        <Section
          $height="56px"
          $width="280px"
          name={name}
          $padding="16px 12px 16px 16px"
          border
          flex
          gap="8px"
          widthMobile
        >
          <img src={PATH + "img/searchGray.svg"} alt="add" />
          <input
            onFocus={handleBorder}
            onBlurCapture={handleBorder}
            name="title"
            onChange={handleSearch}
            placeholder={`Agrega hasta 5 ${title}`}
            type="text"
            value={info.title}
          />
          {info.title !== "" && info.search && (
            <FilterContainer>
              {generalData.length > 0 ? (
                generalData.map(item => (
                  <p onClick={() => handleItemSelected(item)} key={item.id}>
                    {item.nombre}
                  </p>
                ))
              ) : (
                <p>Sin resultados.</p>
              )}
            </FilterContainer>
          )}
        </Section>
        {name !== "interests" && (
          <Section
            $height="56px"
            $width="164px"
            $padding="16px 12px 16px 16px"
            flex
            border
            widthMobile
          >
            <select
              name="level"
              value={info.level}
              onBlur={handleBorder}
              onFocus={handleBorder}
              onChange={handleSearch}
            >
              {optionsOffer ? (
                <>
                  <option value="deseable">Deseable</option>
                  <option value="necesario">Necesario</option>
                  <option value="excluyente">Excluyente</option>
                </>
              ) : (
                <>
                  <option value="basico">Básico</option>
                  <option value="intermedio">Intermedio</option>
                  <option value="avanzado">Avanzado</option>
                </>
              )}
            </select>
          </Section>
        )}
        <Section onClick={handleAddAction} $height="56px" flex gap="8px">
          <img src={PATH + "img/Add.svg"} alt="add" />
        </Section>
      </Section>
      <Section $padding="16px 0" wrap="wrap" flex gap="8px">
        {data.map((x, i) => (
          <Items key={x.title + i}>
            <h3 title={x.descripcion}>{x.title}</h3>
            <img
              onClick={() => handleDeleteAction(x.title)}
              src={PATH + "img/closeBold.svg"}
              alt="close"
            />
          </Items>
        ))}
      </Section>
    </div>
  );
};

export default InputWithSearch;
