import styled from "styled-components";

export const ContainerStories = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 7px 16px 15px;

  //solo para diferenciar, borrar luego
  box-shadow: 0 0 4px #c2c2c2;
  @media (max-width: 600px) {
    margin-top: 32px;
  }
`;
