import styled from "styled-components";
import { colors } from "../../../assets/variables";
export const HeaderSection = styled.div`
  margin: 13px 18px 0;
  border-bottom: 1px solid #cccccc;
`;
export const SectionAddPostulante = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  button{
    width: 188px;
    height: 32px;
    border-radius: 50px;
    color: #002EFF;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    text-decoration: none;
    background: #FFFFFF;
    border: 1px solid #002EFF;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
`
export const ContainerFinalizar = styled.div`
display: flex;
padding-top: 15px;
margin-right: 17px;
border-top: 1px solid #E5E5E5;
button{
  margin-left: auto;
}
`
export const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ $padding }) => $padding};
`;

export const Img = styled.img`
  width: auto;
  height: auto;
`;
export const ItemBox = styled.div`
  flex-basis: ${({ $width }) => $width};
  padding: ${({ p }) => p};
  display: flex;
  gap: ${({ gap }) => (gap ? gap : "8px")};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ jc }) => jc};
  flex-grow: ${({ grow }) => grow};
  button{
    margin: 0 auto;
    padding: 0;
    background: none;
    outline: none;
    border: none;
    height: 24px;
  }
  h6{
    color: #999999;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const ApplicantWrapper = styled.div`
  display: flex;
`;

export const Score = styled.div`
  width: 50px;
  background-color: ${({ back }) =>
    back > 70
      ? colors.greenStories
      : back > 50
      ? colors.blueAverage
      : colors.yellowAverage};
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  padding: 2px 0;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000072;
  }
`;

export const InputSearchFilter = styled.div`
  position: relative;
  width: ${({ $width }) => $width};
  height: 48px;
  background-color: #f2f5ff;
  border-radius: 100px;

  input {
    padding: 12px 18px;
    width: 100%;
    border: none;
    background-color: #f2f5ff;
    font-size: 16px;
    height: 100%;
    border-radius: 100px;
    outline: none;
  }
  img {
    display: flex;
    position: absolute;
    right: 13px;
    top: 11px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const SearchContainer = styled.div`
  padding-bottom: 18px;
  width: 100%;
  position: relative;
  h5{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;
export const ContainerPostulante = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  h4{
    display: flex;
    align-items: center;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    padding: 13px 10px 13px 18px;
    &:hover{
      background: ${colors.mainBlue};
      color: #fff;
    }
    button{
      margin-left: auto;
      width: 113px!important;
      height: 32px!important;
    }
  }
`;
