import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

const load = keyframes`
 0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;
const LoaderBackg = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background: rgba(2, 2, 71, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Loading = styled.div`
  border: 8px solid rgba(0, 0, 0, 0);
  width: 95px;
  height: 95px;
  border-radius: 50%;
  border-left-color: #ff3d00;
  animation: ${load} 1s linear infinite;
`;

const ImgE = styled.img`
  margin-top: -72px;
`;

const Text = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
`;

export const Loader = ({ text }) => {
  return (
    <LoaderBackg
      id="loader"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Loading />
      <ImgE
        src="https://entel.blob.core.windows.net/cdn/img/loading-entel.svg"
        alt="logo"
      />
      <Text>{text}</Text>
    </LoaderBackg>
  );
};
