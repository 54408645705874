import { PATH } from "../../../../assets/variables";
import { InputSearchFilter } from "../../../MatchesDetails/MatchesItems/Styled";
import { useState } from "react";

const Filter = ({ globalFilter, setGlobalFilter, useAsyncDebounce, title }) => {
    const [value, setValue] = useState(globalFilter);

    const onFilterChange = useAsyncDebounce(
        value => setGlobalFilter(value || undefined),
        200
    );

    const handleInputChange = e => {
        setValue(e.target.value);
        onFilterChange(e.target.value);
    };

    return (
        <InputSearchFilter $width="510px">
            <input
                size={50}
                value={value || ""}
                onChange={handleInputChange}
                placeholder={`Buscar ${title != undefined ? title : 'colaborador'}`}
            />
            <img src={PATH + "img/lupa-azul.svg"} alt="" />
        </InputSearchFilter>
    );
};

export default Filter;
