import styled from "styled-components";
import { colors } from "../../../../../../../assets/variables";

export const StoryStyle = styled.div`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  overflow: hidden;
  cursor: pointer;
  border: 4px solid ${props => props.destacada ? "#FD6C98" : colors.greenStories};
  position: relative;
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 80px;
    width: 80px;
  }
  span{
    bottom: 14px;
    position: fixed;
    font-size: 32px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    width: 40px;
    height: 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-align: center;
    justify-content: center;
  }
`;

export const StoryStyleCap = styled.div`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  overflow: hidden;
  cursor: pointer;
  border: 4px solid ${props => props.destacada ? "#FD6C98" : colors.greenStories};
  position: relative;
  background-color: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
    width: 60px;
  }
  span{
    bottom: 14px;
    position: fixed;
    font-size: 32px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    width: 40px;
    height: 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-align: center;
    justify-content: center;
  }
`;
