import React, { useState } from "react";
import { PATH } from "../../../../assets/variables";
import { Hr, Title } from "../../../../Styled";
import { Icon } from "../../../Common/IconProfile/Styled";
import { BtnSave, Section } from "../Styled";
import { ColorBox, ContainerUsrImgPicker } from "./Styled";

const dataColors = {
  colors: [
    "E5FFF9",
    "A4F2D9",
    "ECFAFE",
    "B1EAF8",
    "C9C6EA",
    "748EFE",
    "FEF9EB",
    "F8B106",
    "FFE2EB",
    "FCB6CC",
    "FEF4F2",
    "FF753A"
  ],
  avatars: [1, 2, 3, 10,11,12,13,14,9,4, 5, 6,7,8,15,16,17,18,19,20,21,22,23,24]
};

const UserImgPicker = ({
  img,
  backgroundPrev,
  setShowModal,
  setImgUser,
  setFormModified
}) => {
  const [modified, setModified] = useState(false);
  const [imgUser, setImageUser] = useState({
    background: backgroundPrev,
    avatar: img
  });

  const handleBackground = e => {
    setModified(true);
    setImageUser({
      ...imgUser,
      background: "#" + e.target.dataset.background
    });
  };

  const handleAvatar = e => {
    setModified(true);
    setImageUser({ ...imgUser, avatar: e.target.dataset.avatar });
  };

  const saveImg = () => {
    setFormModified(true);
    setImgUser(imgUser);
    setShowModal(false);
  };

  return (
    <ContainerUsrImgPicker>
      <Section flex jc="center" mb="24px">
        <Icon
          img={`${PATH}perfil/profile${imgUser.avatar}.svg`}
          size="140px"
          back={imgUser.background}
        />
      </Section>
      <Section className="seccionEditUser" flex vertical ai="start" mb="24px">
        <Title bold="400" size="20px" color="#000000" mb="8px">
          Color de fondo:
        </Title>
        <Section className="contenedorColores" $width="100%" flex jc="space-between">
          {dataColors.colors.map(color => (
            <ColorBox
              key={color}
              data-background={color}
              onClick={handleBackground}
              bg={"#" + color}
            />
          ))}
        </Section>
      </Section>
      <Hr $margin="0 0 24px" />
      <Section className="seccionEditUser" flex vertical ai="start" mb="24px">
      <Title className="" bold="400" size="20px" color="#000000" mb="24px">
        Selecciona un avatar:
      </Title>
        <Section className="contenedorColores" $width="100%" flex jc="space-between" gap="5px" style={{height:"95px",scrollSnapType:"x mandatory",overflowX:"scroll"}} >
          {dataColors.avatars.map(avatar => (
            <div data-avatar={avatar} onClick={handleAvatar} key={avatar}>
              <Icon
                border
                size="70px"
                img={`${PATH}perfil/profile${avatar}.svg`}
              />
            </div>
          ))}
        </Section>
      </Section>
      <Hr $margin="0 0 24px" />
      <BtnSave onClick={saveImg} modified={modified}>
        Guardar
        <img src={PATH + "img/IconSend.svg"} alt="save" />
      </BtnSave>
    </ContainerUsrImgPicker>
  );
};

export default UserImgPicker;
