import styled from "styled-components";

export const TypeManteinerStyle = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 4px #bebebe;
  margin-bottom: 24px;
  width: 255px;

  a {
    display: flex;
    gap: 16px;
    color: #000;
    text-decoration: none;
    padding: 16px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .active {
    background-color: #000072;
    color: #fff;
    text-decoration: none;

    svg {
      fill: #fff;
    }
  }
`;
