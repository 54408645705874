import styled from "styled-components";

export const VideoSkeleton = styled.div`
  width: 100%;
  height: 260px;
  background-color: #000;
`;
export const CapsContainer = styled.div`
&.publicacion ~ .noPost{
  display: none!important;
  &:first-child{
      display: none!important;
    }
  }
  &.noPost ~ .publicacion .noPost{
      display: none!important;
    
  }

  .noPost{
    display: none;
    &.post{
      display: none!important;
    }
    &:first-child{
      display: block;
    }
  }
  .no{
  visibility: hidden;
  display: none;
  }

  .no:first-child{
    visibility: visible;
    display: block;
  
  }

  &:has(.aaa){
    .no{
      display: none;
    }
  }
`;

export const ContainerText = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const ContainerSkills = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;
  }
`;

export const ImgEmpty = styled.div`
  display: ${({ display }) => (display ? display : "flex")};
  justify-content: center;
  padding:${({ display }) => (display ? "0px" : "30px 0px")};


`;
