import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import generalData from "./slices/generalData";
import userAll from "./slices/userAll";
import posts from "./slices/posts";
import usuario from "./slices/usuario";


export default configureStore({
  reducer: {
    user,
    generalData,
    userAll,
    posts,
    usuario
  },
  // devTools: false,
});
