import styled from "styled-components";
import { colors, PATH } from "../../../../../../assets/variables";

export const WrapperStories = styled.div`
  position: relative;
  margin-top: 16px;
  padding: 5px 0 5px 8px;
`;

export const Arrow = styled.div`
  transform: ${({ prev }) => (prev ? "rotate(180deg) translate(0, 50%)" : "")};
  background-image: url(${PATH}img/CheckRight.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  z-index: 3;
  :before {
    content: "";
  }

  :hover {
    background-image: url(${PATH}img/CheckRight.svg);
  }
`;

export const ArrowButton = styled.div`
   &.slick-next{
    img{
      right: 0;
      bottom: -250px;
      position: absolute;
    }
    @media (max-width: 600px) {
      img{
        width: 90px;
        right: 77px;
        bottom: -233px;
      }
    }
   }
   &.slick-prev{
    img{
      position: absolute;
      bottom: -250px;
      left: 0;
    }
    @media (max-width: 600px) {
      img{
        width: 90px;
        left: 77px;
        bottom: -233px;
      }
    }
   }
`;

export const Shadow = styled.div`
  position: absolute;
  ${({ left }) => (left ? "left: 0; " : "right: 0;")}
  bottom: 0;
  z-index: 2;
  height: 100%;
  /* width: 1px;
  background-color: #000000; */
  box-shadow: ${({ left }) =>
    left
      ? "1px 0 3px 5px #fff, 2px 0 4px 5px #fff, 3px 0 5px 6px #fff;"
      : "-1px 0 5px 5px #fff, -3px 0 7px 5px #fff, -5px 0 10px 8px #fff;"};
`;

export const Modal = styled.div`
  width: 350px;
  height: 700px;
`;
