import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../assets/variables';
import { Title } from '../../../../../Styled'
import { ContainerPostsList } from './Styled'

const PostList = () => {
    const datos = useSelector(store => store.posts.totalPosts);

  return (
    <>
        <ContainerPostsList>
            <Title size="22px" mb="29px">
                Lista de publicaciones
            </Title>
            <table>
                <thead>
                    <tr>
                        <th><h3>Nombre Publicacíon</h3></th>
                        <th><h3>Gerencia</h3></th>
                        <th><h3>Editar</h3></th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map(x =>( 
                        !x.data.finalizado ? (
                        <>
                            <tr key={x.data.id}>
                            <td>{x.data.cargo}</td>
                            <td>{x.data.gerencia}</td>
                            <td> <NavLink to={`postsList/${x.data.id}`}><img src={PATH + "img/icono-lapiz.svg"} /></NavLink> </td>
                        </tr>
                        </>
                        ) : ""
                    ))
                    }
                </tbody>
            </table>
        </ContainerPostsList>
    </>
  )
}

export default PostList