import React, { useState, useEffect } from "react";
import { PATH, colors } from "../../../assets/variables";
import { Icon } from "../../Common/IconProfile/Styled";
import { useSelector, useDispatch } from "react-redux";
import { TitleSection } from "../Filters/Styled";
import { ContainerModal, Hr, SubTitle, Title } from "../../../Styled";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import {v4} from "uuid"

import {
  BtnClose,
  BtnSubmit,
  ModalWrapper,
  Section
} from "../../PostDetails/PostModal/Styled";
import { ContainerFavs, FavsImgProfile, FavsItem, FavsList } from "./Styled";
import { fetchInfoUserFav } from "../../../store/slices/userAll";
import { fetchToken } from "../../../customHooks/hooks";
import Alert from "../../Common/Alert/Alert";
import { ContainerButton } from "../../Common/Alert/Styled";
import { BtnBlue, BtnCancelar } from "../../VacanciesDetails/MainVacancy/styled";
import { capitalizeWords } from "../../../utils/utilidades";

const Favs = () => {
  const dispatch = useDispatch();
  const [showDetailsUser, setshowDetailsUser] = useState([]);
  const [showDetailsUserTags, setshowDetailsUserTags] = useState([]);
  const [showModalDelFav, setshowModalDelFav] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const userData = useSelector(store => store.userAll);
  const {data,token} = useSelector(store => store.usuario);

  function showDetailsProfile(id){
    setshowModal(true)
    const searchObject= userData.datos.find((user) => user.id==id);
    const skillsMap = searchObject.habilidad.map(tag => ({ id: v4(), title: tag}));
    const interesesMap = searchObject.intereses.map(tag => ({ id: v4(), title: tag}));
    const lenguajesMap = searchObject.lenguajes.map(tag => ({ id: v4(), title: tag}));
    const tagsObject = {
      skills: skillsMap,
      interests:interesesMap,
      languages:lenguajesMap
    }
   
    
    setshowDetailsUser(searchObject)
    setshowDetailsUserTags(tagsObject)
  }
  async function deleteFav(id){
    const dataUserFav = {
      id: id
    };
   const post = await fetchToken({
      endpoint: "Persona/DeleteFav",
      method: "POST",
      body: dataUserFav,
      alt: { error: true },
      token:token
    });
    if(post.FlgOk === 1){
      setshowModalDelFav(false)
      setshowModal(false)
      dispatch(fetchInfoUserFav(token));
    }
    
  }
  return (
    <>
      <ContainerFavs>
        <TitleSection>Tus favoritos:</TitleSection>
        <FavsList>
            {
                userData.useFav.map(x => {
                  return (
                    <FavsItem key={x.id}>
                      <Icon
                        img={
                          x?.img
                            ? `${PATH}perfil/profile${x.img}.svg`
                            : "./img/profile.svg"
                        }
                        size="32px"
                        back={
                          x?.background ? x.background : colors.graySkeleton
                        }
                      />                      
                      <h5>{capitalizeWords(x.nombreCargo)}</h5>
                      <img  onClick={() => showDetailsProfile(x.id)} src={PATH + "img/Info.svg"} alt="icono info" />
                    </FavsItem>
                  ) 
                })
            }
        </FavsList>
      </ContainerFavs>
            {showModal && (
        <ContainerModal>
          <ModalWrapper>
            <BtnClose onClick={() => setshowModal(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
            <Section>
              <div>
              <Icon img={PATH + `perfil/profile${showDetailsUser.img}.svg`} size="80px" 
                back={
                  showDetailsUser?.background ? showDetailsUser.background : colors.graySkeleton
                }
                />              
              </div>
              <div>
                <Title mb="0" size="18px">
                   {capitalizeWords(showDetailsUser.nombreCargo)}
                </Title>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Gerencia actual: <span>{capitalizeWords(showDetailsUser.nombreVpGerencia)}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Estamento: <span>{showDetailsUser.estamento}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Nivel de cargo: <span>{showDetailsUser.nivelCargo}</span>
                </SubTitle>
              </div>
            </Section>
            <Hr $margin="24px" />
            <CapSkills itemsData={showDetailsUserTags} />
            <Hr $margin="24px" />
            <div>
              <Title mb="0" size="16px">
                Descripcion personal:
              </Title>
              <SubTitle lh="24px" $margin="0" size="16px">
                {showDetailsUser.descripcion}
              </SubTitle>
            </div>
            <Hr $margin="24px" />
            <BtnSubmit onClick={()=>{setshowModalDelFav(true);setshowModal(false)}}>
              Eliminar de favoritos <img src={PATH + "/Iconmas.svg"} alt="" />
            </BtnSubmit>
          </ModalWrapper>
        </ContainerModal>
      )}
      
      <Alert
      estado={showModalDelFav}
      >
        <img src={PATH + "icono-info.svg"} alt="info"/> 
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas eliminar a este colaborador a tus favoritos?
         </p>
        
        <ContainerButton>
          <BtnCancelar width={"150px"}  onClick={()=> {setshowModalDelFav(false);setshowModal(true)}}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={()=> deleteFav(showDetailsUser.id)}>Eliminar</BtnBlue>
          
        </ContainerButton>

      </Alert>
    </>
  );
};

export default Favs;
