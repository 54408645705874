import styled from "styled-components";

export const ContainerPostsList = styled.div`
    border-radius: 8px;
    box-shadow: 0 0 4px #bebebe;
    width: 100%;
    padding: 13px 16px;
    height: auto;
    background: #ffffff;
    position: relative;
    table{
        width: 100%;
        border-collapse: collapse;
        thead{
            tr{
                border-bottom: 0.5px solid #ccc;
                th{
                    text-align: left;
                    h3{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #191919;
                        margin-bottom: 8px;
                    }
                }
            }
        }
        tbody{
            tr{
                border-bottom: 0.5px solid #ccc;
                td{
                    padding: 15px 0;
                }
            }
        }
    }
`;
