import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../assets/variables";
import { addAttachment } from "../../../customHooks/hooks";
import { addPostDestacada } from "../../../store/slices/posts";
import { Hr, Title } from "../../../Styled";
import { Loader } from "../../Common/LoaderModal/LoaderModal";
import Files from "../../OfferForm/Form/Files/Files";
import FilesTwo from "../../OfferForm/Form/Files/FilesTwo";
import { ContainerImg } from "../../OfferForm/Form/Styled";
import TextArea from "../../OfferForm/Form/TextAreas/TextArea/TextArea";
import { ContainerHalfCustom } from "../../OfferForm/Styled";
import { BtnSave, Section } from "../../UserRegister/FormUser/Styled";
import { OffSet } from "../../VacanciesDetails/Style";
import { Legend, WrapperLinkBtn } from "./Styled";

const Form = ({ newStoryData, setNewStoryData }) => {
  const dispatch = useDispatch();
  const token = useSelector(store => store.user.token);
  const [load, setLoad] = useState(false);
  const [archivos, setArchivos] = useState([])
  const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '');
  const validator = () => {
    return (
      newStoryData.file &&
      newStoryData.fechaVencimiento !== "" &&
      newStoryData.titulo !== "" &&
      newStoryData.bajada !== ""
    );
  };

  const sendInfo = async e => {
    if (!validator()) {
      return;
    }
    setLoad(true);


    const type = newStoryData.file.type;

    const objServer = {
      titulo: newStoryData.titulo,
      descripcion: newStoryData.bajada,
      url: newStoryData.url,
      pais: 'Chile'
    };
    // const url = await addAttachment(newStoryData.file);
    // const url2 = await addAttachment(newStoryData.file2);

    objServer.archivo = removeAccents(`${newStoryData.file.name};#${type}`);
    objServer.img = removeAccents(`${newStoryData.file2.name};#${type}`).trim();

     dispatch(addPostDestacada(objServer, () => setLoad(false),token, archivos));

  };


  const handleUrl = e => {
    setNewStoryData({
      ...newStoryData,
      url: e.target.value.replace(" ", ""),
      agregarBtn:
        e.target.name === "addBtn" ? e.target.checked : newStoryData.agregarBtn
    });
  };

  return (
    <div>
      <AnimatePresence>
      {/* <Loader text="Creando nueva publicacion..." /> */}
        {load && <Loader id="loader" text="Creando nueva publicacion..." />}

      </AnimatePresence>
      <OffSet />
      <ContainerHalfCustom scroll>
        <ContainerImg />
        <Title size="22px" bold="500" lh="26px" mb="16px">
          Formulario de publicación destacada
        </Title>
        <Hr $margin="0 0 24px" />
        <Title color="blue" size="18px" mb="8px" lh="22px" bold="500">
        Adjuntar una imagen para el icono:<br></br>
          <small>Tamaño recomendado 80px ancho y 80px alto</small>
        </Title>
        <FilesTwo newCapData={newStoryData} setNewCapData={setNewStoryData} setArchivos={setArchivos} archivos={archivos}/>
        <Hr $margin="0 0 24px" />
        <Title color="blue" size="18px" mb="8px" lh="22px" bold="500">
        Adjuntar imagen o video historia destacada<br></br>
          <small>Tamaño recomendado 350px ancho y 1000px alto</small>
        </Title>
        <Files newCapData={newStoryData} setNewCapData={setNewStoryData} setArchivos={setArchivos} archivos={archivos}/>
        <TextArea
          title="Titulo"
          newCapData={newStoryData}
          name="titulo"
          maxCharacters={20}
          setNewCapData={setNewStoryData}
        />
        <TextArea
          title="Bajada de texto"
          newCapData={newStoryData}
          name="bajada"
          maxCharacters={124}
          setNewCapData={setNewStoryData}
        />
        <Hr $margin="24px 0" />
        <WrapperLinkBtn>
          <input
            type="checkbox"
            id="link-btn"
            name="addBtn"
            value={newStoryData.url}
            onChange={handleUrl}
          />
          <label htmlFor="link-btn">Agregar botón</label>
          <Section
            as="fieldset"
            className={newStoryData.agregarBtn && "blue_border"}
            flex
            border
            $width="100%"
            $padding="16px"
            $height="56px"
          >
            {newStoryData.agregarBtn && <Legend>Link enlace:</Legend>}
            <input
              type="text"
              value={newStoryData.url}
              placeholder="https://entel.workspace..."
              onChange={handleUrl}
            />
          </Section>
        </WrapperLinkBtn>
        <Hr $margin="24px 0" />
        <BtnSave onClick={sendInfo} modified={validator()}>
          Guardar cambios <img src={PATH + "img/IconSend.svg"} alt="save" />
        </BtnSave>
      </ContainerHalfCustom>
    </div>
  );
};

export default Form;
