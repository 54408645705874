import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { PATH } from "../../../../assets/variables";
import { fetchToken } from "../../../customHooks/hooks";
import { Icon } from "../../Common/IconProfile/Styled";
import { Wrapper } from "./styled";

const a = [
  "ECFAFE",
  "A4F2D9",
  "B1EAF8",
  "C9C6EA",
  "748EFE",
  "FEF9EB",
  "F8B106",
  "FFE2EB",
  "FCB6CC",
  "FEF4F2"
];

const Applicants = ({ id }) => {
  const [avatar, setAvatar] = useState([])
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: `Publicacion/GetAvatar?id=${id}` });
    setAvatar(dataUsers)
  }
  useEffect(() => {
    FetchData()
  }, [])
  
  return (
    <Wrapper>
      {avatar.map((icon, i) => (
        <Icon
          key={i}
          img={
            icon?.img
              ? `${PATH}perfil/profile${icon.img}.svg`
              : "./img/profile.svg"
          }
          back={icon.background}
          rel={i}
          size="32px"
        />
      ))}
    </Wrapper>
  );
};

export default Applicants;
