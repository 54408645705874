import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { GlobalStyle } from "./Styled";
import { Provider } from "react-redux";
import store from "./store";
import { MsalProviderComponent } from "./auth/MsalProviderComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
// basename = "/sites/SeleccionDeTalento/SiteAssets/build/index.aspx";
// basename = "/sites/SeleccionDeTalento/SiteAssets/Test.aspx";
// <BrowserRouter basename="/sites/SeleccionDeTalento/SiteAssets/Test.aspx"></BrowserRouter>;

root.render(
  // <MemoryRouter initialEntries={["/"]} initialIndex={0}>
  //   <GlobalStyle />
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </MemoryRouter>

<MsalProviderComponent />


  //   <BrowserRouter basename="/sites/SeleccionDeTalento/SiteAssets/Test.aspx">
  //   <GlobalStyle />
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
