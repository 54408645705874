import styled from "styled-components";
import { PATH } from "../../../assets/variables";

export const Organizer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  aside {
    margin-left: 8px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background-color: #666666;
  width: 100%;
`;

export const ContainerOptions = styled.div`
  position: relative;
  select {
    border: none;
    background: none;
    outline: none;
    font-family: "Barlow";
    font-weight: 600;
    line-height: 20px;
    color: #191919;
    appearance: none;
    margin-left: 8px;
    box-sizing: border-box;

    option {
      background-color: none;
      appearance: none;
    }
  }

  :before {
    content: url(${PATH}/CheckDown.svg);
    position: absolute;
    right: -20px;
    top: 4px;
  }
`;
