import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Hr, Title } from "../../../../../Styled";
import Stories from "./Stories/Stories";
import { ContainerStories } from "./Styled";

const SectionStories = ({match}) => {

  return (
    <>
      <ContainerStories>
        <Title size="18px">Ultimas novedades</Title>
        <Hr />
        <Stories matches={match} />
      </ContainerStories>
    </>
  );
};

export default SectionStories;
