import styled from "styled-components";
import { colors } from "../../../../../assets/variables";

export const FilesContainer = styled.div`
  background-color: #f2f2f2;
  padding: 17px;
  border-radius: 8px;
`;

export const FilesContainerNew = styled.div`
  display: flex;
`;
export const InputFile = styled.div`
  height: 48px;
  width: 100%;
  border: 1px solid #66686C;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;
  h5{
    font-size: 18px;
    color: #66686C;
    font-weight: 400;
  }
  &.activo{
    border: 0;
    border-bottom: 1px solid #1EA67B;
  }

`;
export const UploadInput = styled.input`
  display: none;
`;

export const UploadLabel = styled.label`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
`;
export const UploadLabelNew = styled.label`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.mainBlue};
  cursor: pointer;
  text-decoration: underline;
`;