import React from "react";
import Header from "../Header/Header";
// import { useList } from "../../customHooks/hooks";
import { ChildrenContainer, WrapperGeneral } from "./Style";

const MasterPage = ({ children }) => {
  
  return (
    <WrapperGeneral>
      <Header />
      <ChildrenContainer>{children}</ChildrenContainer>
    </WrapperGeneral>
  );
};

export default MasterPage;
