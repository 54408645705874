import  styled   from "styled-components";

export const ContainerFavs = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 7px 15px 27px 15px;
    height: 482px;
    overflow: auto;
    
`
export const FavsList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`
export const FavsItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    border-top: 1px solid #E5E5E5;
    h5{
        margin-right: auto;
        font-size: 14px;
        font-weight: 600;
        margin-left: 8px;
        text-wrap:balance;
    }
`
export const FavsImgProfile = styled.img`
    width: 32px;
    height: 32px;
`