import styled from "styled-components";

export const ContainerSkills = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 7px 16px 0;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    display: none;
  }

  div {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  //solo para diferenciar, borrar luego
  box-shadow: 0 0 4px #c2c2c2;
`;

export const ContainerTopItems = styled.div`
  /* position: sticky; */
  top: ${props => props.user ? '200px' : '100px'};
  height: fit-content;
`;
