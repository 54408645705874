import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import Caps from "./Caps/Caps";
import SectionStories from "./SectionStories/SectionStories";
import { ContainerOptions, Line, Organizer } from "./Styled";

const Feed = () => {
  const userData = useSelector(store => store.userAll.match);
  const elementRef = useRef([]);
 
  
  useEffect(() => {
  }, []);

  return (
    <div>
      <SectionStories match={userData}/>
      <Organizer>
        <Line />
        {/* <aside>Ordenar por:</aside> */}
        {/* <ContainerOptions>
          <select>
            <option>Recientes</option>
            <option>Antiguas</option>
            <option>Todos</option>
          </select>
        </ContainerOptions> */}
      </Organizer>
      <Caps />
    </div>
  );
};

export default Feed;
