import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "../../../../../../assets/variables";

export const ContainerSection = styled.div`
  padding: 0 16px 14.5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  background-color: #fff;
  box-shadow: 0 -5px 5px #fff;
  section {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 30.5px;

    div {
      border: 2px solid  ${({ noMatch }) => (noMatch ? "#F8B106" : colors.pink) };
      border-radius: 200px;
      display: flex;
      align-items: center;
      padding: 4px;
      justify-content: center;
      gap: 16px;
      font-weight: 600;
      span{
        font-size: 10px;
        font-weight: 400;
        display: contents;
      }
    }
  }
`;

export const CollapseImg = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;
