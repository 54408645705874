// import { runWithAdal } from "react-adal";
// import { authContext } from "./adalConfig";

const DO_NOT_LOGIN = false; // Por defecto en false, para pruebas locales en true

// runWithAdal(
//   authContext,
//   () => {
//     // eslint-disable-next-line
//     require("./indexApp.js");
//   },
//   DO_NOT_LOGIN
// );


require("./indexApp.js");
