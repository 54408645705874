import React from "react";
import { PATH } from "../../../../assets/variables";
import Properties from "../../Home/Feed/Caps/Cap/CapProperties/Properties";
import CapVideo from "../../Common/CapVideo/CapVideo";
import { BtnLink } from "../../Home/Feed/Caps/Cap/Styled";
import Applicants from "../Applicants/Applicants";
import { PostStyle } from "./Styled";

const Post = ({ post }) => {
  return (
    <>
     { !post.data.finalizado  && (
    <PostStyle>
    <div>
    <CapVideo post={true} custom={false} archivo={post.data.archivo} />
    </div>
    <section>
      <Properties
        gerencia={post.data.gerencia}
        jornada={post.data.jornada}
        ubicacion={post.data.ubicacion}
        estamento={post.data.estamento}
        cargo={post.data.cargo}
        padding="0"
        margin="0"
      />
      <Applicants id={post.data.id} />
      <BtnLink width="194px" to={`${post.data.id}`}>
        <p>Ir a la publicación</p>
        <img src={PATH + "img/ArrowRight.svg"} alt="Arrow Right" />
      </BtnLink>
    </section>
  </PostStyle>
     )}

    </>
  );
};

export default Post;
