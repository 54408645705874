import React from "react";
import { PATH } from "../../../../assets/variables";
import { Hr, SubTitle, Title } from "../../../../Styled";
import { Icon } from "../../Common/IconProfile/Styled";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";

import { BtnClose, BtnSubmit, ModalWrapper, Section } from "./Styled";

const PostModal = ({ setShowModal }) => {
  const capData = {
    //change for real data
    interests: [],
    languages: [],
    skills: []
  };

  return (
    <ModalWrapper>
      <BtnClose onClick={() => setShowModal(x => !x)}>
        <img src={PATH + "/Close.svg"} alt="Close" />
      </BtnClose>
      <Section>
        <div>
          <Icon img={PATH + "/profile2.svg"} size="80px" />
        </div>
        <div>
          <Title mb="0" size="18px">
            Analista UX/UI
          </Title>
          <SubTitle lh="20px" $margin="0" size="14px">
            Gerencia actual: <span>Capital Humano</span>
          </SubTitle>
          <SubTitle lh="20px" $margin="0" size="14px">
            Estamento: <span>Profesional</span>
          </SubTitle>
          <SubTitle lh="20px" $margin="0" size="14px">
            Nivel de cargo: <span>4</span>
          </SubTitle>
        </div>
      </Section>
      <Hr $margin="24px" />
      <CapSkills itemsData={capData} />
      <Hr $margin="24px" />
      <div>
        <Title mb="0" size="16px">
          Descripcion personal:
        </Title>
        <SubTitle lh="24px" $margin="0" size="16px">
          Asegurar el liderazgo de Entel en el mercado B2B mediante la
          identificación de los cambios en la industria ICT relativos al
          portafolio de Entel Corp que permita mantener y hacer crecer el market
          share por cada negocio
        </SubTitle>
      </div>
      <Hr $margin="24px" />
      <BtnSubmit>
        Me interesa <img src={PATH + "/Iconsend.svg"} alt="" />
      </BtnSubmit>
    </ModalWrapper>
  );
};

export default PostModal;
