import { useEffect } from "react";
import { formatDate, getDays } from "../../../../../../utils/utilidades";
import { SkeletonLoader } from "../../../../../Common/Skeleton/Styled";
import { StoryStyle } from "./Styled";

//Creamos un componente para cada historia que toma el usuario para imprimir dos posibles colores si tiene informacion o imprime el loader mientras carga la info.
const Story = ({
  user,
  setShowModal,
  setSlideInitial,
  i,
  setActiveSlide,
  infoStories,
  destacada
}) => {
  //esta funcion establece la historia inicial con la que se desplegaran las historias, establece tambien la historia activa para validar el inicio de la reproduccion del video y por ultimo activa el modal.
  const handleDoubleclick = () => {
    if (setShowModal) {
      setSlideInitial(i);
      setActiveSlide(x => ({ ...x, current: i, next: i }));
      setShowModal(x => !x);
    }
  };

  useEffect(() => {
  }, [])

  return (
    <StoryStyle destacada={destacada} onClick={handleDoubleclick}>
      {infoStories.loading === "succeded" &&
      infoStories.results.length > 0 ? 
      (<><img src={`https://colaboradordigital.blob.core.windows.net/ematch/archivos/`+user.img.slice(0, user.img.indexOf(';#'))} alt="logo persona"/>{user.fecha_vencimiento === null ? '' :<span>{getDays(user.fecha_vencimiento === null ?  '' : user.fecha_vencimiento)} Días</span>}
      </>) : (
        <SkeletonLoader />
      )}
    </StoryStyle>
  );
};

export default Story;
