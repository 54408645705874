import styled from "styled-components";

export const WrapperPosts = styled.div`
  background-color: #fff;
  padding: 17px 20px;
  border-radius: 8px;
  box-shadow: 0 0 4px #c2c2c2;
  @media (max-width: 600px) {
    margin-top: 40px;
  }
`;

export const PostEmptyContainer = styled.div`
  height: 250px;
  display: flex;
  gap: 10px;
  margin: 10px;
  border: 1px solid #bebebe;

  & > div {
    width: 400px;
    height: 248px;
    position: relative;
    overflow: hidden;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-evenly;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        position: relative;
        width: 425px;
        height: 10px;
        overflow: hidden;
        border-radius: 8px;
      }
    }
  }
`;
