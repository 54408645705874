import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalMobile, setPostFinalizado, setUserPostulo } from "../../../../../store/slices/userAll";
import { Title } from "../../../../../Styled";
import { WrapperVacancy } from "./Styled";

const Vacancy = ({ post: { id, cargo, descripcion } }) => {

  const dispatch = useDispatch();
  const postFinalizado = useSelector(store => store.posts.totalPosts);

  
  return (
    <WrapperVacancy to={"/inicio/resumen/" + id}>
      <div onClick={()=>  {dispatch(setUserPostulo(id)); dispatch(setModalMobile(true));dispatch(setPostFinalizado(postFinalizado.some(fil => fil.data.finalizado === true && fil.data.id === id)))}}>
        <Title color="blue" size="20px">
          {cargo}
        </Title>
        <h4>Descripcion:</h4>
        <p>{descripcion}</p>
      </div>
    </WrapperVacancy>
  );
};

export default Vacancy;
