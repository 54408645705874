import styled from "styled-components";
import { colors } from "../../../../assets/variables";
import { loading } from "../../Common/Skeleton/Styled";

export const PostStyle = styled.div`
  margin-top: 24px;
  height: 280px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }

  & > div {
    width: 400px;
    height: 280px;
    object-fit: contain;
    @media (max-width: 600px) {
      width: 100%;
    }
    div{
      height: 100%;
      display: flex;
      align-items: center;
      img{
        height: 100%;
      @media (max-width: 600px) {
          height: auto;
      }
      }
      video{
        height: 100%;
      }
    }
  }

  & > section {
    /* width: 100%; */
    flex-grow: 1;
    padding: 27px 22px 27px 30px;
    @media (max-width: 600px) {
    a{
      width: 100%;
      justify-content: center;
    }
    }

  }

  .loading {
    background-color: ${colors.graySkeleton};
    width: 400px;
    height: 280px;

    :before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        color-stop(rgba(255, 255, 255, 0.2)),
        to(transparent)
      );

      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
      );

      animation: ${loading} 0.8s infinite;
    }
  }
`;
