import styled from "styled-components";
import { scrollCustom } from "../../../assets/variables";
import { ContainerModal } from "../../../Styled";
import { ContainerGrid } from "../Home/Styled";
import { BtnClose } from "../PostDetails/PostModal/Styled";

export const ContainerGridCustom = styled(ContainerGrid)`
  position: fixed;
  top: 0;
  margin: 0 auto;
  @media (max-width: 600px) {
    position: inherit;
   }

  & > div {
    height: 100vh;
    @media (max-width: 600px) {
    height: 100%;
   }
  }

  .active {
    & > div {
      background-color: #f3f5ff;
    }
  }
`;

export const BackBtn = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  color: #999999;
  text-decoration: underline;
  position: fixed;
  top: 60px;
  background: transparent;
  z-index: 97;
  cursor: pointer;
`;

export const ContainerHalf = styled.div`
  position: relative;
  height: min-content;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  ${({ $max }) => ($max ? "max-height: 87vh;" : "height: 87vh;")}
  box-shadow: 0 0 4px #c2c2c2;
  ${({ scroll }) => scroll && "overflow-y: auto;"}
  ${({ scroll }) => scroll && scrollCustom}
  @media (max-width: 600px) {
    ${({ myPost }) => (myPost ? "height: 56vh;" : "height: 85vh;")}

   }
  & > section {
    ${({ section_scroll, header_height }) =>
      section_scroll &&
      `height: calc(100% - ${header_height}px); overflow-y: auto; ${scrollCustom} `}
  }
`;

export const OffSet = styled.div`
  height: 13vh;
  background-color: transparent;
  @media (max-width: 600px) {
    display: none;
   }
`;
export const ContainerHalfModal = styled(ContainerModal)`
  background-color: transparent;
  position: initial;
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    background: rgba(2,2,71,0.8);
    padding: 0 28px;
   }
`

export const ContainerHalfModalSearch = styled(ContainerModal)`
  background-color: transparent;
  position: initial;
  align-items: flex-start;
  z-index: 1;
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    background: rgba(2,2,71,0.8);
    padding: 0 28px;
    align-items: center;
    z-index: 99;
   }
`
export const BtnCloseMobile = styled.div`
  display: none;
  position: fixed;
    top: 26px;
    right: 26px;
    cursor: pointer;
  @media (max-width: 600px) {
    display: block;
   }
`
