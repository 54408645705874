export const colors = {
  mainBlue: "#002EFF",
  orange: "#FF560A",
  greenStories: "#A4F2D9",
  pink: "#FD6C98",
  tagsBlue: "#2E1FC8",
  graySkeleton: "#bebebe",
  ligthBlue: "#DEE4FB",
  yellowAverage: "#FFE6A0",
  blueAverage: "#B1EAF8"
};

export const scrollCustom = `::-webkit-scrollbar {
    -webkit-appearance: none;
    position: fixed;
    top: 0;
    right: 0;
  }

  ::-webkit-scrollbar:vertical {
    width: 9px;
  }

  ::-webkit-scrollbar-button:increment,
  .contenedor::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }`;

export const PATH =
  "https://colaboradordigital.blob.core.windows.net/ematch/";
