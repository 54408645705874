import React, { useState } from "react";
import { PATH } from "../../../assets/variables";
import { Hr, Title } from "../../../Styled";
import Files from "../OfferForm/Form/Files/Files";
import { ContainerImg } from "../OfferForm/Form/Styled";
import TextArea from "../OfferForm/Form/TextAreas/TextArea/TextArea";
import { ContainerHalfCustom } from "../OfferForm/Styled";
import { ContainerGridCustom } from "../PostDetails/Styled";
import { BtnSave } from "../UserRegister/FormUser/Styled";
import { OffSet } from "../VacanciesDetails/Style";
import Form from "./Form/Form";
import StoryPrev from "./StoryPrev/StoryPrev";
import { ContainerStoryPrev } from "./Styled";

const StoryForm = () => {
  const [newStoryData, setNewStoryData] = useState({
    fechaVencimiento: "jhghjg",
    file: undefined,
    file2: undefined,
    titulo: "",
    bajada: "",
    url: "",
    agregarBtn: false
  });

  return (
    <ContainerGridCustom columns="540px 540px">
      <Form newStoryData={newStoryData} setNewStoryData={setNewStoryData} />
      <StoryPrev
        newStoryData={newStoryData}
        setNewStoryData={setNewStoryData}
      />
    </ContainerGridCustom>
  );
};

export default StoryForm;
