import { motion } from "framer-motion";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../../../../assets/variables";

export const ContainerCaps = styled(motion.div)`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
  overflow: hidden;
  padding-bottom: 100px;

  //solo para diferenciar, borrar luego
  box-shadow: 0 0 4px #c2c2c2;
`;

export const CapLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 29px 0 40px;
`;

export const ContainerTags = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px 0 20px;
  h5{
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 6px;
    span{
      width: 16px;
      height: 16px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      &.tag{
        background-color: #DEE4FB;
      }
      &.tagMatch{
        background-color: #ADF4DD;
      }
    }
  }
`;

export const BtnLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  background-color: ${colors.mainBlue};
  background: #002eff;
  border-radius: 28px;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
