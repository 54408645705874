import styled from "styled-components";
import { PATH } from "../../../../assets/variables";

export const ContainerSetPage = styled.div`
  color: #002eff;
  align-items: center;
  display: flex;

  & section {
    position: relative;

    select {
      width: 60px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 8px;
      text-align: center;
      margin: 0 3px;
      outline: none;
      appearance: none;
      padding-right: 20px;
    }

    &:after {
      content: url(${PATH}doblef.svg);
      position: absolute;
      right: 12px;
      top: 5px;
      margin: auto;
    }
  }
`;

export const ButtonEdge = styled.button`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 12px;
  color: #002eff;
  margin: 0;
  border: none;
  background-color: #fff;
  opacity: ${({ can }) => (can ? 1 : 0.5)};
  cursor: ${({ can }) => (can ? "pointer" : "not-allowed")};
`;

export const NumberButton = styled.button`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 12px;
  color: ${({ can }) => (can ? "#FFFFFF" : "#002EFF")};
  margin: 0;
  border: 1px solid #f2f2f2;
  border-top: none;
  border-bottom: none;
  background-color: ${({ can }) => (can ? "#002EFF" : "#FFFFFF")};
  cursor: pointer;
`;

export const WrapperBtns = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

export const ContPageBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 15px 0;
`;

export const ContImgDelete = styled.div`
  img {
    pointer-events: none;
  }
`;
