import styled from "styled-components";
import { colors } from "../../../../assets/variables";

export const HeaderSection = styled.div`
  margin: 13px 18px 0;
  border-bottom: 1px solid #cccccc;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ $padding }) => $padding};
`;

export const ItemBox = styled.div`
  flex-basis: ${({ $width }) => $width};
  padding: ${({ p }) => p};
  display: flex;
  gap: ${({ gap }) => (gap ? gap : "8px")};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ jc }) => jc};
  flex-grow: ${({ grow }) => grow};
  align-items: center;

  img {
    ${({ icon }) => icon && "width: auto; height: 24px;"}
  }
`;

export const ApplicantWrapper = styled.div`
  display: flex;
`;

export const Score = styled.div`
  width: 50px;
  background-color: ${({ back }) =>
    back > 70
      ? colors.greenStories
      : back > 50
      ? colors.blueAverage
      : colors.yellowAverage};
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  align-items: center;
  padding: 2px 0;
  height: 24px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000072;
  }
`;

export const Hv = styled.div`
  width: 0px;
  height: 32px;
  border-right: 1px solid #e5e5e5;
`;

export const SkeletonContainer = styled.div`
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
`;
