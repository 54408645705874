import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "../../../../assets/variables";

export const ContainerMaintainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 4px #bebebe;
  width: 100%;
  padding: 13px 16px;
`;

export const Fieldset = styled.fieldset`
  display: flex;
  position: relative;
  border-radius: 8px;
  width: ${props => props.width ? props.width : '608px'};
  height: 56px;
  border: 1px solid ${colors.graySkeleton};

  input {
    outline: none;
    width: 100%;
    border: none;
  }

  legend {
    text-align: center;
    padding: 0 8px;
    position: absolute;
    top: -9px;
    left: 16px;
    background-color: #ffffff;
  }
`;

export const TableCustom = styled.table`
  width: 100%;
  border-collapse: collapse;
  justify-content: center;
  tr {
    border-bottom: 0.5px solid #ccc;
  }

  td {
    padding: 15px 0;
  }

  img {
    cursor: pointer;
  }
`;

export const Th = styled.th`
  text-align: ${({ id }) =>
    id === "activar" || id === "btnDelete" ? "center" : "left"};
`;
export const Td = styled.td`
  text-align: left;
  div {
    text-align: center;
  }
`;

export const ContainerToggle = styled(motion.div)`
  border-radius: 80px;
  width: 48px;
  height: 24px;
  background-color: #999999;
  margin: 0 auto;
  padding: 2px;
  display: flex;
  /* position: relative; */

  div {
    /* position: absolute;
    left: 2px;
    top: 2px; */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
  }
`;
export const BtnAgg = styled.button`
  background-color: ${({ blue }) => (blue ? "#002EFF" : "#FFFFFF")};
  border: 1px solid #002eff;
  border-radius: 50px;
  width: 160px;
  height: 48px;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow";
  color: ${({ blue }) => (blue ? "#FFFFFF" : "#002eff")};
  font-weight: 600;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 16px;
    margin-left: 8px;
  }
`;
export const ContainerTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const ContainView = styled.div`
  margin-bottom: 32px;
`;
