import styled from "styled-components";

export const HeaderSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 0 16px 22px;
  gap: 8px;
  position: sticky;
  top: 0;
  background-color: #fff;

  /* p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  } */
`;

export const CargosSection = styled.div`
  @media (max-width: 600px) {
    margin-top: 50px;

  }
`;