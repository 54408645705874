import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Home/Home";
import ListMatches from "../ListMatches/ListMatches";
import Maintainers from "../Maintainers/Maintainers";
import MatchesDetails from "../MatchesDetails/MatchesDetails";
import MyPosts from "../MyPosts/MyPosts";
import OfferForm from "../OfferForm/OfferForm";
import OfferFormUpd from "../OfferForm/OfferFormUpd";
import PostDetails from "../PostDetails/PostDetails";
import Search from "../Search/Search";
import StoryForm from "../StoryForm/StoryForm";
import UserRegister from "../UserRegister/UserRegister";
import VacancyDetails from "../VacanciesDetails/VacanciesDetails";

const NavigationComponents = () => {
    return (
        <Routes>
            {/* <Route path="/inicio/*" element={<Home />} />
            <Route path="/inicio/resumen/:id" element={<VacancyDetails />} />
            <Route path="/mis-publicaciones/*" element={<MyPosts />} />
            <Route path="/mis-publicaciones/:id" element={<PostDetails />} />
            <Route path="/buscador" element={<Search />} />
            <Route path="/perfil" element={<UserRegister />} />
            <Route path="/nueva-oferta" element={<OfferForm />} />
            <Route path="/destacada" element={<StoryForm />} />
            <Route path="/mantenedores/*" element={<Maintainers />} />
            <Route path="/coincidencias/*" element={<ListMatches />} />
            <Route path="/coincidencias/:id" element={<MatchesDetails />} />
            <Route path="/mantenedores/posts/postsList/:id" element={<OfferFormUpd />} /> */}
            <Route path="/inicio/*" element={<Home />} />
            <Route path="/inicio/resumen/:id" element={<VacancyDetails />} />
            <Route path="/mis-publicaciones/*" element={<MyPosts />} />
            <Route path="/mis-publicaciones/:id" element={<PostDetails />} />
            <Route path="/buscador" element={<Search />} />
            <Route path="/perfil" element={<UserRegister />} />
            <Route path="/nueva-oferta" element={<OfferForm />} />
            <Route path="/destacada" element={<StoryForm />} />
            <Route path="/coincidencias/*" element={<ListMatches />} />
            <Route path="/coincidencias/:id" element={<MatchesDetails />} />
            <Route path="/mantenedores/*" element={<Maintainers />} />
            <Route path="/mantenedores/posts/postsList/:id" element={<OfferFormUpd />} />


            <Route
                path="*"
                element={<Navigate to="./inicio" replace={true} />}
            />
        </Routes>
    );
};

export default NavigationComponents;
