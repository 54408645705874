import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { fetchToken } from "../../../customHooks/hooks";
import MyInterests from "../Profile/MyInterests/MyInterests";
import { ContainerSkills, ContainerTopItems } from "./Styled";
import {v4} from "uuid"
import { useQuery } from "@tanstack/react-query";


const info = {
  interests: [
    { id: 1, title: "Futbol" },
    { id: 2, title: "Comer" },
    { id: 3, title: "Ping Pong" },
    { id: 4, title: "Fiesta" },
    { id: 5, title: "Tenis" }
  ],
  skills: [
    { id: 5, title: "C#" },
    { id: 6, title: "Javascript" },
    { id: 7, title: "NodeJS" },
    { id: 8, title: "HTML" },
    { id: 9, title: "CSS" }
  ],
  languages: [
    { id: 10, title: "Español nativo" },
    { id: 11, title: "Ingles" }
  ]
};

const Skills = ({user}) => {
  const [habilidades, sethabilidades] = useState([])
  const [intereses, setintereses] = useState([])

  const FetchData = async () => {
  const dataSkills = await fetchToken({ endpoint: "Habilidad/GetTop?pais=Chile" });
  const dataIntereses = await fetchToken({ endpoint: "Interes/GetTop?pais=Chile" });
  const skills = dataSkills.map(x => ({
    id: v4(),
    title: x.nombre
    }));
    const interes = dataIntereses.map(x => ({
      id: v4(),
      title: x.nombre
      }));

    sethabilidades(skills)
    setintereses(interes)
  }
  const fetchSkills = async() => {
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetTop?pais=Chile" });
    const skills = dataSkills.map(x => ({
      id: v4(),
      title: x.nombre
      }));
      return skills
   }
   const fetchIntereses = async() => {
    const dataIntereses = await fetchToken({ endpoint: "Interes/GetTop?pais=Chile" });
    const interes = dataIntereses.map(x => ({
      id: v4(),
      title: x.nombre
      }));
      return interes
   }
    const {isLoading:cargandoSkills,data:topSkills} = useQuery({ queryKey: ['habilidades'], queryFn: fetchSkills })
    const {isLoading:cargandoInteres,data:topInteres} = useQuery({ queryKey: ['intereses'], queryFn: fetchIntereses })

   if(cargandoSkills){
    console.log("cargando")
   }
   else{
    console.log(topSkills)
    
   }
  useEffect(() => {
    // FetchData()
  }, [])
  
  return (
    <ContainerTopItems user={user}>
      <ContainerSkills>
        <MyInterests top last title="#Habilidades en entel" items={cargandoSkills ? habilidades : topSkills} />
      </ContainerSkills>
      <ContainerSkills>
        <MyInterests top last title="#Intereses en entel" items={cargandoInteres ? intereses : topInteres} />
      </ContainerSkills>
    </ContainerTopItems>
  );
};

export default Skills;
