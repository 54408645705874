import styled from "styled-components";
import { PATH } from "../../../../assets/variables";

// export const FormContainer = styled.div`
//   padding: 16px;
//   box-shadow: 0 0 4px #c2c2c2;
//   background-image: url(${PATH}back_form.svg);
//   background-repeat: no-repeat;
//   background-position: top right;
//   border-radius: 8px;
//   background-color: #fff;
// `;

export const ContainerImg = styled.div`
  background-image: url(${PATH}back_form.svg);
  background-repeat: no-repeat;
  background-position: top right;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 75px;
`;
