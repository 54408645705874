import React, { useEffect, useState } from "react";
import CapVideo from "../../../../Common/CapVideo/CapVideo";
import Properties from "./CapProperties/Properties";
import { BtnLink, CapLink, ContainerCaps, ContainerTags } from "./Styled";
import CapSkills from "./CapSkills/CapSkills";
import Relation from "./Relation/Relation";
import { PATH } from "../../../../../assets/variables";
import { useDispatch, useSelector } from "react-redux";
import { fetchToken } from "../../../../../customHooks/hooks";
import { calculator } from "../../../../../utils/calculator";
import MatchesDetails from "../../../../MatchesDetails/MatchesDetails";
import { setModalMobile, setPostFinalizado, setUserMatch } from "../../../../../store/slices/userAll";
import { getDays } from "../../../../../utils/utilidades";
import { useNavigate, useParams  } from "react-router-dom";
import { ImgEmpty } from "../Styled";
import { useRef } from "react";
import { useLayoutEffect } from "react";


const Cap = ({
  info: {
    data: { id, cargo, gerencia, estamento, ubicacion, jornada, archivo, fecha_vencimiento, finalizado },
    listadoHabilidades,
    listadoIntereses,
    listadoLenguajes
  }
  
}) => {
  const [matches, setMatches] = useState([]);
  const [numMatches, setNumMatches] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [matchStories,setmactchStories] = useState([])
  const dataId = useSelector(store => store.user.data)
  const {data,token} = useSelector(store => store.usuario);

  const match = useSelector(store => store.userAll.match)
  const dispatch = useDispatch()
  let idmacht = []
  const capData = {
    //change for real data
    interests: listadoIntereses.map(i => ({
      ...i,
      title: i.nombre,
      id: i.idTag
    })),
    languages: listadoLenguajes.map(l => ({
      ...l,
      title: l.nombre,
      id: l.idTag
    })),
    skills: listadoHabilidades.map(s => ({
      ...s,
      title: s.nombre,
      id: s.idTag
    }))
  };

  const navigate = useNavigate()
  useEffect(() => {
    dispatch(setPostFinalizado(false))
    let url = window.location.href;
    let idUrl =  url.replace('https://www.ematch.soyentel.com/?','').slice(1);
    let mantUrl =  url.replace('https://www.ematch.soyentel.com/?','').slice(1);

    (async () => {
      const results = await fetchToken({
        endpoint: `Publicacion/GetTokenMatchTagsV2?id=${id}`,
        alt: { dataSkills: [], dataLenguajes: [] },token:token
      });

      const calc = calculator({
        listadoHabilidades,
        listadoLenguajes,
        listadoIntereses,
        results
      });
      setMatches(calc);
      // if(idUrl != ""){
      //   window.history.replaceState("","","https://www.ematch.soyentel.com/")
      //   navigate(`resumen/${idUrl}`)
      //   dispatch(setModalMobile(true))
      // }
      //     if(mantUrl == "mantenedor"){

      //     window.history.replaceState("","","https://www.ematch.soyentel.com/")
      //     navigate('/mantenedores/habilidades')
      //     }
      if(match.length === 0){
        dispatch(setUserMatch(calc.filter(x => x.fk_persona == dataId.fk_colaborador)))
      }
    })();

  }, [dataId]);


  return (
   <>

    {(() => {
      if(matches.filter(x=> x.average >= 50  && x.fk_persona === dataId.fk_colaborador && x.idpost === id && finalizado !== true).length >0){
        return (
          <ContainerCaps
          className="aaa"
          initial={{ maxHeight: "720px" }}
          animate={{ maxHeight: isOpen ? "945px" : "720px" }}
        >
          <CapVideo post={true} archivo={archivo} fecha_vencimiento={fecha_vencimiento} />
          <ContainerTags>
            <h5><span className="tag"></span>Tag de la publicación</h5>
            <h5><span className="tagMatch"></span>Tag match</h5>
          </ContainerTags>
          <Properties
            cargo={cargo}
            gerencia={gerencia}
            estamento={estamento}
            ubicacion={ubicacion}
            jornada={jornada}
          />
          <CapSkills itemsData={capData} />
          <CapLink>
            <BtnLink to={`resumen/${id}`} onClick={()=> dispatch(setModalMobile(true))}>
              <p>Ir a la publicación</p>
              <img src={PATH + "img/ArrowRight.svg"} alt="Arrow Right" />
            </BtnLink>
          </CapLink>
          <Relation isOpen={isOpen} setIsOpen={setIsOpen} noMatch={false}/>
        </ContainerCaps>
        ); 
        }
        // else if((matches.filter(x=> x.average >= 50  && x.fk_persona === dataId.fk_colaborador && x.idpost === id  && finalizado !== true).length) > 0){
          
        //   return(
        //     <ImgEmpty className={'noPost post'}>
          
        //     <img  src={PATH + "sin_posts.svg"} alt="no post" />
        //   </ImgEmpty>
        //   )
        
        // }
        // else if(matches.filter(x=> x.average >= 50  && x.fk_persona === dataId.fk_colaborador && x.idpost === id  && finalizado !== true).length === 0){
          
        //   return(
        //     <ImgEmpty className={'noPost'}>
          
        //     <img  src={PATH + "sin_posts.svg"} alt="no post" />
        //   </ImgEmpty>
        //   )
        
        // }
        else{
          if(finalizado !== true){
            return (
              <ContainerCaps
              className="aaa"
              initial={{ maxHeight: "661px" }}
              animate={{ maxHeight: isOpen ? "873px" : "661px" }}
            >
              <CapVideo post={true} archivo={archivo} fecha_vencimiento={fecha_vencimiento} />
              <ContainerTags>
                <h5><span className="tag"></span>Tag de la publicación</h5>
                <h5><span className="tagMatch"></span>Tag match</h5>
              </ContainerTags>
              <Properties
                cargo={cargo}
                gerencia={gerencia}
                estamento={estamento}
                ubicacion={ubicacion}
                jornada={jornada}
              />
              <CapSkills itemsData={capData} />
              <Relation isOpen={isOpen} setIsOpen={setIsOpen} noMatch={true}/>
            </ContainerCaps>
            )
          }         
        }
      })()}
    </>
  );
};

export default Cap;

