import React from "react";
import { PATH } from "../../../../assets/variables";
import { Tag, SubTitle, Hr } from "../../../../Styled";
import { MyInterestStyle } from "./Styled";

const MyInterests = ({ title, last, items, top }) => {
  return (
    <>
      <SubTitle top={top}>
        {top && "Top 5"} <span>{title}</span>
      </SubTitle>
      <MyInterestStyle>
        {items.length > 0 ? (
          items.map((item, i) => (
            <div className="contenedorTag" key={item.id}>
              {top && <span>{i + 1}</span>}
              <Tag background="#DEE4FB" title={item.title}>
                {/* <img src={PATH + "iconTag.svg"} alt="lupa" />  */}
                {item.title}
              </Tag>
            </div>
          ))
        ) : (
          <SubTitle>No ingresado</SubTitle>
        )}
      </MyInterestStyle>
      {!last && <Hr />}
    </>
  );
};

export default MyInterests;
