import styled from "styled-components";

export const CalendarImg = styled.img`
  position: absolute;
  right: 16px;
  pointer-events: none;
`;

export const ContainerLeaders = styled.div`
  background-color: #ffffff;
  position: absolute;
  bottom: -66px;
  left: 0;
  width: 100%;
  border: 1px solid #666666;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 5px 15px;
`;

export const Leader = styled.div`
  cursor: pointer;
  border-top: 0.5px solid #666666;
  padding: 5px 0;
  h3 {
    pointer-events: none;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 6px;
  }

  p {
    pointer-events: none;
    color: #cccccc;
  }
`;
