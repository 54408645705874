import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerModal, Hr, SubTitle, Title } from "../../Styled";

import MatchesItems from "./MatchesItems/MatchesItems";
import MatchesMain from "./MatchesMain/MatchesMain";
import MatchesModal from "./MatchesModal/MatchesModal";
import { ContainerGridCustom } from "./Styled";

const MatchesDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  return (
    <>
      {showModal && (
        <ContainerModal
          data-modal="true"
          onClick={e =>
            e.target.dataset.modal === "true" && setShowModal(!showModal)
          }
        >
          <MatchesModal setShowModal={setShowModal} />
        </ContainerModal>
      )}
      <ContainerGridCustom columns="540px 540px">
        <MatchesMain idPost={id} />
        <div>
        <MatchesItems idPost={id} setShowModal={setShowModal} />
        <MatchesItems idPost={id} setShowModal={setShowModal} postulante="postulo" />
        </div>

        
      </ContainerGridCustom>
    </>
  );
};

export default MatchesDetails;
