import styled from "styled-components";
import { colors } from "../../../assets/variables";
import { loading } from "../Skeleton/Styled";

const WrapperIcon = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  background-color: ${({ back }) => back};
  /* overflow: hidden; */
  position: relative;
  left: ${({ rel }) => `-${rel * 2}px`};
  border: ${({ border }) => border && "1px solid #000"};
  pointer-events: none;

  .loading {
    background-color: ${colors.graySkeleton};
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: 50%;

    :before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        color-stop(rgba(255, 255, 255, 0.2)),
        to(transparent)
      );

      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
      );

      animation: ${loading} 0.8s infinite;
    }
  }

  img {
    pointer-events: none;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    object-fit: cover;
  }
`;

export const Icon = ({ img, size, back, rel, border }) => {
  return (
    <WrapperIcon rel={rel} border={border} size={size} back={back}>
      <img
        className="loading"
        onLoad={e => e.target.classList.remove("loading")}
        src={img}
        alt="logo perfil"
      />
    </WrapperIcon>
  );
};
