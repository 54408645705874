import styled from "styled-components";
import { WrapperStory } from "../../Home/Feed/SectionStories/Stories/MainStory/Styled";

export const ContainerStoryPrev = styled.div`
  background-color: rgba(2, 2, 71, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 88px 0;
`;

export const WrapperStoryCustom = styled(WrapperStory)`
  background-color: #000;
  border-radius: 8px;
  height: 624px;
  width: 350px;
  object-fit: contain;
  aspect-ratio: 16/9;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const ImgStory = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
