import { PATH } from "../../../../../assets/variables";
import { Title } from "../../../../../Styled";
import { Section } from "../../../../UserRegister/FormUser/Styled";
import { InfoText } from "./Styled";

const TextArea = ({
  title,
  newCapData,
  name,
  setNewCapData,
  maxCharacters
}) => {
  const handleChange = e => {
    if (e.target.value === " ") {
      return;
    }
    e.target.value.replace("  ", " ").length <= Number(maxCharacters) &&
      setNewCapData({
        ...newCapData,
        [name]: e.target.value.replace("  ", " ")
      });
  };

  return (
    <Section mb="16px">
      <Title size="16px">
        {title} <InfoText>(maximo {maxCharacters} caracteres)</InfoText>
      </Title>

      <textarea
        onChange={handleChange}
        name="description"
        placeholder=""
        value={newCapData[name]}
      />
      <Section flex jc="flex-end">

        <div>
          {newCapData[name].length}/{maxCharacters}
        </div>
      </Section>
    </Section>
  );
};

export default TextArea;
