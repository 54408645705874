import styled from "styled-components";

export const MyInterestStyle = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 16px;
  .contenedorTag{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0;
    span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-right: 10px;
  }
  }
`;
