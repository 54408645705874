import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PATH } from "../../../../../assets/variables";
import { Section, SectionSearch } from "../../../UserRegister/FormUser/Styled";
import SelectCustom from "./SelectCustom/SelectCustom";
import { CalendarImg, ContainerLeaders, Leader } from "./Styled";
import {v4} from "uuid"


const InputFields = ({ newCapData, setNewCapData, handleChange, upd }) => {
  const [leaderToSearch, setLeaderToSearch] = useState({
    name: "",
    status: false // con esta propiedad controlo cuando ya le dio click a un usuario y se seteo en el objeto principal
  });
  const [ubicacionToSearch, setUbicacionToSearch] = useState({
    name: newCapData.ubicacion,
    status: newCapData.ubicacion == "" ? false : true // con esta propiedad controlo cuando ya le dio click a un usuario y se seteo en el objeto principal
  });

  const { gerencias, estamentos, ubicaciones, jornadas, lideres } = useSelector(
    store => store.posts.selectors
  );
  gerencias.map(x => {
    if(x.gerencia === newCapData.gerencia){
      newCapData.gerencia =x.id
    }
  })
  estamentos.map(x => {
    if(x.estamento === newCapData.estamento){
      newCapData.estamento =x.id
    }
  })

  ubicaciones.map(x => {
    if(x.ubicacion === newCapData.ubicacion){
      newCapData.ubicacion =x.id
    }
  })
  if(upd != false){
    lideres.map(x => {
      if(x.fk_colaborador == newCapData.lider){
        newCapData.lider =x.id
        setLeaderToSearch({
          name: x.nombreCompleto,
          status: true
        });
      }
    })
  }

  jornadas.map(x => {
    if(x.jornada === newCapData.jornada){
      newCapData.jornada =x.id
    }
  })


  const leader = useSelector(store => {
    const leaderSplit = leaderToSearch.name.split(" ");
    let finalArray = [];

    for (let i = 0; i < leaderSplit.length; i++) {
      if (i == 0) {
        finalArray = store.posts.selectors.lideres.filter(
          opt =>
            opt.nombreCompleto
              .toLowerCase()
              .includes(leaderSplit[i].toLowerCase()) ||
            opt.email.toLowerCase().includes(leaderSplit[i].toLowerCase())
        );
      } else {
        finalArray = finalArray.filter(
          opt =>
            opt.nombreCompleto
              .toLowerCase()
              .includes(leaderSplit[i].toLowerCase()) ||
            opt.email.toLowerCase().includes(leaderSplit[i].toLowerCase())
        );
      }
    }

    return finalArray;
  });
  let finalArray = [];

  const location = useSelector(store => {
    const locationSplit = ubicacionToSearch.name.split(" ");

    for (let i = 0; i < locationSplit.length; i++) {
        finalArray = store.posts.selectors.ubicaciones.filter(
          opt =>
            opt.ubicacion
              .toLowerCase()
              .includes(locationSplit[i].toLowerCase()) 
        );
    }
    return finalArray;

  });

  const handleBorder = e => {
    e.stopPropagation();
    if (e._reactName === "onFocus") {
      e.target.parentElement.classList.add("blue_border");
    } else {
      e.target.parentElement.classList.remove("blue_border");
    }
  };

  const handleLeader = e => {
    setLeaderToSearch({
      name: e.target.value,
      status: false
    });
  };

  const handleSelectLeader = e => {
    setNewCapData({
      ...newCapData,
      [e.target.dataset.key]: Number(e.target.dataset.value)
    });
    setLeaderToSearch({
      name: e.target.dataset.name,
      status: true
    });
  };
  const handleUbicacion = e => {
    setUbicacionToSearch({
      name: e.target.value,
      status: false
    });
  };

  const handleSelectUbicacion = e => {
    setNewCapData({
      ...newCapData,
      [e.target.dataset.key]: e.target.dataset.value
    });
    setUbicacionToSearch({
      name: e.target.dataset.name,
      status: true
    });
  };

  return (
    <Section flex vertical gap="40px">
      <Section flex border $width="100%" $padding="16px" $height="56px">
        <input
          onFocus={handleBorder}
          onBlur={handleBorder}
          onChange={handleChange}
          name="cargo"
          placeholder="Nombre del cargo"
          value={newCapData.cargo}
        />
      </Section>
      <Section flex $width="100%" gap="20px">
        <SelectCustom
          handleBorder={handleBorder}
          value="Gerencia"
          newCapData={newCapData}
          name="gerencia"
          setNewCapData={setNewCapData}
          data={gerencias}
          dataPrev={newCapData.gerencia}
        />
      </Section>
      <Section flex $width="100%" gap="20px">
        <SelectCustom
          handleBorder={handleBorder}
          value="Estamento"
          newCapData={newCapData}
          name="estamento"
          setNewCapData={setNewCapData}
          data={estamentos}
          dataPrev={newCapData.estamento}
        />
        <SelectCustom
          handleBorder={handleBorder}
          value="Jornada"
          newCapData={newCapData}
          name="jornada"
          setNewCapData={setNewCapData}
          data={jornadas}
          dataPrev={newCapData.jornada}

        />
      </Section>
      <Section flex $width="100%" gap="20px">
        {/* <SelectCustom
          handleBorder={handleBorder}
          value="Ubicación"
          newCapData={newCapData}
          name="ubicacion"
          setNewCapData={setNewCapData}
          data={ubicaciones}
        /> */}
        <Section flex border $width="100%" $padding="16px" $height="56px">
          <input
          onFocus={handleBorder}
          onBlur={handleBorder}
          onChange={handleUbicacion}
          placeholder="Ubicacion"
          value={ubicacionToSearch.name}
/>

<SectionSearch  key={v4()}>

{!ubicacionToSearch.status && ubicacionToSearch.name.length > 0 && (
          finalArray.map(x =>
           
            <h6  key={v4()}
                data-key="ubicacion"
                data-value={x.id}
                data-name={x.ubicacion}
                onClick={handleSelectUbicacion}
            >{x.ubicacion}</h6>
            )

          )

        
        
        }
                    </SectionSearch>


        {/* {console.log(ubicaciones.filter(fil => fil.ubicacion.toLowerCase().includes(newCapData.ubicacion.toLowerCase())).map(x => console.log(x.ubicacion)))} */}
        </Section>
        
        <Section flex border $width="100%" $padding="16px" $height="56px">
          <input
            onFocus={handleBorder}
            onBlur={handleBorder}
            type="date"
            name="fechaVencimiento"
            defaultValue={newCapData.fechaVencimiento}
            onChange={handleChange}
          />
          
          <CalendarImg src={PATH + "img/Calendar.svg"} alt="add" />
        </Section>
      </Section>
      <Section flex border $width="100%" $padding="16px" $height="56px">
        <input
          onFocus={handleBorder}
          onBlur={handleBorder}
          onChange={handleLeader}
          placeholder="Mail o Nombre del Lider"
          value={leaderToSearch.name}
        />
        {leaderToSearch.name.length > 3 && !leaderToSearch.status && (
          <ContainerLeaders>
          {leader.length > 0 ? (
            leader.map(l => (
              l.activo ? 
              <Leader
                key={v4()}
                data-key="lider"
                data-value={l.fk_colaborador}
                data-name={l.nombreCompleto}
                onClick={handleSelectLeader}
              >
                <h3>{l.nombreCompleto}</h3>
                <p>{l.email}</p>
              </Leader> : (<Leader>
                <h3>No hay resultados</h3>
              <p>Colaborador no activo como lider</p>
            </Leader>)
            ))
          ) : (
            <Leader>
              <h3>No hay resultados</h3>
              <p>Recuerda separar los nombres por un espacio</p>
            </Leader>
          )}
        </ContainerLeaders>
        )}
      </Section>
    </Section>
  );
};

export default InputFields;
