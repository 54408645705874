import styled from "styled-components";

export const ContainerUsrImgPicker = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 23px 14px;
  width: 540px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ColorBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #666666;
  background-color: ${({ bg }) => bg};
  cursor: pointer;
`;
