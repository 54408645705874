import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../assets/variables";
import Alert from "../Common/Alert/Alert";
import { ContainerButton } from "../Common/Alert/Styled";
import { BtnBlue } from "../VacanciesDetails/MainVacancy/styled";
import Feed from "./Feed/Feed";
import Profile from "./Profile/Profile";
import Skills from "./Skills/Skills";
import { ContainerGrid } from "./Styled";
import { ArrowButton } from "./Feed/SectionStories/Stories/Styled";

const NextPreviousSlide = ({ className, onClick, prev }) => {
  return <ArrowButton  className={className} onClick={onClick}><img src={PATH + "img/btn-next.svg"} alt="next" /></ArrowButton>;
};
const PreviousSlide = ({ onClick, className }) => {
  return <ArrowButton  className={className} onClick={onClick}><img src={PATH + "img/btn-prev.svg"} alt="prev" /></ArrowButton>;
};
const Home = () => {
  const userData = useSelector(store => store.user);
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    if(userData.data.fk_colaborador == undefined || userData.data.fk_colaborador != "" || userData.data.fk_colaborador != null){
      setVisible(false)
    }
    if(userData.data.fk_colaborador === null){
      setVisible(true)
    }


  }, [userData.data.fk_colaborador])
  
  const routeChange = () =>{ 
    setVisible(false)
      setVisible(false)
      navigate("/perfil");

  }
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextPreviousSlide />,
    prevArrow: <PreviousSlide />,
    adaptiveHeight: false
  };

  return (
    <>
      <ContainerGrid columns="255px 540px 255px">
        <Profile />
        <Feed />
        <Skills user={false}/>
      </ContainerGrid>
      <Alert
            estado={visible}
            padding={"40px 50px"}
            >
          <Slider
              className="carrusel-home"
              style={{
                width: "440px",
                height: "100%"
              }}
              {...settings}
            >
              <div className="contenedorModal">
                <img src={PATH + "img/logo-negro.svg"} alt="logo empresa" />
                <h5>Bienvenid@ al Portal e)Match</h5>
                <h6>La plataforma de movilidad interna de Entel</h6>
                <p>Para poder postular a nuevos desafíos, debes crear tu perfil de usuario en e) Match.
                </p>
                <img className="bannerBienvenida" src={PATH + "img/banner-bienvenida-1.png"} alt="logo empresa" />
              </div>
              <div className="contenedorModal">
                <img src={PATH + "img/logo-negro.svg"} alt="logo empresa" />
                <h5>Crea tu perfil</h5>
                <p>
                Entre más completo, más posibilidades tendrás de hacer<br></br>
Match con un cargo.<br></br>
Debes completar la información con una descripción personal,
tus habilidades e intereses.
                </p>
                <img className="bannerBienvenida" src={PATH + "img/banner-bienvenida-2.png"} alt="logo empresa" />
              </div>
              <div className="contenedorModal">
                <img src={PATH + "img/logo-negro.svg"} alt="logo empresa" />
                <h5>Agrega tus habilidades</h5>
                <p>
                Al completar tus habilidades, la herramienta hará match con los cargos adecuados a tu perfil.<br></br>
Si no haces match, puedes editar los tags de habilidades e intereses las veces que quieras hasta encontrar la vacante ideal.
                </p>
                <img className="bannerBienvenida" src={PATH + "img/banner-bienvenida-3.png"} alt="logo empresa" />
              </div>
              <div className="contenedorModal">
              <img src={PATH + "img/logo-negro.svg"} alt="logo empresa" />
              <h5>Ya estás list@ para completar tu perfil!<br></br>
Te esperamos.</h5>
               <img className="bannerBienvenida" src={PATH + "img/banner-bienvenida-4.png"} alt="logo empresa" />
               <ContainerButton>
              <BtnBlue onClick={routeChange}>
              Crear mi perfil <img src={PATH + "img/ArrowRight.svg"} alt="save" />
                </BtnBlue>
              </ContainerButton>
              </div>
            </Slider>

      

            </Alert>
    </>
  );
};

export default Home;
