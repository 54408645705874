import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../assets/variables";

export const WrapperContent = styled.div`
  padding: 8px 20px 20px;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  justify-content: flex-start;
  gap: 24px;
  margin-top: 24px;
`;
export const HistorialContent = styled.div`
  padding: 8px 20px 20px;
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 9px;
  align-items: center;
  h6{
    font-size: 14px;
    font-weight: 400;
  }
`;
export const BtnApply = styled(NavLink)`
  background-color: ${colors.mainBlue};
  text-decoration: none;
  color: #fff;
  background: #002eff;
  border-radius: 28px;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 8px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SectionStyled = styled.div`
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  p{
    white-space: break-spaces;
  }
`;


export const BtnBlue = styled.button`
  background-color: ${colors.mainBlue};
  color: #fff;
  width: ${({ width }) => width};
  gap: 8px;
  border-radius: 50px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
  border: 0;
  &:disabled{
    background-color: #E5E5E5;
    color: #666666;
    font-weight: 600;
    cursor: not-allowed;
  }
`;

export const BtnCancelar = styled.button`
  background-color: #fff;
  color: #020247;
  width: ${({ width }) => width};
  gap: 8px;
  border-radius: 50px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #999999;
  border-radius: 30px;
`;
