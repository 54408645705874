import React, { useRef } from 'react'
import { useState } from 'react'
import { PATH } from '../../../assets/variables'
import Alert from '../../Common/Alert/Alert'
import { Items } from '../FormUser/InputWithSearch/Styled'
import { ColorCuadrado, ContainerAdd, ContainerButtonSuggest, ContainerColores, ContainerImportante, ContainerInput, ContainerSkills, SuggestButton, SuggestStyled } from './Styled'
import {v4} from "uuid"
import { ContainerButton } from '../../Common/Alert/Styled'
import { BtnBlue, BtnCancelar } from '../../VacanciesDetails/MainVacancy/styled'
import { addSugerida } from '../../../store/slices/posts'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { Loader } from '../../Common/LoaderModal/LoaderModal'

const Suggest = () => {
  const aboutSection = useRef(null);
    const [name, setName] = useState('');
    const [nameInteres, setNameInteres] = useState('');
    const [nameLenguaje, setNameLenguaje] = useState('');

    const [color, setColor] = useState('');

    const [visible, setVisible] = useState(false);
    const [skillshtml, setSkillshtml] = useState("");
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(store => store.user.data);



    const sendInfo = async e => {
        if(skills.length == 0){
            return
        }
        setLoad(true);
        dispatch(addSugerida({nombre:skillshtml,pais:data.Pais}, () => setLoad(false)));
        setVisible(false);
        setSkills("")
        setSkillshtml("")
    }
    const [skills, setSkills] = useState([]);
  return (
    <>
    <AnimatePresence>
        {load && <Loader text="Enviando sugerencias..." />}
    </AnimatePresence>
    <SuggestStyled
    onClick={() => {
        setVisible(true);
        }}
    >
     <img src={PATH + "img/icono-sugerir.svg"}/>
     <h3>
     Sugerir
       <span> Habilidad </span>
     </h3>
    </SuggestStyled>
    <SuggestButton
        onClick={() => {
          setVisible(true);
          }}
    >
        <img src={PATH + "img/icono-boton-sugerir.svg"} alt="icono-sugerir" />
        Sugerir
    </SuggestButton>
    <Alert
    estado={visible}
    width={"true"}
    height={"true"}
    justify={"flex-start"}
    >
        
      <h3>
        <img src={PATH + "img/icono-habilidad-modal.svg"} />
        Agregar sugerencia
      </h3>
      <ContainerAdd>
        <h4>Agrega la habilidad que deseas sugerir</h4>
        <ContainerInput>
        <input type="text"
         value={name}
         onChange={e => {setName(e.target.value);setColor("#DEE4FB")}}
        />
        <img src={PATH + "img/Add.svg"} 
        onClick={() => {
            if(name != ''){
                setSkills([
                    ...skills,
                    {id:v4(), name: name, color: color }
                ]);
                aboutSection.current?.scrollIntoView({behavior: 'smooth'})
            }
            setSkillshtml(skillshtml+`<h5 style="width: 320px;background: ${color};text-align: center;color: #0C0C0C;padding:7px 0 7px 0;font-size:18px;margin: 0 0 10px 0">${name}</h5>`);

            setName('');
            setColor('');


            }}
        />
        </ContainerInput>
        <h4>Agrega el interés que deseas sugerir</h4>
        <ContainerInput>
        <input type="text"
         value={nameInteres}
         onChange={e => {setNameInteres(e.target.value);setColor("#FFCFBF")}}
        />
        <img src={PATH + "img/Add.svg"} 
        onClick={() => {
            if(nameInteres != ''){
                setSkills([
                    ...skills,
                    {id:v4(), name: nameInteres, color: color }
                ]);
            }
            setSkillshtml(skillshtml+`<h5 style="width: 320px;background: ${color};text-align: center;color: #0C0C0C;padding:7px 0 7px 0;font-size:18px;margin: 0 0 10px 0">${nameInteres}</h5>`);

            setNameInteres('');
            setColor('');

            }}
        />
        </ContainerInput>

        <h4>Agrega el idioma que deseas sugerir</h4>
        <ContainerInput>
        <input type="text"
         value={nameLenguaje}
         onChange={e => {setNameLenguaje(e.target.value);setColor("#A0F3D9")}}
        />
        <img src={PATH + "img/Add.svg"} 
        onClick={() => {
            if(nameLenguaje != ''){
                setSkills([
                    ...skills,
                    {id:v4(), name: nameLenguaje, color: color }
                ]);
            }
            setSkillshtml(skillshtml+`<h5 style="width: 320px;background: ${color};text-align: center;color: #0C0C0C;padding:7px 0 7px 0;font-size:18px;margin: 0 0 10px 0">${nameLenguaje}</h5>`);
            setNameLenguaje('');
            setColor('');

            }}
        />
        </ContainerInput>

      </ContainerAdd>
      <ContainerColores>
        <h4>Sugerencias agregadas:</h4>
        <div>
          <h5>
            <ColorCuadrado color='#BFCBFF'></ColorCuadrado>
            Habilidad
          </h5>
          <h5>
            <ColorCuadrado color='#FFCFBF'></ColorCuadrado>
            Interés
          </h5>
          <h5>
            <ColorCuadrado color='#71EEC7'></ColorCuadrado>
            Idioma
          </h5>
        </div>
      </ContainerColores>
      <ContainerSkills ref={aboutSection}>
      {
        skills != "" ? (
            skills.map(skill => (
                <Items key={skill.id} style={{backgroundColor:`${skill.color}`}}>
                <h3> {skill.name}</h3>
                <img
                  src={PATH + "img/closeBold.svg"}
                  alt="close"
                  onClick={() => {
                     setSkills(
                         skills.filter(a =>
                         a.id !== skill.id
                       )
                     );
                   }}
                />
              </Items>
         ))
        ) : ""
      }

      </ContainerSkills>
      <ContainerImportante>
        <h4>
            <img src={PATH + "img/icono-importante.svg"}  />
            Importante:
        </h4>
        <p>
        Te recordamos que las habilidades que sugieras,  pasarán por una revisión previa y se cargarán al portal. 
        </p>
      </ContainerImportante>
      <ContainerButtonSuggest>
          <BtnCancelar width={"150px"} onClick={() => {setVisible(false);setSkills("");setSkillshtml("")}}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={sendInfo}>Enviar</BtnBlue>
        </ContainerButtonSuggest>
    </Alert>
    </>
  )
}

export default Suggest