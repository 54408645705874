import React, { useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import ItemsBoard from "./ItemsBoard/ItemsBoard";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import PostList from "./PostList/PostList";

const useColumns = () => {
  const leaderColumns = useMemo(
    () => [
      {
        Header: "Fecha de recepcion",
        accessor: "fecha_recepcion"
      },
      {
        Header: "Nombre del colaborador",
        accessor: "nombreCompleto"
      },
      {
        Header: "Gerencia",
        accessor: "nombreVpGerencia"
      },
      {
        Header: "Activar perfil lider",
        accessor: "activo"
      }
    ],
    []
  );
  const itemColumns = useMemo(
    () => [
      {
        Header: "Fecha de recepcion",
        accessor: "fecha_creacion"
      },
      {
        Header: "Nombre del responsable",
        accessor: "creador"
      },
      {
        Header: "Interes",
        accessor: "nombre"
      },
      {
        Header: "Eliminar",
        accessor: "btnDelete"
      }
    ],
    []
  );

  return { leaderColumns, itemColumns };
};

const DataTypeMaintainers = () => {
  const { leaderColumns, itemColumns } = useColumns();

  return (
    <Routes>
      <Route
        path="/lideres"
        element={<LeaderBoard columns={leaderColumns} />}
      />
      <Route
        path="/posts"
        element={<PostList  />}
      />
      <Route
        path="/habilidades"
        element={
          <ItemsBoard
            columns={itemColumns}
            title="Ingreso de nueva habilidad"
            legend="Habilidad"
            name="skills"
            titleList="Lista de habilidades"
            endpoint="Habilidad"
          />
        }
      />
      <Route
        path="/intereses"
        element={
          <ItemsBoard
            columns={itemColumns}
            title="Ingreso de nuevo interes"
            legend="Interes"
            name="interests"
            titleList="Lista de intereses"
            endpoint="Interes"
          />
        }
      />
      <Route
        path="/lenguajes"
        element={
          <ItemsBoard
            columns={itemColumns}
            title="Ingreso de nuevo lenguaje"
            legend="Idiomas"
            name="languages"
            titleList="Lista de lenguajes"
            endpoint="Lenguaje"
          />
        }
      />
      <Route path="*" element={<Navigate to="./lideres" />} />
    </Routes>
  );
};

export default DataTypeMaintainers;
