import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../assets/variables";
import { setInputFile, setPrevCap, setInputFile2 } from "../../../store/slices/userAll";
import { Hr, Title } from "../../../Styled";
import CapVideo from "../../Common/CapVideo/CapVideo";
import Properties from "../../Home/Feed/Caps/Cap/CapProperties/Properties";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import { MinStory, MinStoryCap } from "../../Home/Feed/SectionStories/Stories/MainStory/Styled";
import { StoryStyle, StoryStyleCap } from "../../Home/Feed/SectionStories/Stories/Story/Styled";
import { ImgProfile } from "../../Search/Results/Styled";
import { SectionStyled } from "../../VacanciesDetails/MainVacancy/styled";
import { OffSet } from "../../VacanciesDetails/Style";
import { ContainerHalfCustom } from "../Styled";
import { CapImg, NoFile, WrapperText } from "./Styled";

const VideoOrImg = ({ file }) => {
  const dispatch = useDispatch();
  const [src, setSrc] = useState(null);
  const [visible, setVisible] = useState(true);
  // if(file == undefined){
  //   console.log("registrar")

  // }
  // else{
  //   if(file.includes("https://")){
  //     console.log("editar")
  //   }
  // }



  useEffect(() => {
    if(file){
      dispatch(setInputFile(true))
      const reader = new FileReader();
      reader.addEventListener("load", event => {
        setSrc(event.target.result);
      });
      reader.readAsDataURL(file);
    }


  }, [file]);


  if (!file) {
    return <NoFile />;
  }

    return <CapVideo archivo={`${src};#${file.type}`}/>;

    // return <CapVideo archivo={file} />;

};

const VideoOrImgPrev = ({ file }) => {

    return <CapVideo post={true} archivo={file}/>;

    // return <CapVideo archivo={file} />;

};

const ImgProfileStoryPrev = ({ file2 }) => {
  const [url, type] = file2.split(";#");

  return <img src={'https://colaboradordigital.blob.core.windows.net/ematch/archivos/'+url} />;

  // return <CapVideo archivo={file} />;

};

const ImgProfileStory = ({ file2 }) => {
  const dispatch = useDispatch();

  const [srcStory, setSrcStory] = useState(null);

  useEffect(() => {
    if (file2) {
      dispatch(setInputFile2(true))
      const reader = new FileReader();
      reader.addEventListener("load", event => {
        setSrcStory(event.target.result);
      });
      reader.readAsDataURL(file2);
    }
  }, [file2]);

  if (!file2) {
    return <img src={PATH + "img/profile.svg"} alt="Profile" />;
  }


    return <img src={srcStory} alt="previsualizacion de imagen" />;

};


const PrevCap = ({ newCapData, setNewCapData }) => {
  const dispatch = useDispatch();

  const prevCap = useSelector(store => store.userAll.prevCap);
  const { gerencia, estamento, ubicacion, jornada } = useSelector(store => {
    const gerencia = store.posts.selectors.gerencias.filter(
      g => g.id === Number(newCapData.gerencia)
    )[0]?.gerencia;
    const estamento = store.posts.selectors.estamentos.filter(
      e => e.id === Number(newCapData.estamento)
    )[0]?.estamento;
    const ubicacion = store.posts.selectors.ubicaciones.filter(
      u => u.id === Number(newCapData.ubicacion)
    )[0]?.ubicacion;
    const jornada = store.posts.selectors.jornadas.filter(
      g => g.id === Number(newCapData.jornada)
    )[0]?.jornada;

    return { gerencia, estamento, ubicacion, jornada };
  });

  return (
    <div>
      <OffSet />
      <ContainerHalfCustom section_scroll header_height="24">
        <Title size="20px">Vista previa</Title>

        <section>
        {!prevCap || newCapData.file2 != undefined ? (
          <MinStoryCap>
                  <StoryStyleCap>
                    <ImgProfileStory file2={newCapData.file2} />
                  </StoryStyleCap>
          </MinStoryCap>

          ) : (
            <MinStoryCap>
            <StoryStyleCap>
              <ImgProfileStoryPrev file2={newCapData.filePrev2}/>
            </StoryStyleCap>
          </MinStoryCap>
          )}  

          {!prevCap || newCapData.file != undefined ? (
          <VideoOrImg file={newCapData.file} />

          ) : (
          <VideoOrImgPrev file={newCapData.filePrev} />
          )}     

          <h5>{newCapData.fechaVencimiento}</h5>
          <Properties
            padding="16px 0 0"
            cargo={newCapData.cargo}
            gerencia={gerencia}
            estamento={estamento}
            ubicacion={ubicacion}
            jornada={jornada}
          />
          <Hr $margin="0 0 24px " />
          <CapSkills off itemsData={newCapData} />
          <Hr $margin="24px 0 " />
          <WrapperText>
            <SectionStyled>
              <h3>Misión:</h3>
              <p>{newCapData.mision}</p>
            </SectionStyled>
            <SectionStyled>
              <h3>Descripción:</h3>
              <p>{newCapData.descripcion}</p>
            </SectionStyled>
            <SectionStyled>
              <h3>Requisitos:</h3>
              <p>{newCapData.funciones}</p>
            </SectionStyled>
          </WrapperText>
        </section>
      </ContainerHalfCustom>
    </div>
  );
};

export default PrevCap;
