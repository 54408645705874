import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PATH } from "../../../../../../assets/variables";
import { Section } from "../../../../UserRegister/FormUser/Styled";

const SelectCustom = ({
  value,
  newCapData,
  name,
  setNewCapData,
  handleBorder,
  data,
  dataPrev
}) => {
  const handleChange = e => {
    setNewCapData({ ...newCapData, [name]: e.target.value });
  };
  const [idGerencia, setIdGerenciat] = useState(6)


// useEffect(() => {
//   let gerencia=[];
//   data.map(x => {
//     if(x.gerencia === newCapData.gerencia){
//       gerencia.push(x.id)
//     }
//   })
//   setIdGerenciat(gerencia[0])
//   console.log(idGerencia)
// }, [data,newCapData.gerencia])



  return (
    <Section flex border $width="100%" $padding="16px" $height="56px">
      <select
        onFocus={handleBorder}
        onBlur={handleBorder}
        onChange={handleChange}
        defaultValue={dataPrev}
        
        
      >
        <option value="" disabled>
          {value}
        </option>
        {data.map(selector => (
          <option key={selector.id} value={selector.id}>
            {selector[name]}
          </option>
        ))}
      </select>
    </Section>
  );
};

export default SelectCustom;
