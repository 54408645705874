import React, { useState } from "react";
import Profile from "../Home/Profile/Profile";
import Skills from "../Home/Skills/Skills";
import { ContainerGrid } from "../Home/Styled";
import FormUser from "./FormUser/FormUser";
import Suggest from "./Suggest/Suggest";

const UserRegister = () => {
  return (
    <ContainerGrid columns="255px 540px 255px">
      <Profile />
      <FormUser />
      <div>
      <Skills user={true} />
      </div>
    </ContainerGrid>
  );
};

export default UserRegister;
