import React from "react";
import styled from "styled-components";
import { ContainerSetPage, ButtonEdge, NumberButton } from "./Styled";

const Paginacion = ({ pageSize, setPageSize }) => {
    const valor = [10, 15, 20];

    return (
        <ContainerSetPage>
            <p>Mostrar</p>
            <section>
                <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                >
                    {valor.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </section>
            <p>registros</p>
        </ContainerSetPage>
    );
};

export const ViewBoard = ({ data, pageIndex, pageSize }) => {
    const by =
        data.length < (pageIndex + 1) * pageSize
            ? data.length
            : (pageIndex + 1) * pageSize;

    return (
        <p>
            Mostrando registros del {(pageIndex + 1) * pageSize - pageSize + 1}{" "}
            al {by} de un total de {data.length} registros.
        </p>
    );
};

export const PreviousBoard = ({
    canPreviousPage,
    previousPage,
    pageOptions,
    pageIndex
}) => {
    return (
        <ButtonEdge
            onClick={() => previousPage()}
            can={canPreviousPage}
            disabled={!canPreviousPage}
        >
            Anterior
        </ButtonEdge>
    );
};
export const NextBoard = ({ canNextPage, nextPage }) => {
    return (
        <ButtonEdge
            onClick={() => nextPage()}
            can={canNextPage}
            disabled={!canNextPage}
        >
            Siguiente
        </ButtonEdge>
    );
};

export const NumberBoard = ({ pageOptions, pageIndex, gotoPage }) => {
    return (
        <>
            {pageOptions.map((page, i) => (
                <NumberButton
                    onClick={() => gotoPage(page)}
                    pageIndex={pageIndex}
                    key={page + i}
                    i={i}
                    can={page === pageIndex}
                >
                    {page + 1}
                </NumberButton>
            ))}
        </>
    );
};

export default Paginacion;
