import React from 'react'
import { ContainerModal } from '../../../../Styled'
import { ModalAlert } from './Styled'


const Alert = ({children, estado, setModal, justify, width, padding, height}) => {
  return (
    <>
    { estado &&
    <ContainerModal>
        <ModalAlert justify={justify} width={width} padding={padding} height={height}>
            {children}
        </ModalAlert>
    </ContainerModal>
    }

    </>
  )
}

export default Alert